import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { saveAs } from "file-saver";

import DatePicker from "../Common/DatePicker";
import { EndPoints } from "../../Helpers/Constants/EndPoints";
import PopTable from "../Common/PopTable";
import { PopTableData } from "../../Helpers/Constants/InitialValues";

import API from "../../Helpers/Constants/EndPoints/Api";
import Axios from "../../Helpers/Universal/Axios/Axios";
import axios from "axios";

const popTabVal = { auto: false, manual: false, others: false };

const AdminDashboard = ({ permissions: { all, add } }) => {
  const [data, setData] = useState({
    received: {},
    paid: {},
    difference: 0,
    wrongTransaction: {},
    bank: [],
    customExpense: [],
    paymentGateway: [],
    company: [],
  });
  const [dropdown, setDropdown] = useState({ companies: [], platforms: [] });
  const [filter, setFilter] = useState({
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });
  const [popTable, setPopTable] = useState({
    received: popTabVal,
    paid: popTabVal,
  });

  const getData = async () => {
    try {
      const { status, data } = await Axios.get(API.DASHBOARD.ADMIN, filter);
      if (status)
        setData({
          received: data.dashboard.received,
          paid: data.dashboard.paid,
          difference: data.dashboard.difference,
          wrongTransaction: data.dashboard.wrongTransaction,
          bank: data.bank,
          customExpense: data.customExpense,
          paymentGateway: data.paymentGateway,
          company: data.company,
        });
    } catch (err) {
      console.log("ERROR==>", err);
    }
  };

  const getDropdowns = async () => {
    try {
      const [companies, paltforms] = await Promise.all([
        Axios.get(API.DROPDOWN.COMPANY),
        Axios.get(API.DROPDOWN.PLATFORM),
      ]);
      if (companies.status)
        setDropdown((prev) => ({ ...prev, companies: companies.data }));
      if (paltforms.status)
        setDropdown((prev) => ({ ...prev, platforms: paltforms.data }));
    } catch (err) {
      console.log("ERROR==>", err);
    }
  };

  const showPopTable = (key, subKey) => {
    setPopTable((prev) => ({
      ...prev,
      [key]: { ...prev[key], [subKey]: true },
    }));
  };

  const PDF = async () => {
    try {
      const { status, data } = await Axios.get(API.DASHBOARD.PDF, filter);
      if (status) {
        const response = await axios({
          url: data,
          method: "GET",
          responseType: "blob",
        });
        const blob = new Blob([response.data], { type: "application/pdf" });
        saveAs(blob, "downloaded-file.pdf");
      }
    } catch (err) {
      console.log("ERROR==>", err);
    }
  };

  const AccountingPDF = async () => {
    try {
      const { status, data } = await Axios.get(
        API.DASHBOARD.ACCOUNTING_PDF,
        filter
      );
      if (status) {
        const response = await axios({
          url: data,
          method: "GET",
          responseType: "blob",
        });
        const blob = new Blob([response.data], { type: "application/pdf" });
        saveAs(blob, "Accounting-file.pdf");
      }
    } catch (err) {
      console.log("ERROR==>", err);
    }
  };

  const setFilters = (event, key) => {
    const value = event?.target?.value;

    value
      ? setFilter((prev) => ({ ...prev, [key]: value }))
      : setFilter((prev) => {
          delete prev[key];
          return { ...prev };
        });
  };

  useEffect(() => {
    getData();
  }, [filter]);

  useEffect(() => {
    getDropdowns();
  }, []);

  return (
    <div>
      <div className="container-fluid py-3">
        <span className="dateselect_navigation d-flex justify-content-between align-items-center w-100 postion-relative">
          <div className="d-flex">
            <select
              className="form-select text-start btn bg-gradient-dark btn-lg mb-1 mx-2"
              onChange={(e) => setFilters(e, "platform_id")}
            >
              <option value="">Platform</option>
              {dropdown.platforms?.map((val) => (
                <option key={val._id} value={val._id}>
                  {val.name}
                </option>
              ))}
            </select>
            <select
              className="form-select text-start btn bg-gradient-dark btn-lg mb-1 mx-2"
              onChange={(e) => setFilters(e, "company_id")}
            >
              <option value="">Company</option>
              {dropdown.companies?.map((val) => (
                <option key={val._id} value={val._id}>
                  {val.name}
                </option>
              ))}
            </select>
            <DatePicker filter={filter} setFilter={setFilter} />
          </div>
          <div className="d-flex">
            <button
              className="btn bg-gradient-dark btn-lg align-items-center float-end d-flex mt-3 mb-3 btn-md mb-1 me-2"
              onClick={AccountingPDF}
            >
              Accounting <i className="fas fa-download ms-2"></i>
            </button>
            <button
              className="btn bg-gradient-dark btn-lg align-items-center float-end d-flex mt-3 mb-3 btn-md mb-1 me-2"
              onClick={PDF}
            >
              Export <i className="fas fa-download ms-2"></i>
            </button>

            {(all || add) && (
              <Link to={EndPoints.ADD_PAYMENT}>
                <button className="btn bg-gradient-dark align-items-center btn-lg  float-end d-flex mt-3 mb-3 btn-md mb-1">
                  <i className="fas fa-credit-card me-2"></i>
                  Add Payment
                </button>
              </Link>
            )}
          </div>
        </span>
      </div>

      <div className="container-fluid py-4">
        <div className="row">
          <div className="col-xl-4 col-sm-6 mb-xl-0 mb-4">
            <div className="card">
              <div className="card-header d-flex p-3 pb-0 pt-2">
                <div className="icon icon-lg icon-shape bg-gradient-dark shadow-dark text-center border-radius-xl mt-n4">
                  <i className="material-icons opacity-10">person</i>
                </div>
                <div className="heading mt-n2 font-weight-bold text-dark ms-3">
                  Received
                </div>
              </div>

              <div className="text-end pe-3 mt-n3">
                <h4 className="mb-0">{data?.received?.total?.toFixed(2)}</h4>
              </div>
              <hr className="dark horizontal my-0"></hr>
              <div className="card-footer p-3">
                <p className="mb-0 d-flex justify-content-between">
                  <span
                    className="text-secondary fs-6 font-weight-bolder"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    title={`Total of Automatic Deposits`}
                  >
                    <label
                      onClick={() => showPopTable("received", "auto")}
                      className="font-weight-bolder text-secondary pe-auto"
                    >
                      Auto
                    </label>
                    {data?.received?.auto?.toFixed(2)}
                    {popTable.received.auto && (
                      <PopTable
                        filter={{ ...filter, populate: "automaticDeposit" }}
                        visible={setPopTable}
                        total={data?.received?.auto?.toFixed(2)}
                        field={PopTableData?.ReceivedAuto}
                      />
                    )}
                  </span>
                  <span
                    className="text-secondary fs-6 font-weight-bolder"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    title={`Total of Manual Deposits`}
                  >
                    <label
                      className="font-weight-bolder text-secondary pe-auto"
                      onClick={() => showPopTable("received", "manual")}
                    >
                      Manual
                    </label>
                    {data?.received?.manual?.toFixed(2)}
                    {popTable.received.manual && (
                      <PopTable
                        filter={filter}
                        visible={setPopTable}
                        total={data?.received?.manual?.toFixed(2)}
                        field={PopTableData?.ReceivedManual}
                      />
                    )}
                  </span>
                  <span
                    className="text-secondary fs-6 font-weight-bolder"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    title={`Wrong Deposits + Withdrawal Failed + Custom Credits`}
                  >
                    <label
                      className="font-weight-bolder text-secondary pe-auto"
                      onClick={() => showPopTable("received", "others")}
                    >
                      Others
                    </label>
                    {data?.received?.others?.toFixed(2)}
                    {popTable.received.others && (
                      <PopTable
                        filter={{ ...filter, populate: "withdrawalFailed" }}
                        visible={setPopTable}
                        total={data?.received?.others?.toFixed(2)}
                        field={PopTableData?.ReceivedOthers}
                      />
                    )}
                  </span>
                </p>
              </div>
            </div>
          </div>

          <div className="col-xl-4 col-sm-6 mb-xl-0 mb-4">
            <div className="card">
              <div className="card-header d-flex p-3 pb-0 pt-2">
                <div className="icon icon-lg icon-shape bg-gradient-primary shadow-primary text-center border-radius-xl mt-n4">
                  <i className="material-icons opacity-10">person</i>
                </div>
                <div className="heading mt-n2 font-weight-bold text-dark ms-3">
                  Paid
                </div>
              </div>

              <div className="text-end pe-3 mt-n3">
                <h4 className="mb-0">{data?.paid?.total?.toFixed(2)}</h4>
              </div>
              <hr className="dark horizontal my-0"></hr>
              <div className="card-footer p-3">
                <p className="mb-0 d-flex justify-content-between">
                  <span
                    className="text-secondary fs-6 font-weight-bolder"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    title={`Total of Auto Withdrawals`}
                  >
                    <label
                      className="font-weight-bolder text-secondary"
                      onClick={() => showPopTable("paid", "auto")}
                    >
                      Auto
                    </label>
                    {data?.paid?.auto?.toFixed(2)}
                    {popTable.paid.auto && (
                      <PopTable
                        filter={{ ...filter, populate: "autoWithdrawal" }}
                        visible={setPopTable}
                        total={data?.paid?.auto?.toFixed(2)}
                        field={PopTableData?.PaidAuto}
                      />
                    )}
                  </span>
                  <span
                    className="text-secondary fs-6 font-weight-bolder"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    title={`Total of Manual Withdrawals`}
                  >
                    <label
                      className="font-weight-bolder text-secondary"
                      onClick={() => showPopTable("paid", "manual")}
                    >
                      Manual
                    </label>
                    {data?.paid?.manual?.toFixed(2)}
                    {popTable.paid.manual && (
                      <PopTable
                        filter={{ ...filter, populate: "manualWithdrawal" }}
                        visible={setPopTable}
                        data={data.data}
                        total={data?.paid?.manual?.toFixed(2)}
                        field={PopTableData?.PaidManual}
                      />
                    )}
                  </span>
                  <span
                    className="text-secondary fs-6 font-weight-bolder"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    title={`Wrong Withdrawals + Withdrawal Failed + Withdrawal Processing + Incorrect Settlements + Custom Debits`}
                  >
                    <label
                      className="font-weight-bolder text-secondary"
                      onClick={() => showPopTable("paid", "others")}
                    >
                      Others
                    </label>
                    {data?.paid?.others?.toFixed(2)}
                    {popTable.paid.others && (
                      <PopTable
                        filter={{
                          ...filter,
                          populate: "withdrawalFailed withdrawalProcessing",
                        }}
                        visible={setPopTable}
                        total={data?.paid?.others?.toFixed(2)}
                        field={PopTableData?.PaidOthers}
                      />
                    )}
                  </span>
                </p>
              </div>
            </div>
          </div>

          <div className="col-xl-4 col-sm-6 mb-xl-0 mb-4">
            <div className="card">
              <div className="card-header d-flex p-3 pb-0 pt-2">
                <div className="icon icon-lg icon-shape bg-gradient-success shadow-success text-center border-radius-xl mt-n4 ">
                  <i className="material-icons opacity-10">person</i>
                </div>
                <div className="heading mt-n2 font-weight-bold text-dark ms-3">
                  Difference
                </div>
              </div>
              <div className="text-end pe-3 mt-n3">
                <h4
                  className="mb-0"
                  style={
                    data?.difference > 0 ? { color: "green" } : { color: "red" }
                  }
                >
                  {data?.difference?.toFixed(2)}
                </h4>
              </div>
              <hr className="dark horizontal my-0"></hr>
              <div className="card-footer p-3">
                <p className="mb-0 d-flex justify-content-between"></p>
              </div>
            </div>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-lg-12 col-md-12 mt-5 mb-1">
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table align-items-center mb-0">
                    <thead>
                      <tr>
                        <th className="text-uppercase text-dark fs-6 font-weight-bolder  px-3 py-1">
                          Bank
                        </th>
                        <th className="text-center text-uppercase text-dark fs-6 font-weight-bolder ">
                          Opening
                        </th>
                        <th className="text-center  text-uppercase text-dark fs-6 font-weight-bolder  ps-2">
                          Received
                        </th>
                        <th className="text-center text-uppercase text-dark fs-6 font-weight-bolder ">
                          Transfered
                        </th>
                        <th className="text-center text-uppercase text-dark fs-6 font-weight-bolder ">
                          Balance
                        </th>
                        <th className="text-center text-uppercase text-dark fs-6 font-weight-bolder ">
                          Actual Balance
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.bank?.map((val) => (
                        <tr key={val._id}>
                          <td className="align-middle text-sm px-3 py-1">
                            <span className="fs-6 font-weight-bold text-dark">
                              {`${val.firmName} - ${val.bankName}`}
                            </span>
                          </td>
                          <td className="align-middle text-center text-sm">
                            <span className="fs-6 font-weight-bold">
                              {val.opening?.toFixed(2)}
                            </span>
                          </td>
                          <td className="align-middle text-center text-sm">
                            <span className="fs-6 font-weight-bold">
                              {val.received?.toFixed(2)}
                            </span>
                          </td>
                          <td className="align-middle text-center text-sm">
                            <span className="fs-6 font-weight-bold">
                              {val.transfered?.toFixed(2)}
                            </span>
                          </td>
                          <td className="align-middle text-center text-sm">
                            <span className="fs-6 font-weight-bold">
                              {val.balance?.toFixed(2)}
                            </span>
                          </td>
                          <td className="align-middle text-center text-sm">
                            <span className="fs-6 font-weight-bold">
                              {val.actualBalance?.toFixed(2)}
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-lg-12 col-md-12 mb-1">
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table align-items-center mb-0">
                    <thead>
                      <tr>
                        <th className="text-uppercase text-dark fs-6 font-weight-bolder  px-3 py-1">
                          Company
                        </th>
                        <th className="text-center text-uppercase text-dark fs-6 font-weight-bolder ">
                          Opening Balance
                        </th>
                        <th className="text-center  text-uppercase text-dark fs-6 font-weight-bolder  ps-2">
                          Amount Received
                        </th>
                        <th className="text-center text-uppercase text-dark fs-6 font-weight-bolder ">
                          Tranfered Amount
                        </th>
                        <th className="text-center text-uppercase text-dark fs-6 font-weight-bolder ">
                          Paid
                        </th>
                        <th className="text-center text-uppercase text-dark fs-6 font-weight-bolder ">
                          Balance
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.company?.map((val) => (
                        <tr key={val._id}>
                          <td className="align-middle text-sm px-3 py-1">
                            <span className="fs-6 font-weight-bold text-dark">
                              {val.name}
                            </span>
                          </td>
                          <td className="align-middle text-center text-sm">
                            <span className="fs-6 font-weight-bold">
                              {val.openingBalance?.toFixed(2)}
                            </span>
                          </td>
                          <td className="align-middle text-center text-sm">
                            <span className="fs-6 font-weight-bold">
                              {val.amountReceived?.toFixed(2)}
                            </span>
                          </td>
                          <td className="align-middle text-center text-sm">
                            <span className="fs-6 font-weight-bold">
                              {val.transferedAmount?.toFixed(2)}
                            </span>
                          </td>
                          <td className="align-middle text-center text-sm">
                            <span className="fs-6 font-weight-bold">
                              {val.paid?.toFixed(2)}
                            </span>
                          </td>
                          <td className="align-middle text-center text-sm">
                            <span className="fs-6 font-weight-bold">
                              {val.balance?.toFixed(2)}
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-lg-12 col-md-12 mt-2 mb-2">
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table align-items-center mb-0">
                    <thead>
                      <tr>
                        <th className="text-uppercase text-dark fs-6 font-weight-bolder  px-3 py-1">
                          Payment gateway
                        </th>
                        <th className="text-center  text-uppercase text-dark fs-6 font-weight-bolder  ps-2">
                          Opening
                        </th>
                        <th className="text-center  text-uppercase text-dark fs-6 font-weight-bolder  ps-2">
                          IN
                        </th>
                        <th className="text-center text-uppercase text-dark fs-6 font-weight-bolder ">
                          Out
                        </th>
                        <th className="text-center text-uppercase text-dark fs-6 font-weight-bolder ">
                          Failed
                        </th>
                        <th className="text-center text-uppercase text-dark fs-6 font-weight-bolder ">
                          Processing
                        </th>
                        <th className="text-center text-uppercase text-dark fs-6 font-weight-bolder ">
                          Balance
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.paymentGateway?.map((val) => (
                        <tr key={val._id}>
                          <td className="align-middle text-sm px-3 py-1">
                            <span className="fs-6 font-weight-bold text-dark">
                              {val.name}
                            </span>
                          </td>
                          <td className="align-middle text-center text-sm">
                            <span className="fs-6 font-weight-bold">
                              {val.opening?.toFixed(2)}
                            </span>
                          </td>
                          <td className="align-middle text-center text-sm">
                            <span className="fs-6 font-weight-bold">
                              {val.in?.toFixed(2)}
                            </span>
                          </td>
                          <td className="align-middle text-center text-sm">
                            <span className="fs-6 font-weight-bold">
                              {val.out?.toFixed(2)}
                            </span>
                          </td>
                          <td className="align-middle text-center text-sm">
                            <span className="fs-6 font-weight-bold">
                              {val.failed?.toFixed(2)}
                            </span>
                          </td>
                          <td className="align-middle text-center text-sm">
                            <span className="fs-6 font-weight-bold">
                              {val.processing?.toFixed(2)}
                            </span>
                          </td>
                          <td className="align-middle text-center text-sm">
                            <span className="fs-6 font-weight-bold">
                              {val.balance?.toFixed(2)}
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-lg-6 col-md-6 mb-md-0 mb-4">
            <div className="card">
              <div className="card-header">
                <div className="row">
                  <div className="col-12">
                    <h6 className="mb-0">Wrong Transaction</h6>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between m-4 border-bottom mt-1 mb-1">
                <h6>Deposit - </h6>
                <h6>{data?.wrongTransaction?.deposit?.toFixed(2)}</h6>
              </div>
              <div className="d-flex justify-content-between m-4 mt-1 mb-1">
                <h6>Withdrawal - </h6>
                <h6>{data?.wrongTransaction?.withdrawal?.toFixed(2)}</h6>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6">
            <div className="card">
              <div className="card-header">
                <div className="row">
                  <div className="col-12">
                    <h6 className="mb-0">Custom Expense</h6>
                  </div>
                </div>
              </div>
              {data?.customExpense?.map((val) => (
                <div
                  key={val._id}
                  className="d-flex justify-content-between m-4 border-bottom mt-1 mb-1"
                >
                  <h6>{val.name} - </h6>
                  <h6>{val.amount?.toFixed(2)}</h6>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
