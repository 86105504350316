import React, { useEffect, useState } from "react";

import API from "../../Helpers/Constants/EndPoints/Api";
import Axios from "../../Helpers/Universal/Axios/Axios";
import { TRANSACTION } from "../../Helpers/Constants/Dropdowns";
import { ReverseObj } from "../../Helpers/Universal/Function/common";
import { NA } from "../../Helpers/Constants/Default";

const popTabVal = { auto: false, manual: false, others: false };

const PopTable = ({ visible, total, field, filter }) => {
  const [data, setData] = useState([]);

  const getData = async () => {
    try {
      const { status, data } = await Axios.get(
        API.DASHBOARD.TABLE_DATA,
        filter
      );
      if (status) {
        const result = [];

        for (let val of data) {
          let isIn = false;

          for (let fil of field) {
            if (
              typeof val[fil.body] === "object"
                ? val[fil.body].length > 0
                : val[fil.body]
            )
              isIn = true;
          }

          if (isIn) result.push(val);
        }

        setData(result);
      }
    } catch (err) {
      console.log("ERROR==>", err);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="bg_viewpopup" style={{ zIndex: "999999" }}>
      <div className="successfull popTable bg-white p-4 w-50 top-50 start-50 end-0 border rounded text-center position-absolute">
        <span
          onClick={() => visible({ received: popTabVal, paid: popTabVal })}
          className="cross_icon end-1 position-absolute rounded-circle text-white"
        >
          <label>
            <span className="bg-gradient-dark">&times;</span>
          </label>
        </span>
        <div className="overflow-x-scroll w-100 h-100">
          <table className="table-striped table align-middle mt-3  text-center">
            <thead className="bg-light text-dark">
              <tr>
                <th scope="col" className="text-uppercase">
                  #
                </th>
                {field?.map((val, i) => (
                  <th key={i} scope="col" className="text-uppercase">
                    {val?.head}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data?.map((val, i) => (
                <tr key={val._id} valign="top">
                  <td>{++i}</td>
                  {field?.map((ele, j) => (
                    <td key={j}>
                      {ele.isArray && ele.populate
                        ? val[ele.body]?.map((data, k) => (
                            <div key={k}>
                              {data?.schema === "Bank"
                                ? `${data?.docId?.firmName || NA} - ${
                                    data?.amount?.toFixed(2) || NA
                                  }` || NA
                                : `${data?.docId?.name || NA} - ${
                                    data?.amount?.toFixed(2) || NA
                                  }` || NA}
                              <br></br>
                            </div>
                          ))
                        : ele.isArray && !ele.populate
                        ? val[ele.body]?.map((data, k) =>
                            ele.body === "manualDeposit" ? (
                              <div key={k}>
                                {`${
                                  ReverseObj(TRANSACTION.TYPE)[data?.type]
                                } - ${data?.amount?.toFixed(2) || NA}` || NA}
                                <br></br>
                              </div>
                            ) : (
                              ele.drCr === data?.type && (
                                <div key={k}>
                                  {`${data?.name || NA} - ${
                                    data?.amount?.toFixed(2) || NA
                                  }`}
                                  <br></br>
                                </div>
                              )
                            )
                          )
                        : !ele.isArray && !ele.populate
                        ? val[ele.body]?.toFixed(2) || NA
                        : null}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="popTableTotal bg-gradient-dark text-white p-2">
          <label className="text-white w-100 mb-0">TOTAL</label>
          <label className="text-white w-100 mb-0">{total}</label>
        </div>
      </div>
    </div>
  );
};

export default PopTable;
