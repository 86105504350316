import React from "react";
import { Navigate } from "react-router-dom";

import { EndPoints } from "../../Helpers/Constants/EndPoints";
import { useSelector } from "react-redux";
import { USER_TYPE } from "../../Helpers/Constants/Default";

const PvtRoute = ({ path, children: Component }) => {
  const { profile } = useSelector((val) => val);
  const token = localStorage.getItem("token");

  if (!token || !profile) return <Navigate to={EndPoints.LOGIN} />;

  if (profile.role === USER_TYPE[0])
    return <Component permissions={{ all: true }} />;

  const avaliable = profile?.permissions?.find(
    (val) =>
      val.field === path ||
      `add-${val.field}` === path ||
      `view-${val.field}` === path
  );

  if (
    avaliable &&
    path.includes("add-") &&
    (avaliable.all || avaliable.add || avaliable.edit)
  )
    return <Component permissions={avaliable} />;

  if (avaliable && (avaliable.all || avaliable.view))
    return <Component permissions={avaliable} />;
};

export default PvtRoute;
