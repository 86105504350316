import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";

import { InternalTransferSchema } from "../../Helpers/Constants/Schemas";
import { InternalTransferFields } from "../../Helpers/Constants/InitialValues";

import { EndPoints } from "../../Helpers/Constants/EndPoints";

import API from "../../Helpers/Constants/EndPoints/Api";
import Axios from "../../Helpers/Universal/Axios/Axios";
import { UpdateParam } from "../../Helpers/Universal/Function/common";
import { INTERNAL_TRANSFER } from "../../Helpers/Constants/Dropdowns";

const AddInternalTransfer = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [isUpate] = useState(state ? true : false);
  const [initialValues] = useState(
    isUpate
      ? {
          ...state?.val,
          platform: state?.val?.platform?._id,
          from: state?.val?.from?._id,
          fromType: state?.val?.fromType,
          to: state?.val?.toBank?._id,
          toType: state?.val?.toType,
        }
      : InternalTransferFields.required
  );

  const [dropdown, setDropdown] = useState({ platforms: [], from: [], to: [] });
  const [platform_id, setPlatform_id] = useState(initialValues.platform);
  const [type, setType] = useState({
    fromType: initialValues.fromType,
    toType: initialValues.toType,
  });

  const getPlatforms = async () => {
    try {
      const { status, data } = await Axios.get(API.DROPDOWN.PLATFORM);
      if (status) setDropdown((prev) => ({ ...prev, platforms: data }));
    } catch (err) {
      console.log("ERROR==>", err);
    }
  };

  const getDropdowns = async () => {
    try {
      if (type.fromType) {
        let url;

        if (type.fromType === INTERNAL_TRANSFER.REF_TYPE[0])
          url = API.DROPDOWN.BANK;

        if (type.fromType === INTERNAL_TRANSFER.REF_TYPE[1])
          url = API.DROPDOWN.PAYMENT_GATEWAY;

        if (url) {
          const { status, data } = await Axios.get(url, { platform_id });
          if (status) setDropdown((prev) => ({ ...prev, from: data }));
        }
      }

      if (type.toType) {
        let url;

        if (type.toType === INTERNAL_TRANSFER.REF_TYPE[0])
          url = API.DROPDOWN.BANK;

        if (type.toType === INTERNAL_TRANSFER.REF_TYPE[1])
          url = API.DROPDOWN.PAYMENT_GATEWAY;

        if (url) {
          const { status, data } = await Axios.get(url);
          if (status) setDropdown((prev) => ({ ...prev, to: data }));
        }
      }
    } catch (err) {
      console.log("ERROR==>", err);
    }
  };

  const handleSubmit = async (data) => {
    try {
      const { status } = await Axios.post(API.INTERNAL_TRANSFER.CREATE, data);
      if (status) navigate(EndPoints.INTERNAL_TRANSFER);
    } catch (err) {
      console.log("ERROR==>", err);
    }
  };

  const handleUpdateDocument = async (data) => {
    try {
      const params = await UpdateParam(InternalTransferFields, data);
      const { status } = await Axios.patch(API.INTERNAL_TRANSFER.UPDATE, {
        internalTransfer_id: initialValues._id,
        ...params,
      });
      if (status) navigate(EndPoints.INTERNAL_TRANSFER);
    } catch (err) {
      console.log("ERROR==>", err);
    }
  };

  useEffect(() => {
    getPlatforms();
  }, []);

  useEffect(() => {
    getDropdowns();
  }, [type]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={InternalTransferSchema}
      onSubmit={(values, { resetForm }) => {
        isUpate ? handleUpdateDocument(values) : handleSubmit(values);
        resetForm();
      }}
    >
      {({ errors, touched, setValues }) => (
        <Form className="border rounded shadow mx-auto w-50 mt-7 m-4 p-4">
          <div className="row">
            <div className="col-md-5 mb-3">
              <label>Platform</label>
            </div>
            <div className="col-md-7 mb-3">
              <Field
                className="form-control"
                name="platform"
                as="select"
                onClick={(e) => {
                  setPlatform_id(e.target.value);
                }}
              >
                <option value="">Choose a Platform</option>
                {dropdown.platforms?.map((val) => (
                  <option key={val._id} value={val._id}>
                    {val.name}
                  </option>
                ))}
              </Field>
              {errors.platform && touched.platform ? (
                <div className="error">{errors.platform}</div>
              ) : null}
            </div>

            <div className="col-md-5 mb-3">
              <label>From</label>
            </div>
            <div className="col-md-3 mb-3">
              <Field
                className="form-control"
                name="fromType"
                as="select"
                onClick={(e) => {
                  setType((prev) => ({ ...prev, fromType: e.target.value }));
                }}
              >
                <option value="">Select Type</option>
                {INTERNAL_TRANSFER.REF_TYPE?.map((val) => (
                  <option key={val} value={val}>
                    {val}
                  </option>
                ))}
              </Field>
              {errors.fromType && touched.fromType ? (
                <div className="error">{errors.fromType}</div>
              ) : null}
            </div>
            <div className="col-md-4 mb-3">
              <Field className="form-control" name="from" as="select">
                <option value="">Click to Select</option>
                {dropdown.from?.map((val) => (
                  <option key={val._id} value={val._id}>
                    {type.fromType === INTERNAL_TRANSFER.REF_TYPE[0]
                      ? `${val.firmName} - ${val.bankName}`
                      : `${val.name}`}
                  </option>
                ))}
              </Field>
              {errors.from && touched.from ? (
                <div className="error">{errors.from}</div>
              ) : null}
            </div>

            <div className="col-md-5 mb-3">
              <label>Amount</label>
            </div>
            <div className="col-md-7 mb-3">
              <Field className="form-control" name="amount" type="number" />
              {errors.amount && touched.amount ? (
                <div className="error">{errors.amount}</div>
              ) : null}
            </div>

            <div className="col-md-5 mb-3">
              <label>To</label>
            </div>
            <div className="col-md-3 mb-3">
              <Field
                className="form-control"
                name="toType"
                as="select"
                onClick={(e) => {
                  setType((prev) => ({ ...prev, toType: e.target.value }));
                }}
              >
                <option value="">Select Type</option>
                {INTERNAL_TRANSFER.REF_TYPE?.map((val) => (
                  <option key={val} value={val}>
                    {val}
                  </option>
                ))}
              </Field>
              {errors.toType && touched.toType ? (
                <div className="error">{errors.toType}</div>
              ) : null}
            </div>
            <div className="col-md-4 mb-3">
              <Field className="form-control" name="to" as="select">
                <option value="">Click to Select</option>
                {dropdown.to?.map((val) => (
                  <option key={val._id} value={val._id}>
                    {type.toType === INTERNAL_TRANSFER.REF_TYPE[0]
                      ? `${val.firmName} - ${val.bankName}`
                      : `${val.name}`}
                  </option>
                ))}
              </Field>
              {errors.to && touched.to ? (
                <div className="error">{errors.to}</div>
              ) : null}
            </div>

            <div className="col-md-5 mb-3">
              <label>Opening Balance</label>
            </div>
            <div className="col-md-7 mb-3">
              <Field
                className="form-control"
                name="openingBalance"
                type="number"
              />
              {errors.openingBalance && touched.openingBalance ? (
                <div className="error">{errors.openingBalance}</div>
              ) : null}
            </div>

            <div className="col-md-5 mb-3">
              <label>Remarks</label>
            </div>
            <div className="col-md-7 mb-3">
              <Field className="form-control" type="text" name="remarks" />
              {errors.remarks && touched.remarks ? (
                <div className="error">{errors.remarks}</div>
              ) : null}
            </div>
          </div>

          <button
            className="btn bg-gradient-dark mx-auto d-block btn-lg mb-1"
            type="submit"
          >
            {isUpate ? "Update" : "Transfer"}
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default AddInternalTransfer;
