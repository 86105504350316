import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import { useLocation, useNavigate } from "react-router-dom";

import { CompanySchema } from "../../Helpers/Constants/Schemas";
import { CompanyFields } from "../../Helpers/Constants/InitialValues";
import { EndPoints } from "../../Helpers/Constants/EndPoints";

import API from "../../Helpers/Constants/EndPoints/Api";
import Axios from "../../Helpers/Universal/Axios/Axios";

const AddCompany = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [isUpate] = useState(state ? true : false);
  const [initialValues] = useState(isUpate ? state.val : CompanyFields);

  const handleSubmit = async (data) => {
    try {
      const { status } = await Axios.post(API.COMPANY.CREATE, data);
      if (status) navigate(EndPoints.COMPANY);
    } catch (err) {
      console.log("ERROR==>", err);
    }
  };

  const handleUpdateDocument = async (data) => {
    try {
      const { status } = await Axios.put(API.COMPANY.UPDATE, {
        company_id: initialValues._id,
        name: data.name,
      });
      if (status) navigate(EndPoints.COMPANY);
    } catch (err) {
      console.log("ERROR==>", err);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={CompanySchema}
      onSubmit={(values) => {
        isUpate ? handleUpdateDocument(values) : handleSubmit(values);
      }}
    >
      {({ errors, touched }) => (
        <Form className="border rounded shadow mx-auto w-50 mt-7 m-4 p-4">
          <div className="row">
            <div className="col-md-6 mb-3">
              <label>Company</label>
            </div>
            <div className="col-md-6 mb-3">
              <Field className="form-control" type="text" name="name" />
              {errors.name && touched.name ? (
                <div className="error">{errors.name}</div>
              ) : null}
            </div>
          </div>

          <button
            className="btn bg-gradient-dark mx-auto d-block btn-lg mb-1"
            type="submit"
          >
            {isUpate ? "Update" : "Add"}
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default AddCompany;
