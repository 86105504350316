import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import { EndPoints } from "../../Helpers/Constants/EndPoints";
import Loader from "../Common/Loader";

import API from "../../Helpers/Constants/EndPoints/Api";
import Axios from "../../Helpers/Universal/Axios/Axios";
import { CreatedAt } from "../../Helpers/Universal/Function/common";
import { LIMIT, NA, OFFSET, USER_TYPE } from "../../Helpers/Constants/Default";
import Paginate from "../Common/Paginate";
import { BANK } from "../../Helpers/Constants/Dropdowns";
import DeleteConfirmation from "../Common/DeleteConfirmation ";

const Bank = ({ permissions: { all, add, edit, delete: del } }) => {
  const { profile } = useSelector((val) => val);

  const [isAdmin] = useState(profile?.role === USER_TYPE[0] ? true : false);
  const [data, setData] = useState([]);
  const [visible, setVisible] = useState({
    loader: false,
    deleteConformation: { data: false, visible: false },
  });
  const [dropdown, setDropdown] = useState({
    banks: [],
    platforms: [],
    types: Object.keys(BANK.TYPE),
  });
  const [filter, setFilter] = useState({
    limit: LIMIT,
    offset: OFFSET,
    type: null,
    // type: isAdmin ? null : BANK.TYPE.ACCOUNTING,
  });

  const getData = async () => {
    try {
      setVisible((prev) => ({ ...prev, loader: true }));
      const { status, data } = await Axios.get(API.BANK.LISTING, filter);
      if (status) setData(data);
    } catch (err) {
      console.log("ERROR==>", err);
    } finally {
      setVisible((prev) => ({ ...prev, loader: false }));
    }
  };

  const getDropdowns = async () => {
    try {
      const [bankName, platform] = await Promise.all([
        Axios.get(API.DROPDOWN.BANK_NAMES),
        Axios.get(API.DROPDOWN.PLATFORM),
      ]);

      if (bankName.status)
        setDropdown((prev) => ({ ...prev, banks: bankName.data }));

      if (platform.status)
        setDropdown((prev) => ({ ...prev, platforms: platform.data }));
    } catch (err) {
      console.log("ERROR==>", err);
    }
  };

  const setFilters = (event, key) => {
    const value = event?.target?.value;

    value
      ? setFilter((prev) => ({ ...prev, [key]: value }))
      : setFilter((prev) => {
          delete prev[key];
          return { ...prev };
        });
  };

  useEffect(() => {
    getDropdowns();
  }, []);

  useEffect(() => {
    getData();
  }, [filter]);

  return (
    <>
      {visible.loader && <Loader />}
      {visible.deleteConformation.visible && (
        <DeleteConfirmation
          visible={visible}
          setVisible={setVisible}
          setData={setData}
          API={API.BANK.DELETE}
        />
      )}
      <div className="container-fluid py-3">
        <div className="dateselect_navigation d-flex justify-content-between align-items-center">
          <div className="d-flex">
            <select
              className="form-select text-start btn bg-gradient-dark btn-lg mb-0"
              onChange={(e) => setFilters(e, "platform_id")}
            >
              <option value="">Platforms</option>
              {dropdown.platforms?.map((val) => (
                <option key={val._id} value={val._id}>
                  {val.name}
                </option>
              ))}
            </select>
            <select
              className="form-select text-start btn bg-gradient-dark btn-lg mb-0 mx-2"
              onChange={(e) => setFilters(e, "bank")}
            >
              <option value="">Banks</option>
              {dropdown.banks?.map((val) => (
                <option key={val._id} value={val.bankName}>
                  {val.bankName}
                </option>
              ))}
            </select>
            {/* {isAdmin && ( */}
            <select
              className="form-select text-start btn bg-gradient-dark btn-lg mb-0 mx-2"
              onChange={(e) => setFilters(e, "type")}
            >
              <option value="">Type</option>
              {dropdown.types?.map((val) => (
                <option key={val} value={BANK.TYPE[val]}>
                  {val}
                </option>
              ))}
            </select>
            {/* )} */}
            <div className="d-flex position-relative bg-gradient-dark rounded">
              <i
                className="fa fa-search position-absolute top-50 translate-middle ms-3 text-white"
                aria-hidden="true"
              ></i>
              <input
                onChange={(e) => setFilters(e, "search")}
                type="search"
                className="text-start bg-gradient-dark p-2 btn text-lowercase border-0 text-white btn-lg mb-1 mx-4"
                style={{ cursor: "unset" }}
                name="gsearch"
              ></input>
            </div>
          </div>
          {(all || add) && (
            <Link to={EndPoints.ADD_BANK}>
              <button className="btn bg-gradient-dark float-end d-flex btn-md mb-1">
                <i className="fas fa-plus me-2 mt-1" aria-hidden="true"></i>
                Add
              </button>
            </Link>
          )}
        </div>

        <div className="overflow-x-scroll w-100">
          <table className="table-striped table align-middle mt-3 gate_way">
            <thead className="bg-light text-dark">
              <tr>
                <th scope="col" className="text-uppercase">
                  #
                </th>
                <th scope="col" className="text-uppercase">
                  Date
                </th>
                <th scope="col" className="text-uppercase">
                  Firm
                </th>
                <th scope="col" className="text-uppercase">
                  Bank
                </th>
                <th scope="col" className="text-uppercase">
                  Platform
                </th>
                <th scope="col" className="text-uppercase">
                  Secondary <br></br> Platforms
                </th>
                <th scope="col" className="text-uppercase">
                  Balance Amount
                </th>
                <th scope="col" className="text-uppercase">
                  Added By
                </th>
                {(all || edit || del) && (
                  <th scope="col" className="text-uppercase">
                    Action
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {data?.data?.map((val, i) => (
                <tr key={val._id}>
                  <td>{++i + filter.offset}</td>
                  <td>{CreatedAt(val.createdAt)}</td>
                  <td>{val.firmName}</td>
                  <td>{val.bankName}</td>
                  <td>{val.platform?.name || NA}</td>
                  <td>
                    {val.secondaryPlatforms?.map((val) => (
                      <div key={val._id}>
                        {val.name}
                        <br></br>
                      </div>
                    ))}
                  </td>
                  <td>{val.balanceAmount}</td>
                  <td>{val.createdBy?.name || NA}</td>
                  {(all || edit || del) && (
                    <td>
                      {(all || edit) && (
                        <Link to={EndPoints.ADD_BANK} state={{ val }}>
                          <i className="fas fa-edit me-2 pe-auto"></i>
                        </Link>
                      )}
                      {(all || del) && (
                        <i
                          className="material-icons text-danger pe-auto"
                          onClick={() =>
                            setVisible((prev) => ({
                              ...prev,
                              deleteConformation: {
                                data: { _id: val._id, name: val.firmName },
                                visible: true,
                              },
                            }))
                          }
                        >
                          delete
                        </i>
                      )}
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Paginate count={data?.count} filter={filter} setFilter={setFilter} />
      </div>
    </>
  );
};

export default Bank;
