import React from "react";
import Axios from "../../Helpers/Universal/Axios/Axios";

const DeleteConfirmation = ({ visible, setVisible, setData, API }) => {
  const hide = () => {
    setVisible((prev) => ({
      ...prev,
      deleteConformation: { ...prev.deleteConformation, visible: false },
    }));
  };

  const delData = async () => {
    try {
      await Axios.delete(API + "/" + visible.deleteConformation.data?._id);
      setData((prev) => ({
        ...prev,
        data: prev?.data?.filter(
          (val) => val._id !== visible.deleteConformation.data?._id
        ),
      }));
    } catch (err) {
      console.log("ERROR==>", err);
    } finally {
      hide();
    }
  };

  return (
    <div className="bg_viewpopup" style={{ zIndex: "999999" }}>
      <div className="successfull delete-modal bg-white p-4 pb-0 w-50 top-50 start-50 end-0 border border-secondary rounded text-center position-absolute">
        <div className="modal-header">
          <h5>Delete Confirmation</h5>
          <label onClick={hide}>×</label>
        </div>
        <div className="modal-bdy">
          <p className="text-dark font-weight-bold border-bottom pb-3">
            Are you shure want to delete "
            {visible.deleteConformation.data?.name}"
          </p>
        </div>
        <div className="modal-footer">
          <button
            className="text-center btn btn-secondary text-white  "
            onClick={hide}
          >
            Cancel
          </button>
          <button
            className="text-center btn btn-primary text-white "
            onClick={delData}
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirmation;
