import React, { useEffect, useState } from "react";

import Loader from "../Common/Loader";

import API from "../../Helpers/Constants/EndPoints/Api";
import Axios from "../../Helpers/Universal/Axios/Axios";
import Paginate from "../Common/Paginate";
import { LIMIT, OFFSET } from "../../Helpers/Constants/Default";

const PGCurrentBalance = () => {
  const [visible, setVisible] = useState({ loader: false });
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState({ limit: LIMIT, offset: OFFSET });

  const getData = async () => {
    try {
      setVisible({ loader: true });
      const { status, data } = await Axios.get(
        API.PAYMENT_GATEWAY.BALANCE,
        filter
      );
      if (status) setData(data);
    } catch (err) {
      console.log("ERROR==>", err);
    } finally {
      setVisible({ loader: false });
    }
  };

  const setFilters = (event, key) => {
    const value = event?.target?.value;

    value
      ? setFilter((prev) => ({ ...prev, [key]: value }))
      : setFilter((prev) => {
          delete prev[key];
          return { ...prev };
        });
  };

  useEffect(() => {
    getData();
  }, [filter]);

  return (
    <>
      {visible.loader && <Loader />}
      <div className="container-fluid py-3">
        <div className="dateselect_navigation d-flex justify-content-between align-items-center">
          <div className="d-flex position-relative bg-gradient-dark rounded">
            <i
              className="fa fa-search position-absolute top-50 translate-middle ms-3 text-white"
              aria-hidden="true"
            ></i>
            <input
              onChange={(e) => setFilters(e, "search")}
              type="search"
              className="text-start bg-gradient-dark p-2 btn text-lowercase border-0 text-white btn-lg mb-1 mx-4"
              style={{ cursor: "unset" }}
              name="gsearch"
            ></input>
          </div>
        </div>
        <div className="overflow-x-scroll w-100">
          <table className="table-striped table align-middle mt-3 gate_way">
            <thead className="bg-light text-dark">
              <tr>
                <th scope="col" className="text-uppercase">
                  #
                </th>
                <th scope="col" className="text-uppercase">
                  Payment Gateway
                </th>
                <th scope="col" className="text-uppercase">
                  Current Balance
                </th>
              </tr>
            </thead>
            <tbody>
              {data?.data?.map((val, i) => (
                <tr key={val._id}>
                  <td>{++i + filter.offset}</td>
                  <td>{val.name} </td>
                  <td
                    style={
                      val.balance > 0 ? { color: "green" } : { color: "red" }
                    }
                  >
                    {val.balance || 0}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Paginate count={data?.count} filter={filter} setFilter={setFilter} />
      </div>
    </>
  );
};

export default PGCurrentBalance;
