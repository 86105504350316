export default {
  LOGIN: {
    ADMIN: "/admin/login",
    USER: "/user/login",
  },
  PROFILE: "/admin/profile",
  UPLOAD_FILE: "/upload/files",
  DASHBOARD: {
    ADMIN: "/dashboard/listing",
    TABLE_DATA: "/dashboard/sub/listing",
    PDF: "/dashboard/pdf",
    ACCOUNTING_PDF: "/dashboard/accounting/pdf",
  },
  DROPDOWN: {
    PLATFORM: "/dropdown/platforms",
    PAYMENT_GATEWAY: "/dropdown/paymentgateways",
    PARTICULAR: "/dropdown/particulars",
    SUB_PARTICULAR: "/dropdown/subparticulars",
    ROLE: "/dropdown/roles",
    EXPENSE_TYPE: "/dropdown/expensetypes",
    BANK: "/dropdown/banks",
    BANK_NAMES: "/dropdown/banknames",
    COMPANY: "/dropdown/companies",
  },
  PLATFORM: {
    CREATE: "/platform/create",
    LISTING: "/platform/listing",
    UPDATE: "/platform/update",
    DELETE: "/platform/delete",
  },
  PAYMENT_GATEWAY: {
    CREATE: "/paymentgateway/create",
    LISTING: "/paymentgateway/listing",
    UPDATE: "/paymentgateway/update",
    DELETE: "/paymentgateway/delete",
    BALANCE: "/paymentgateway/balance",
  },
  SUB_PARTICULAR: {
    CREATE: "/subparticular/create",
    LISTING: "/subparticular/listing",
    UPDATE: "/subparticular/update",
    DELETE: "/subparticular/delete",
  },
  EXPENSE_TYPE: {
    CREATE: "/expensetype/create",
    LISTING: "/expensetype/listing",
    UPDATE: "/expensetype/update",
    DELETE: "/expensetype/delete",
  },
  BANK: {
    CREATE: "/bank/create",
    LISTING: "/bank/listing",
    UPDATE: "/bank/update",
    DELETE: "/bank/delete",
  },
  ROLE: {
    CREATE: "/role/create",
    LISTING: "/role/listing",
    UPDATE: "/role/update",
    DELETE: "/role/delete",
  },
  USER: {
    CREATE: "/user/create",
    LISTING: "/user/listing",
    UPDATE: "/user/update",
    DELETE: "/user/delete",
  },
  CUSTOM_EXPENSE: {
    CREATE: "/customexpense/create",
    LISTING: "/customexpense/listing",
    UPDATE: "/customexpense/update",
    DELETE: "/customexpense/delete",
  },
  P2P: {
    CREATE: "/p2paccount/create",
    LISTING: "/p2paccount/listing",
    UPDATE: "/p2paccount/update",
    DELETE: "/p2paccount/delete",
  },
  INTERNAL_TRANSFER: {
    CREATE: "/internaltransfer/create",
    LISTING: "/internaltransfer/listing",
    UPDATE: "/internaltransfer/update",
    DELETE: "/internaltransfer/delete",
  },
  ACCOUNTING: {
    CREATE: "/accounting/create",
    LISTING: "/accounting/listing",
    UPDATE: "/accounting/update",
    DELETE: "/accounting/delete",
  },
  TRANSACTION: {
    CREATE: "/transaction/create",
    LISTING: "/transaction/listing",
    UPDATE: "/transaction/update",
    DELETE: "/transaction/delete",
  },
  TRANSFER: {
    CREATE: "/transfer/create",
    LISTING: "/transfer/listing",
    UPDATE: "/transfer/update",
    DELETE: "/transfer/delete",
  },
  COMPANY: {
    CREATE: "/company/create",
    LISTING: "/company/listing",
    UPDATE: "/company/update",
    DELETE: "/company/delete",
  },
  ENTRY: {
    CREATE: "/entry/create",
    LISTING: "/entry/listing",
    UPDATE: "/entry/update",
    DELETE: "/entry/delete",
  },
  PARTICULAR: {
    CREATE: "/particular/create",
    LISTING: "/particular/listing",
    UPDATE: "/particular/update",
    DELETE: "/particular/delete",
  },
};
