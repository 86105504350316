import React, { useState } from "react";
import { Formik, Form, Field } from "formik";

import { EntrySchema } from "../../Helpers/Constants/Schemas";
import { EntryFields } from "../../Helpers/Constants/InitialValues";

const PopForm = ({ value, setVisible, handleSubmit, handleUpdate }) => {
  const [initialValues] = useState(value?.isUpdate ? value : EntryFields);

  return (
    <div className="bg_viewpopup" style={{ zIndex: "999999" }}>
      <div className="successfull bg-white p-4 w-30 top-50 start-50 end-0 border rounded text-center position-absolute">
        <div
          onClick={() => setVisible((prev) => ({ ...prev, popForm: false }))}
          className="cross_icon12 bg-gradient-dark end-1 position-absolute rounded-circle text-white cursor-pointer"
        >
          <label className="cursor-pointer">&times;</label>
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={EntrySchema}
          onSubmit={(values) => {
            value?.isUpdate ? handleUpdate(values) : handleSubmit(values);
          }}
        >
          {({ errors, touched }) => (
            <Form className=" mt-2 m-2 p-4">
              <div className="row">
                <div className="col-md-3 mb-3">
                  <label className="mb-0 h-100 d-flex align-items-center">
                    Amount
                  </label>
                </div>
                <div className="col-md-9 mb-3">
                  <Field className="form-control" type="number" name="amount" />
                  {errors.amount && touched.amount ? (
                    <div className="error">{errors.amount}</div>
                  ) : null}
                </div>
              </div>

              <button
                className="btn bg-gradient-dark mx-auto d-block btn-lg mb-1"
                type="submit"
              >
                {value?.isUpdate ? "Update" : "Add"}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default PopForm;
