import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import Switch from "react-switch";

import { UpdateParam } from "../../Helpers/Universal/Function/common";

import { PaymentGatewaySchema } from "../../Helpers/Constants/Schemas";
import { PaymentGatewayFields } from "../../Helpers/Constants/InitialValues";

import { EndPoints } from "../../Helpers/Constants/EndPoints";
import CustomSelect from "../Common/CustomSelect";

import API from "../../Helpers/Constants/EndPoints/Api";
import Axios from "../../Helpers/Universal/Axios/Axios";

const AddPaymentGateway = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [isUpate] = useState(state ? true : false);
  const [initialValues] = useState(
    isUpate
      ? {
          ...state?.val,
          platforms: state?.val?.platforms?.map((val) => val?._id),
        }
      : PaymentGatewayFields.required
  );

  const [toggle, setToggle] = useState({
    payIn: isUpate ? initialValues.payIn : false,
    payOut: isUpate ? initialValues.payOut : false,
  });
  const [platform, setPlatform] = useState([]);

  const getPlatforms = async () => {
    try {
      const { status, data } = await Axios.get(API.DROPDOWN.PLATFORM);
      if (status) setPlatform(data);
    } catch (err) {
      console.log("ERROR==>", err);
    }
  };

  const toggleFun = (key, value) => {
    setToggle((prev) => ({ ...prev, [key]: !value }));
  };

  const handleSubmit = async (data) => {
    try {
      const { status } = await Axios.post(API.PAYMENT_GATEWAY.CREATE, data);
      if (status) navigate(EndPoints.PAYMENT_GATEWAY);
    } catch (err) {
      console.log("ERROR==>", err);
    }
  };

  const handleUpdateDocument = async (data) => {
    try {
      const params = await UpdateParam(PaymentGatewayFields, data);
      const { status } = await Axios.patch(API.PAYMENT_GATEWAY.UPDATE, {
        paymentGateway_id: initialValues._id,
        ...params,
      });
      if (status) navigate(EndPoints.PAYMENT_GATEWAY);
    } catch (err) {
      console.log("ERROR==>", err);
    }
  };

  useEffect(() => {
    getPlatforms();
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={PaymentGatewaySchema}
      onSubmit={(values, { resetForm }) => {
        const valObj = {
          ...values,
          payIn: toggle.payIn,
          payOut: toggle.payOut,
        };
        isUpate ? handleUpdateDocument(valObj) : handleSubmit(valObj);
        // resetForm();
      }}
    >
      {({ errors, touched }) => (
        <Form className="border rounded shadow mx-auto w-50 mt-7 m-4 p-4">
          <div className="row">
            <div className="col-md-6 mb-3">
              <label>Payment Gateway Name</label>
            </div>
            <div className="col-md-6 mb-3">
              <Field className="form-control" name="name" type="text" />
              {errors.name && touched.name ? (
                <div className="error">{errors.name}</div>
              ) : null}
            </div>

            <div className="col-md-6 mb-3">
              <label>Platform</label>
            </div>
            <div className="col-md-6 mb-3">
              <Field
                name="platforms"
                options={platform.map((val) => ({
                  label: val.name,
                  value: val._id,
                }))}
                component={CustomSelect}
                placeholder="Choose Platforms"
                isMulti={true}
              />
              {errors.platforms && touched.platforms ? (
                <div className="error">{errors.platforms}</div>
              ) : null}
            </div>

            <div className="col-md-6 mb-3">
              <label>Pay In</label>
            </div>
            <div className="col-md-6 mb-3">
              <Switch
                onChange={() => toggleFun("payIn", toggle.payIn)}
                checked={toggle.payIn === true}
              />
            </div>

            <div className="col-md-6 mb-3">
              <label>Pay Out</label>
            </div>
            <div className="col-md-6 mb-3">
              <Switch
                onChange={() => toggleFun("payOut", toggle.payOut)}
                checked={toggle.payOut === true}
              />
            </div>

            <div className="col-md-6 mb-3">
              <label>Opening Balance</label>
            </div>
            <div className="col-md-6 mb-3">
              <Field
                className="form-control"
                type="number"
                name="openingBalance"
              />
              {errors.openingBalance && touched.openingBalance ? (
                <div className="error">{errors.openingBalance}</div>
              ) : null}
            </div>

            <div className="col-md-6 mb-3">
              <label>Remarks</label>
            </div>
            <div className="col-md-6 mb-3">
              <Field className="form-control" type="text" name="remarks" />
              {errors.remarks && touched.remarks ? (
                <div className="error">{errors.remarks}</div>
              ) : null}
            </div>
          </div>

          <button
            className="btn bg-gradient-dark mx-auto d-block btn-lg mb-1"
            type="submit"
          >
            {isUpate ? "Update" : "Add"}
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default AddPaymentGateway;
