import React from "react";
import DateRangePicker from "react-daterange-picker";
import "react-daterange-picker/dist/css/react-calendar.css";
import { useState } from "react";

const DatePicker = ({ filter, setFilter }) => {
  const [state, setState] = useState({ isOpen: false, value: false });

  const onSelect = (value, states) => {
    setState({ value, states });
    setFilter((prev) => ({
      ...prev,
      startDate: value?.start?.format("YYYY-MM-DD"),
      endDate: value?.end?.format("YYYY-MM-DD"),
    }));
  };

  const onToggle = () => {
    setState({ isOpen: !state.isOpen });
  };

  return (
    <>
      <div className="position-relative">
        <input
          type="button"
          className="btn bg-gradient-dark btn-lg mb-1 ps-5"
          value={
            filter.startDate && filter.endDate
              ? `${filter.startDate} - ${filter.endDate}`
              : `____-__-__  -  ____-__-__`
          }
          onClick={onToggle}
        />
        <i
          className="fas fa-calendar fa-lg position-absolute ms-4 start-0 translate-middle top-50 text-white"
          aria-hidden="true"
        ></i>
        {state.isOpen && (
          <DateRangePicker
            value={state.value}
            onSelect={onSelect}
            singleDateRange={true}
          />
        )}
      </div>
    </>
  );
};

export default DatePicker;
