import React from "react";
import { useSelector } from "react-redux";

import AdminDashboard from "./AdminDashboard";
import AccDashboard from "./AccDashboard";

import { USER_TYPE } from "../../Helpers/Constants/Default";

const Dashboard = ({ permissions }) => {
  const { profile } = useSelector((val) => val);

  if (profile.role === USER_TYPE[0]) return <AdminDashboard />;
  else if (profile.role === USER_TYPE[1])
    return <AccDashboard permissions={permissions} />;
  else return null;
};

export default Dashboard;
