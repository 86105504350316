export const PaymentFields = {
  required: { platform: "" },
  optional: {
    automaticDeposit: [{ provider: "", provider2: "", amount: "" }],
    manualDeposit: [{ type: "", bank: "", amount: "" }],
    depositFailed: [{ provider: "", amount: "" }],
    autoWithdrawal: [{ provider: "", amount: "" }],
    manualWithdrawal: [{ provider: "", amount: "" }],
    withdrawalProcessing: [{ provider: "", amount: "" }],
    withdrawalFailed: [{ provider: "", amount: "" }],
    wrongDeposit: "",
    wrongWithdrawal: "",
    incorrectSettlement: "",
    payoutReceived: { text: "", amount: "" },
    bank: [{ bank: "", amount: "" }],
    GST: "",
    remarks: "",
    custom: [],
  },
};

export const BankFields = {
  required: { firmName: "", bankName: "", type: "" },
  optional: {
    platform: "",
    secondaryPlatforms: [],
    balanceAmount: 0,
    proprietorName: "",
    accountNumber: "",
    ifscCode: "",
    accountType: "",
    branchName: "",
    mobileNumber: "",
    emailId: "",
    password: "",
    customerId: "",
    internetBankingUser: "",
    internetBankingPin: "",
    aadharCardNumber: "",
    DOB: "",
    atmPin: "",
    debitCardNumber: "",
    debitCardExpiry: "",
    debitCardCvv: "",
    referenceName: "",
    dateOfIssue: "",
    mobilePhone: "",
    currentBalnace: 0,
  },
};

export const InternalTransferFields = {
  required: {
    platform: "",
    from: "",
    fromType: "",
    amount: "",
    to: "",
    toType: "",
  },
  optional: { openingBalance: "", remarks: "" },
};

export const PaymentGatewayFields = {
  required: { name: "" },
  optional: {
    platforms: [],
    payIn: false,
    payOut: false,
    openingBalance: 0,
    remarks: "",
  },
};

export const P2pFields = {
  required: {
    bank: "",
    bankAmount: "",
    dashboardAmount: "",
  },
  optional: { dashboardFiles: [], bankFiles: [] },
};

export const PlatformFields = { name: "" };

export const ParticularFields = { name: "" };

export const CompanyFields = { name: "" };

export const ExpenseTypeFields = {
  required: { name: "" },
  optional: {},
};

export const CustomExpenseFields = {
  required: {
    platform: "",
    bank: "",
    expenseType: "",
    amount: "",
    approvedBy: "",
  },
  optional: { remarks: "", files: [] },
};

export const LoginFileds = {
  email: "",
  password: "",
};

export const AccountingFileds = {
  required: {
    date: new Date().toISOString().substr(0, 10),
    company: "",
    type: "",
    paymentMode: "",
    amount: "",
    narration: "",
  },
  optional: {
    bank: "",
    particular: "",
    subParticular: "",
    approvedBy: "",
    voucherNumber: "",
  },
};

export const SubParticularFields = {
  required: { particular: "", name: "" },
  optional: {},
};

export const CashBalanceFields = {
  fromDate: "",
  toDate: "",
  company: "",
  paymentMode: "",
};

export const RoleFields = {
  required: { name: "", status: 1 },
  optional: { permissions: [] },
};

export const UserFields = {
  required: { name: "", email: "", password: "", role: "" },
  optional: { status: 1 },
};

export const TransferFileds = {
  required: {
    fromCompany: "",
    paymentMode: "",
    amount: "",
    toCompany: "",
  },
  optional: {},
};

export const EntryFields = {
  amount: "",
};

const values = {
  all: false,
  view: false,
  add: false,
  edit: false,
  delete: false,
};

export const Permissions = {
  permissions: [
    { field: "dashboard", name: "Dashboard", ...values },
    { field: "transaction", name: "Transactions", ...values },
    { field: "accounting", name: "Accounting", ...values },
    { field: "transfer", name: "Transfer", ...values },
    { field: "internal-transfer", name: "Internal Transfer", ...values },
    { field: "p2p-account", name: "P2P Account", ...values },
    { field: "custom-expense", name: "Custom Expenses", ...values },
    { field: "bank-current-balance", name: "Bank Current Balance", ...values },
    { field: "pg-current-balance", name: "PG Current Balance", ...values },
    { field: "bank", name: "Bank", ...values },
    { field: "payment-gateway", name: "Payment Gateway", ...values },
    { field: "platform", name: "Platform", ...values },
    { field: "sub-particular", name: "Sub Particulars", ...values },
    { field: "expense-type", name: "Type of Expenses", ...values },
  ],
};

export const PopTableData = {
  ReceivedAuto: [
    {
      head: "Automatic Deposit",
      body: "automaticDeposit",
      isArray: true,
      populate: "automaticDeposit",
    },
  ],
  ReceivedManual: [
    {
      head: "Manual Deposit",
      body: "manualDeposit",
      isArray: true,
      populate: "",
    },
  ],
  ReceivedOthers: [
    {
      head: "Wrong Deposit",
      body: "wrongDeposit",
      isArray: false,
      populate: "",
    },
    {
      head: "Withdrawal Failed",
      body: "withdrawalFailed",
      isArray: true,
      populate: "withdrawalFailed",
    },
    {
      head: "Custom",
      body: "custom",
      isArray: true,
      populate: "",
      drCr: 2,
    },
  ],
  PaidAuto: [
    {
      head: "Auto Withdrawal",
      body: "autoWithdrawal",
      isArray: true,
      populate: true,
    },
  ],
  PaidManual: [
    {
      head: "Manual Withdrawal",
      body: "manualWithdrawal",
      isArray: true,
      populate: true,
    },
  ],
  PaidOthers: [
    {
      head: "Wrong Withdrawal",
      body: "wrongWithdrawal",
      isArray: false,
      populate: false,
    },
    {
      head: "Withdrawal Failed",
      body: "withdrawalFailed",
      isArray: true,
      populate: true,
    },
    {
      head: "Withdrawal Processing",
      body: "withdrawalProcessing",
      isArray: true,
      populate: true,
    },
    {
      head: "Incorrect Settlement",
      body: "incorrectSettlement",
      isArray: false,
      populate: false,
    },
    {
      head: "Custom",
      body: "custom",
      isArray: true,
      populate: false,
      drCr: 1,
    },
  ],
};
