export const DB = {
  BANK: "bank",
  INTERNAL_TRANSFER: "internalTransfer",
  PAYMENT: "payment",
  PAYMENT_GATEWAY: "paymentGateway",
  PLATFORM: "platform",
  EXPENSE_TYPE: "expenseType",
  CUSTOM_EXPENSE: "customExpense",
  P2P_ACC: "p2pAcc",
  USER: "user",
  ACCOUNTING: "accounting",
  SUB_PARTICULAR: "subParticular",
  CASH_BALANCE: "cashBalance",
  ROLE: "role",
};
