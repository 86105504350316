import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Loader from "../Common/Loader";
import Paginate from "../Common/Paginate";
import PopForm from "../Common/PopForm";
import DatePicker from "../Common/DatePicker";

import API from "../../Helpers/Constants/EndPoints/Api";
import Axios from "../../Helpers/Universal/Axios/Axios";
import { CreatedAt } from "../../Helpers/Universal/Function/common";
import { LIMIT, NA, OFFSET } from "../../Helpers/Constants/Default";
import { ENTRY } from "../../Helpers/Constants/Dropdowns";
import { EndPoints } from "../../Helpers/Constants/EndPoints";
import DeleteConfirmation from "../Common/DeleteConfirmation ";

const ViewCompany = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const [value, setValue] = useState(
    state?.val ? { ...state.val, isUpdate: false } : false
  );

  useEffect(() => {
    value || navigate(EndPoints.COMPANY);
  }, [value]);

  const [visible, setVisible] = useState({
    loader: false,
    popForm: false,
    deleteConformation: { data: false, visible: false },
  });
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState({
    docId: value?._id,
    limit: LIMIT,
    offset: OFFSET,
  });

  const getData = async () => {
    try {
      setVisible((prev) => ({ ...prev, loader: true }));
      const { status, data } = await Axios.get(API.ENTRY.LISTING, filter);

      if (status) setData(data);
    } catch (err) {
      console.log("ERROR==>", err);
    } finally {
      setVisible((prev) => ({ ...prev, loader: false }));
    }
  };

  const handleSubmit = async (data) => {
    try {
      data.docId = value?._id;
      data.model = ENTRY.MODEL[0];
      data.type = ENTRY.TYPE.COMPANY;

      const { status } = await Axios.post(API.ENTRY.CREATE, data);
      if (status) setFilter((prev) => ({ ...prev }));
    } catch (err) {
      console.log("ERROR==>", err);
    } finally {
      setVisible((prev) => ({ ...prev, popForm: false }));
    }
  };

  const handleUpdate = async (data) => {
    try {
      const { status } = await Axios.patch(API.ENTRY.UPDATE, {
        entry_id: data.entry_id,
        amount: data.amount,
      });
      if (status) setFilter((prev) => ({ ...prev }));
    } catch (err) {
      console.log("ERROR==>", err);
    } finally {
      setValue((prev) => ({ ...prev, isUpdate: false }));
      setVisible((prev) => ({ ...prev, popForm: false }));
    }
  };

  useEffect(() => {
    getData();
  }, [filter]);

  return (
    <>
      {visible.loader && <Loader />}
      {visible.popForm && (
        <PopForm
          value={value}
          setVisible={setVisible}
          handleSubmit={handleSubmit}
          handleUpdate={handleUpdate}
        />
      )}
      {visible.deleteConformation.visible && (
        <DeleteConfirmation
          visible={visible}
          setVisible={setVisible}
          setData={setData}
          API={API.ENTRY.DELETE}
        />
      )}

      <h4 className="text-center mt-3 mb-0">{value?.name}</h4>

      <div className="container-fluid py-3">
        <div className="dateselect_navigation d-flex justify-content-between">
          <DatePicker filter={filter} setFilter={setFilter} />
          {(state?.all || state?.add) && (
            <button
              className="btn bg-gradient-dark float-end mb-0"
              onClick={() => setVisible((prev) => ({ ...prev, popForm: true }))}
            >
              <i className="fas fa-plus me-2 mt-1" aria-hidden="true"></i>
              Add
            </button>
          )}
        </div>

        <div className="overflow-x-scroll w-100">
          <table className="table-striped table align-middle mt-3 gate_way">
            <thead className="bg-light text-dark">
              <tr>
                <th scope="col" className="text-uppercase">
                  #
                </th>
                <th scope="col" className="text-uppercase">
                  Date
                </th>
                <th scope="col" className="text-uppercase">
                  Amount
                </th>
                <th scope="col" className="text-uppercase">
                  Added By
                </th>
                {(state?.all || state?.edit || state?.del) && (
                  <th scope="col" className="text-uppercase">
                    Action
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {data?.data?.map((val, i) => (
                <tr key={val._id}>
                  <td>{++i + filter.offset}</td>
                  <td>{CreatedAt(val.createdAt)}</td>
                  <td>{val.amount}</td>
                  <td>{val.createdBy?.name || NA}</td>
                  {(state?.all || state?.edit || state?.del) && (
                    <td>
                      {(state?.all || state?.edit) && (
                        <i
                          className="fas fa-edit me-2 pe-auto"
                          onClick={() => {
                            value &&
                              setValue((prev) => ({
                                ...prev,
                                amount: val.amount,
                                entry_id: val._id,
                                isUpdate: true,
                              }));
                            setVisible((prev) => ({
                              ...prev,
                              popForm: true,
                            }));
                          }}
                        ></i>
                      )}
                      {(state?.all || state?.del) && (
                        <i
                          className="material-icons text-danger pe-auto"
                          onClick={() =>
                            setVisible((prev) => ({
                              ...prev,
                              deleteConformation: {
                                data: { _id: val._id, name: val.amount },
                                visible: true,
                              },
                            }))
                          }
                        >
                          delete
                        </i>
                      )}
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <Paginate count={data?.count} filter={filter} setFilter={setFilter} />
      </div>
    </>
  );
};

export default ViewCompany;
