import React, { useRef, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik, Form, Field, FieldArray } from "formik";

import { PaymentSchema } from "../../Helpers/Constants/Schemas";
import { PaymentFields } from "../../Helpers/Constants/InitialValues";
import FileUpload from "../Common/FileUpload";

import { EndPoints } from "../../Helpers/Constants/EndPoints";

import { TRANSACTION } from "../../Helpers/Constants/Dropdowns";

import API from "../../Helpers/Constants/EndPoints/Api";
import Axios from "../../Helpers/Universal/Axios/Axios";
import {
  FilesFormData,
  FilesUpload,
} from "../../Helpers/Universal/Function/common";
import { UPLOAD } from "../../Helpers/Constants/Default";
import Loader from "../Common/Loader";

const Payment = () => {
  const [visible, setVisible] = useState({ loader: false });

  const navigate = useNavigate();
  const { state } = useLocation();

  const files = useRef(null);

  const [isUpate] = useState(state ? true : false);

  const [initialValues] = useState(
    isUpate
      ? {
          ...state.val,
          platform: state?.val?.platform?._id,
          manualDeposit:
            state?.val?.manualDeposit?.length > 0
              ? state?.val?.manualDeposit
              : PaymentFields.optional.manualDeposit,
          automaticDeposit:
            state?.val?.automaticDeposit?.length > 0
              ? state?.val?.automaticDeposit?.map((val) => ({
                  provider: val?.docId?._id,
                  provider2: val?.docId_2?._id,
                  amount: val?.amount,
                }))
              : PaymentFields.optional.automaticDeposit,
          autoWithdrawal: checkPrevData(
            state?.val?.autoWithdrawal,
            PaymentFields.optional.autoWithdrawal,
            ["provider", "amount"]
          ),
          manualWithdrawal: checkPrevData(
            state?.val?.manualWithdrawal,
            PaymentFields.optional.manualWithdrawal,
            ["provider", "amount"]
          ),
          withdrawalProcessing: checkPrevData(
            state?.val?.withdrawalProcessing,
            PaymentFields.optional.withdrawalProcessing,
            ["provider", "amount"]
          ),
          withdrawalFailed: checkPrevData(
            state?.val?.withdrawalFailed,
            PaymentFields.optional.withdrawalFailed,
            ["provider", "amount"]
          ),
          depositFailed: checkPrevData(
            state?.val?.depositFailed,
            PaymentFields.optional.depositFailed,
            ["provider", "amount"]
          ),
          bank: checkPrevData(state?.val?.bank, PaymentFields.optional.bank, [
            "bank",
            "amount",
          ]),
        }
      : { ...PaymentFields.required, ...PaymentFields.optional }
  );

  const [platform, setPlatform] = useState([]);
  const [bank, setBank] = useState([]);

  const [remarks, setRemarks] = useState(false);
  const [provider, setProvider] = useState({
    automaticDeposit: [],
    autoWithdrawal: [],
    allProviders: [],
  });

  const getPlatforms = async () => {
    try {
      const { status, data } = await Axios.get(API.DROPDOWN.PLATFORM);
      if (status) setPlatform(data);
    } catch (err) {
      console.log("ERROR==>", err);
    }
  };

  const getProviders = async (platform_id) => {
    try {
      const [auDe, auWi, allPro] = await Promise.all([
        Axios.get(API.DROPDOWN.PAYMENT_GATEWAY, { platform_id, payIn: true }),
        Axios.get(API.DROPDOWN.PAYMENT_GATEWAY, { platform_id, payOut: true }),
        Axios.get(API.DROPDOWN.PAYMENT_GATEWAY, { platform_id }),
      ]);

      if (auDe.status)
        setProvider((prev) => ({ ...prev, automaticDeposit: auDe.data }));

      if (auWi.status)
        setProvider((prev) => ({ ...prev, autoWithdrawal: auWi.data }));

      if (allPro.status)
        setProvider((prev) => ({ ...prev, allProviders: allPro.data }));
    } catch (err) {
      console.log("ERROR==>", err);
    }
  };

  const getBanks = async (platform_id) => {
    try {
      const { status, data } = await Axios.get(API.DROPDOWN.BANK, {
        platform_id,
      });
      if (status) setBank(data);
    } catch (err) {
      console.log("ERROR==>", err);
    }
  };

  const handleSubmit = async (data) => {
    try {
      const values = {
        platform: data.platform,
        automaticDeposit: data.automaticDeposit
          ?.filter((val) => (val.provider || val.provider2) && val.amount)
          .map((val) => {
            const obj = { amount: val.amount };
            if (val.provider) obj.provider = val.provider;
            if (val.provider2) obj.provider2 = val.provider2;
            return obj;
          }),
        manualDeposit: data.manualDeposit?.filter(
          (val) => val.type && val.amount && val
        ),
        depositFailed: data.depositFailed?.filter(
          (val) => val.provider && val.amount && val
        ),
        autoWithdrawal: data.autoWithdrawal?.filter(
          (val) => val.provider && val.amount && val
        ),
        manualWithdrawal: data.manualWithdrawal?.filter(
          (val) => val.provider && val.amount && val
        ),
        withdrawalProcessing: data.withdrawalProcessing?.filter(
          (val) => val.provider && val.amount && val
        ),
        withdrawalFailed: data.withdrawalFailed?.filter(
          (val) => val.provider && val.amount && val
        ),
        wrongDeposit: data.wrongDeposit,
        wrongWithdrawal: data.wrongWithdrawal,
        incorrectSettlement: data.incorrectSettlement,
        payoutReceived: {
          ...(data.payoutReceived.text && { text: data.payoutReceived.text }),
          ...(data.payoutReceived.amount && {
            amount: data.payoutReceived.amount,
          }),
        },
        bank: data.bank?.filter((val) => val.bank && val.amount && val),
        GST: data.GST,
        remarks: data.remarks,
        custom: data?.custom?.filter(
          (val) => val.type && val.name && val.amount && val
        ),
      };

      if (files.current?.formData)
        values.files = await FilesUpload(files.current?.formData);

      const params = {};

      Object.keys(values).map((key) => {
        if (typeof values[key] === "object" && values[key]?.length > 0) {
          params[key] = values[key];
        } else if (values[key]) {
          params[key] = values[key];
        }
      });

      const { status } = await Axios.post(API.TRANSACTION.CREATE, params);
      if (status) navigate(EndPoints.DASHBOARD);
    } catch (err) {
      console.log("ERROR==>", err);
    } finally {
      setVisible({ loader: false });
    }
  };

  const handleUpdateDocument = async (data) => {
    try {
      // const params = await UpdateParam(PaymentFields, data);

      const values = {
        platform: data.platform,
        automaticDeposit: data.automaticDeposit
          ?.filter((val) => (val.provider || val.provider2) && val.amount)
          .map((val) => {
            const obj = { amount: val.amount };
            if (val.provider) obj.provider = val.provider;
            if (val.provider2) obj.provider2 = val.provider2;
            return obj;
          }),
        manualDeposit: data.manualDeposit?.filter(
          (val) => val.type && val.amount && val
        ),
        depositFailed: data.depositFailed?.filter(
          (val) => val.provider && val.amount && val
        ),
        autoWithdrawal: data.autoWithdrawal?.filter(
          (val) => val.provider && val.amount && val
        ),
        manualWithdrawal: data.manualWithdrawal?.filter(
          (val) => val.provider && val.amount && val
        ),
        withdrawalProcessing: data.withdrawalProcessing?.filter(
          (val) => val.provider && val.amount && val
        ),
        withdrawalFailed: data.withdrawalFailed?.filter(
          (val) => val.provider && val.amount && val
        ),
        wrongDeposit: data.wrongDeposit === "" ? 0 : data.wrongDeposit,
        wrongWithdrawal: data.wrongWithdrawal === "" ? 0 : data.wrongWithdrawal,
        incorrectSettlement:
          data.incorrectSettlement === "" ? 0 : data.incorrectSettlement,
        payoutReceived: {
          ...(data.payoutReceived?.text && { text: data.payoutReceived.text }),
          ...(data.payoutReceived?.amount && {
            amount: data.payoutReceived.amount,
          }),
        },
        bank: data.bank?.filter((val) => val.bank && val.amount && val),
        GST: data.GST === "" ? 0 : data.GST,
        remarks: data.remarks,
        custom: data?.custom?.filter(
          (val) => val.type && val.name && val.amount && val
        ),
      };

      // const params = {};

      // Object.keys(values).map((key) => {
      //   if (typeof values[key] === "object" && values[key]?.length > 0) {
      //     params[key] = values[key];
      //   } else if (values[key]) {
      //     params[key] = values[key];
      //   }
      // });

      const { status } = await Axios.patch(API.TRANSACTION.UPDATE, {
        transaction_id: initialValues._id,
        ...values,
      });
      if (status) navigate(EndPoints.DASHBOARD);
    } catch (err) {
      console.log("ERROR==>", err);
    } finally {
      setVisible({ loader: false });
    }
  };

  const checkPlatform = (e) => {
    const platform = e && e.target.value;
    if (platform) {
      getProviders(platform);
      getBanks(platform);
    } else if (!e) {
      getProviders(initialValues?.platform);
      getBanks(initialValues?.platform);
    }
  };

  function checkPrevData(array, prevArr, subVal) {
    const data = array?.map((val) => ({
      [subVal[0]]: val?.["docId"]?._id,
      [subVal[1]]: val?.[subVal[1]],
    }));

    return data.length > 0 ? data : prevArr;
  }

  const handleFileChange = (event) => {
    files.current.formData = FilesFormData(event, UPLOAD.Transaction);
  };

  useEffect(() => {
    getPlatforms();
    isUpate && checkPlatform(false);
  }, []);

  return (
    <>
      {visible.loader && <Loader />}
      <Formik
        initialValues={initialValues}
        validationSchema={PaymentSchema}
        onSubmit={(values, { resetForm }) => {
          setVisible({ loader: true });
          isUpate ? handleUpdateDocument(values) : handleSubmit(values);
          resetForm();
        }}
      >
        {({ errors, touched, values }) => (
          <Form className="border rounded shadow mx-auto w-80 mt-7 m-4 p-4">
            <div className="row">
              <div className="col-md-4 mb-3">
                <label>Platform</label>
              </div>
              <div className="col-md-8 mb-3">
                <Field
                  className="form-control"
                  name="platform"
                  as="select"
                  onClick={checkPlatform}
                >
                  <option value="">Choose a Platform</option>
                  {platform?.map((val, i) => (
                    <option key={`platform${i}`} value={val._id}>
                      {val.name}
                    </option>
                  ))}
                </Field>
                {errors.platform && touched.platform ? (
                  <div className="error">{errors.platform}</div>
                ) : null}
              </div>

              <div className="col-md-4 mb-3">
                <label>Automatic Deposit</label>
              </div>
              <div className="col-md-8 mb-3">
                <span>
                  <FieldArray name="automaticDeposit">
                    {({ push, remove }) =>
                      values.automaticDeposit?.map((val, i) => (
                        <div
                          className="d-flex add_input mb-3"
                          key={`automaticDeposit${i}`}
                        >
                          <Field
                            className="form-control"
                            name={`automaticDeposit[${i}].provider`}
                            as="select"
                          >
                            <option value="">Select Provider</option>
                            {provider.automaticDeposit?.map((val, i) => (
                              <option
                                key={`automaticDeposit${i}`}
                                value={val._id}
                              >
                                {val.name}
                              </option>
                            ))}
                          </Field>

                          <Field
                            className="form-control"
                            name={`automaticDeposit[${i}].provider2`}
                            as="select"
                          >
                            <option value="">Select Provider</option>
                            {provider.automaticDeposit?.map((val, i) => (
                              <option
                                key={`automaticDeposit-2-${i}`}
                                value={val._id}
                              >
                                {val.name}
                              </option>
                            ))}
                          </Field>

                          <Field
                            name={`automaticDeposit[${i}].amount`}
                            className="form-control"
                            type="number"
                          ></Field>

                          {i ? (
                            <button
                              className="bg-danger border btn-lg text-white ps-2 pe-2"
                              type="button"
                              onClick={() => remove(i)}
                            >
                              <i
                                className="fas fa-minus"
                                aria-hidden="true"
                              ></i>
                            </button>
                          ) : (
                            <button
                              className="bg-gradient-dark border btn-lg text-white ps-2 pe-2"
                              type="button"
                              onClick={() =>
                                push({
                                  provider: "",
                                  provider2: "",
                                  amount: "",
                                })
                              }
                            >
                              <i className="fas fa-plus" aria-hidden="true"></i>
                            </button>
                          )}
                        </div>
                      ))
                    }
                  </FieldArray>
                </span>
              </div>

              <div className="col-md-4 mb-3">
                <label>Manual Deposit</label>
              </div>
              <div className="col-md-8 mb-3">
                <span>
                  <FieldArray name="manualDeposit">
                    {({ push, remove }) =>
                      values.manualDeposit?.map((val, i) => (
                        <div
                          className="d-flex add_input mb-3"
                          key={`manualDeposit${i}`}
                        >
                          <Field
                            className="form-control"
                            name={`manualDeposit[${i}].type`}
                            as="select"
                          >
                            <option value="">Select a Type</option>
                            {Object.keys(TRANSACTION.TYPE)?.map((val, i) => (
                              <option
                                key={`manualDeposit${i}`}
                                value={TRANSACTION.TYPE[val]}
                              >
                                {val}
                              </option>
                            ))}
                          </Field>

                          <Field
                            className="form-control"
                            name={`manualDeposit[${i}].bank`}
                            as="select"
                          >
                            <option value="">Select Bank</option>
                            {bank?.map((val) => (
                              <option
                                key={`manualDeposit-${i}`}
                                value={val._id}
                              >
                                {val.firmName} - {val.bankName}
                              </option>
                            ))}
                          </Field>

                          <Field
                            name={`manualDeposit[${i}].amount`}
                            className="form-control"
                            type="number"
                          ></Field>

                          {i ? (
                            <button
                              className="bg-danger border btn-lg text-white ps-2 pe-2"
                              type="button"
                              onClick={() => remove(i)}
                            >
                              <i
                                className="fas fa-minus"
                                aria-hidden="true"
                              ></i>
                            </button>
                          ) : (
                            <button
                              className="bg-gradient-dark border btn-lg text-white ps-2 pe-2"
                              type="button"
                              onClick={() =>
                                push({ type: "", bank: "", amount: "" })
                              }
                            >
                              <i className="fas fa-plus" aria-hidden="true"></i>
                            </button>
                          )}
                        </div>
                      ))
                    }
                  </FieldArray>
                </span>
              </div>

              <div className="col-md-4 mb-3">
                <label>Deposit Failed</label>
              </div>
              <div className="col-md-8 mb-3">
                <span>
                  <FieldArray name="depositFailed">
                    {({ push, remove }) =>
                      values.depositFailed?.map((val, i) => (
                        <div
                          className="d-flex add_input mb-3"
                          key={`depositFailed${i}`}
                        >
                          <Field
                            className="form-control"
                            name={`depositFailed[${i}].provider`}
                            as="select"
                          >
                            <option value="">Select Provider</option>
                            {[
                              ...provider.allProviders,
                              // ...bank
                            ]?.map((val, i) => {
                              // if (val.firmName)
                              //   return (
                              //     <option
                              //       key={`depositFailed${i}`}
                              //       value={val._id}
                              //     >
                              //       {val.firmName} - {val.bankName}
                              //     </option>
                              //   );
                              // else
                              return (
                                <option
                                  key={`depositFailed${i}`}
                                  value={val._id}
                                >
                                  {val.name}
                                </option>
                              );
                            })}
                          </Field>
                          {errors[`depositFailed[${i}].provider`] &&
                          touched[`depositFailed[${i}].provider`] ? (
                            <div className="error">
                              {errors[`depositFailed[${i}].provider`]}
                            </div>
                          ) : null}

                          <Field
                            name={`depositFailed[${i}].amount`}
                            className="form-control"
                            type="number"
                          ></Field>
                          {errors[`depositFailed[${i}].amount`] &&
                          touched[`depositFailed[${i}].amount`] ? (
                            <div className="error">
                              {errors[`depositFailed[${i}].amount`]}
                            </div>
                          ) : null}

                          {i ? (
                            <button
                              className="bg-danger border btn-lg text-white ps-2 pe-2"
                              type="button"
                              onClick={() => remove(i)}
                            >
                              <i
                                className="fas fa-minus"
                                aria-hidden="true"
                              ></i>
                            </button>
                          ) : (
                            <button
                              className="bg-gradient-dark border btn-lg text-white ps-2 pe-2"
                              type="button"
                              onClick={() => push({ provider: "", amount: "" })}
                            >
                              <i className="fas fa-plus" aria-hidden="true"></i>
                            </button>
                          )}
                        </div>
                      ))
                    }
                  </FieldArray>
                </span>
              </div>

              <div className="col-md-4 mb-3">
                <label>Auto Withdrawal</label>
              </div>
              <div className="col-md-8 mb-3">
                <span>
                  <FieldArray name="autoWithdrawal">
                    {({ push, remove }) =>
                      values.autoWithdrawal?.map((val, i) => (
                        <div
                          className="d-flex add_input mb-3"
                          key={`autoWithdrawal${i}`}
                        >
                          <Field
                            className="form-control"
                            name={`autoWithdrawal[${i}].provider`}
                            as="select"
                          >
                            <option value="">Select Provider</option>
                            {provider.autoWithdrawal?.map((val, i) => (
                              <option
                                key={`autoWithdrawal${i}`}
                                value={val._id}
                              >
                                {val.name}
                              </option>
                            ))}
                          </Field>
                          {errors[`autoWithdrawal[${i}].provider`] &&
                          touched[`autoWithdrawal[${i}].provider`] ? (
                            <div className="error">
                              {errors[`autoWithdrawal[${i}].provider`]}
                            </div>
                          ) : null}

                          <Field
                            name={`autoWithdrawal[${i}].amount`}
                            className="form-control"
                            type="number"
                          ></Field>
                          {errors[`autoWithdrawal[${i}].amount`] &&
                          touched[`autoWithdrawal[${i}].amount`] ? (
                            <div className="error">
                              {errors[`autoWithdrawal[${i}].amount`]}
                            </div>
                          ) : null}

                          {i ? (
                            <button
                              className="bg-danger border btn-lg text-white ps-2 pe-2"
                              type="button"
                              onClick={() => remove(i)}
                            >
                              <i
                                className="fas fa-minus"
                                aria-hidden="true"
                              ></i>
                            </button>
                          ) : (
                            <button
                              className="bg-gradient-dark border btn-lg text-white ps-2 pe-2"
                              type="button"
                              onClick={() => push({ provider: "", amount: "" })}
                            >
                              <i className="fas fa-plus" aria-hidden="true"></i>
                            </button>
                          )}
                        </div>
                      ))
                    }
                  </FieldArray>
                </span>
              </div>

              <div className="col-md-4 mb-3">
                <label>Manual Withdrawal</label>
              </div>
              <div className="col-md-8 mb-3">
                <span>
                  <FieldArray name="manualWithdrawal">
                    {({ push, remove }) =>
                      values.manualWithdrawal?.map((val, i) => (
                        <div
                          className="d-flex add_input mb-3"
                          key={`manualWithdrawal${i}`}
                        >
                          <Field
                            className="form-control"
                            name={`manualWithdrawal[${i}].provider`}
                            as="select"
                          >
                            <option value="">Select Provider</option>
                            {[...provider.allProviders, ...bank]?.map(
                              (val, i) => {
                                if (val.firmName)
                                  return (
                                    <option
                                      key={`manualWithdrawal${i}`}
                                      value={val._id}
                                    >
                                      {val.firmName} - {val.bankName}
                                    </option>
                                  );
                                else
                                  return (
                                    <option
                                      key={`manualWithdrawa${i}`}
                                      value={val._id}
                                    >
                                      {val.name}
                                    </option>
                                  );
                              }
                            )}
                            {/* {provider.allProviders?.map((val, i) => (
                              <option key={i} value={val}>
                                {val}
                              </option>
                            ))} */}
                          </Field>
                          {errors[`manualWithdrawal[${i}].provider`] &&
                          touched[`manualWithdrawal[${i}].provider`] ? (
                            <div className="error">
                              {errors[`manualWithdrawal[${i}].provider`]}
                            </div>
                          ) : null}

                          <Field
                            name={`manualWithdrawal[${i}].amount`}
                            className="form-control"
                            type="number"
                          ></Field>
                          {errors[`manualWithdrawal[${i}].amount`] &&
                          touched[`manualWithdrawal[${i}].amount`] ? (
                            <div className="error">
                              {errors[`manualWithdrawal[${i}].amount`]}
                            </div>
                          ) : null}

                          {i ? (
                            <button
                              className="bg-danger border btn-lg text-white ps-2 pe-2"
                              type="button"
                              onClick={() => remove(i)}
                            >
                              <i
                                className="fas fa-minus"
                                aria-hidden="true"
                              ></i>
                            </button>
                          ) : (
                            <button
                              className="bg-gradient-dark border btn-lg text-white ps-2 pe-2"
                              type="button"
                              onClick={() => push({ provider: "", amount: "" })}
                            >
                              <i className="fas fa-plus" aria-hidden="true"></i>
                            </button>
                          )}
                        </div>
                      ))
                    }
                  </FieldArray>
                </span>
              </div>

              <div className="col-md-4 mb-3">
                <label>Withdrawal Processing</label>
              </div>
              <div className="col-md-8 mb-3">
                <span>
                  <FieldArray name="withdrawalProcessing">
                    {({ push, remove }) =>
                      values.withdrawalProcessing?.map((val, i) => (
                        <div
                          className="d-flex add_input mb-3"
                          key={`withdrawalProcessing${i}`}
                        >
                          <Field
                            className="form-control"
                            name={`withdrawalProcessing[${i}].provider`}
                            as="select"
                          >
                            <option value="">Select Provider</option>
                            {[
                              ...provider.allProviders,
                              // ...bank
                            ]?.map((val, i) => {
                              // if (val.firmName)
                              //   return (
                              //     <option
                              //       key={`withdrawalProcessing${i}`}
                              //       value={val._id}
                              //     >
                              //       {val.firmName} - {val.bankName}
                              //     </option>
                              //   );
                              // else
                              return (
                                <option
                                  key={`withdrawalProcessing${i}`}
                                  value={val._id}
                                >
                                  {val.name}
                                </option>
                              );
                            })}
                            {/* {provider.allProviders?.map((val, i) => (
                              <option key={i} value={val}>
                                {val}
                              </option>
                            ))} */}
                          </Field>
                          {errors[`withdrawalProcessing[${i}].provider`] &&
                          touched[`withdrawalProcessing[${i}].provider`] ? (
                            <div className="error">
                              {errors[`withdrawalProcessing[${i}].provider`]}
                            </div>
                          ) : null}

                          <Field
                            name={`withdrawalProcessing[${i}].amount`}
                            className="form-control"
                            type="number"
                          ></Field>
                          {errors[`withdrawalProcessing[${i}].amount`] &&
                          touched[`withdrawalProcessing[${i}].amount`] ? (
                            <div className="error">
                              {errors[`withdrawalProcessing[${i}].amount`]}
                            </div>
                          ) : null}

                          {i ? (
                            <button
                              className="bg-danger border btn-lg text-white ps-2 pe-2"
                              type="button"
                              onClick={() => remove(i)}
                            >
                              <i
                                className="fas fa-minus"
                                aria-hidden="true"
                              ></i>
                            </button>
                          ) : (
                            <button
                              className="bg-gradient-dark border btn-lg text-white ps-2 pe-2"
                              type="button"
                              onClick={() => push({ provider: "", amount: "" })}
                            >
                              <i className="fas fa-plus" aria-hidden="true"></i>
                            </button>
                          )}
                        </div>
                      ))
                    }
                  </FieldArray>
                </span>
              </div>

              <div className="col-md-4 mb-3">
                <label>Withdrawal Failed</label>
              </div>
              <div className="col-md-8 mb-3">
                <span>
                  <FieldArray name="withdrawalFailed">
                    {({ push, remove }) =>
                      values.withdrawalFailed?.map((val, i) => (
                        <div
                          className="d-flex add_input mb-3"
                          key={`withdrawalFailed${i}`}
                        >
                          <Field
                            className="form-control"
                            name={`withdrawalFailed[${i}].provider`}
                            as="select"
                          >
                            <option value="">Select Provider</option>
                            {[
                              ...provider.allProviders,
                              // ...bank
                            ]?.map((val, i) => {
                              // if (val.firmName)
                              //   return (
                              //     <option
                              //       key={`withdrawalFailed${i}`}
                              //       value={val._id}
                              //     >
                              //       {val.firmName} - {val.bankName}
                              //     </option>
                              //   );
                              // else
                              return (
                                <option
                                  key={`withdrawalFailed${i}`}
                                  value={val._id}
                                >
                                  {val.name}
                                </option>
                              );
                            })}
                          </Field>
                          {errors[`withdrawalFailed[${i}].provider`] &&
                          touched[`withdrawalFailed[${i}].provider`] ? (
                            <div className="error">
                              {errors[`withdrawalFailed[${i}].provider`]}
                            </div>
                          ) : null}

                          <Field
                            name={`withdrawalFailed[${i}].amount`}
                            className="form-control"
                            type="number"
                          ></Field>
                          {errors[`withdrawalFailed[${i}].amount`] &&
                          touched[`withdrawalFailed[${i}].amount`] ? (
                            <div className="error">
                              {errors[`withdrawalFailed[${i}].amount`]}
                            </div>
                          ) : null}

                          {i ? (
                            <button
                              className="bg-danger border btn-lg text-white ps-2 pe-2"
                              type="button"
                              onClick={() => remove(i)}
                            >
                              <i
                                className="fas fa-minus"
                                aria-hidden="true"
                              ></i>
                            </button>
                          ) : (
                            <button
                              className="bg-gradient-dark border btn-lg text-white ps-2 pe-2"
                              type="button"
                              onClick={() => push({ provider: "", amount: "" })}
                            >
                              <i className="fas fa-plus" aria-hidden="true"></i>
                            </button>
                          )}
                        </div>
                      ))
                    }
                  </FieldArray>
                </span>
              </div>

              <div className="col-md-4 mb-3">
                <label>Wrong Deposit</label>
              </div>
              <div className="col-md-8 mb-3">
                <Field
                  className="form-control"
                  name="wrongDeposit"
                  type="number"
                />
                {errors.wrongDeposit && touched.wrongDeposit ? (
                  <div className="error">{errors.wrongDeposit}</div>
                ) : null}
              </div>

              <div className="col-md-4 mb-3">
                <label>Wrong Withdrawal</label>
              </div>
              <div className="col-md-8 mb-3">
                <Field
                  className="form-control"
                  name="wrongWithdrawal"
                  type="number"
                />
                {errors.wrongWithdrawal && touched.wrongWithdrawal ? (
                  <div className="error">{errors.wrongWithdrawal}</div>
                ) : null}
              </div>

              <div className="col-md-4 mb-4">
                <label>Incorrect Settlement</label>
              </div>
              <div className="col-md-8 mb-4">
                <Field
                  className="form-control"
                  name="incorrectSettlement"
                  type="number"
                />
                {errors.incorrectSettlement && touched.incorrectSettlement ? (
                  <div className="error">{errors.incorrectSettlement}</div>
                ) : null}
              </div>

              <div className="col-md-4 mb-3">
                <label>Payout Received</label>
              </div>
              <div className="col-md-8 mb-3">
                <div className="row mb-3">
                  <div className="col-md-8">
                    <Field
                      name="payoutReceived.text"
                      className="form-control "
                      type="text"
                    ></Field>
                    {errors.payoutReceived?.text &&
                    touched.payoutReceived?.text ? (
                      <div className="error">{errors.payoutReceived.text}</div>
                    ) : null}
                  </div>
                  <div className="col-md-4">
                    <Field
                      className="form-control col-md-4"
                      name="payoutReceived.amount"
                      type="number"
                    />
                    {errors.payoutReceived?.amount &&
                    touched.payoutReceived?.amount ? (
                      <div className="error">
                        {errors.payoutReceived.amount}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="col-md-4 mb-3">
                <label>Select Bank</label>
              </div>
              <div className="col-md-8 mb-3">
                <span>
                  <FieldArray name="bank">
                    {({ push, remove }) =>
                      values.bank?.map((val, i) => (
                        <div className="d-flex add_input mb-3" key={`bank${i}`}>
                          <Field
                            className="form-control"
                            name={`bank[${i}].bank`}
                            as="select"
                          >
                            <option value="">Select Bank</option>
                            {bank?.map((val, i) => (
                              <option key={`bank${i}`} value={val._id}>
                                {val.firmName} - {val.bankName}
                              </option>
                            ))}
                          </Field>
                          {errors[`bank[${i}].bank`] &&
                          touched[`bank[${i}].bank`] ? (
                            <div className="error">
                              {errors[`bank[${i}].bank`]}
                            </div>
                          ) : null}

                          <Field
                            name={`bank[${i}].amount`}
                            className="form-control"
                            type="number"
                          ></Field>
                          {errors[`bank[${i}].amount`] &&
                          touched[`bank[${i}].amount`] ? (
                            <div className="error">
                              {errors[`bank[${i}].amount`]}
                            </div>
                          ) : null}

                          {i ? (
                            <button
                              className="bg-danger border btn-lg text-white ps-2 pe-2"
                              type="button"
                              onClick={() => remove(i)}
                            >
                              <i
                                className="fas fa-minus"
                                aria-hidden="true"
                              ></i>
                            </button>
                          ) : (
                            <button
                              className="bg-gradient-dark border btn-lg text-white ps-2 pe-2"
                              type="button"
                              onClick={() => push({ bank: "", amount: "" })}
                            >
                              <i className="fas fa-plus" aria-hidden="true"></i>
                            </button>
                          )}
                        </div>
                      ))
                    }
                  </FieldArray>
                </span>
              </div>

              <div className="col-md-4 mb-4">
                <label>GST</label>
              </div>
              <div className="col-md-8 mb-4">
                <Field className="form-control" name="GST" type="number" />
                {errors.GST && touched.GST ? (
                  <div className="error">{errors.GST}</div>
                ) : null}
                <label className="text-gray-500 font-bold">
                  <input
                    className="mr-2 leading-tight"
                    type="checkbox"
                    defaultChecked={remarks}
                    onClick={() => setRemarks((prev) => !prev)}
                  />
                  <span className="text-sm mx-2">remarks</span>
                </label>
              </div>

              {remarks && (
                <>
                  <div className="col-md-4 mb-4">
                    <label>Remarks</label>
                  </div>
                  <div className="col-md-8 mb-4">
                    <Field
                      className="form-control"
                      name="remarks"
                      type="text"
                    />
                    {errors.remarks && touched.remarks ? (
                      <div className="error">{errors.remarks}</div>
                    ) : null}
                  </div>
                </>
              )}

              <FieldArray name="custom">
                {({ push, remove }) => (
                  <>
                    {values.custom?.map((val, i) => (
                      <div key={`custom${i}`} className="col-md-12 mb-3">
                        <label className="text-gray-500 font-bold">
                          <input
                            className="mr-2 leading-tight"
                            type="checkbox"
                            defaultChecked={true}
                            onClick={() => remove(i)}
                          />
                          <span className="text-sm mx-2">Custom</span>
                        </label>
                        <span className="d-flex justify-content-between">
                          <Field
                            className="form-control w-30 me-3"
                            name={`custom[${i}].type`}
                            as="select"
                          >
                            <option value="">Transaction Type</option>
                            {Object.keys(TRANSACTION.CUSTOM_TYPE)?.map(
                              (val) => (
                                <option
                                  value={TRANSACTION.CUSTOM_TYPE[val]}
                                  key={val}
                                >
                                  {val}
                                </option>
                              )
                            )}
                          </Field>
                          <Field
                            className="form-control w-70 me-3"
                            type="text"
                            name={`custom[${i}].name`}
                          />
                          <Field
                            className="form-control w-49"
                            type="number"
                            name={`custom[${i}].amount`}
                          />
                        </span>
                      </div>
                    ))}
                    <label className="text-gray-500 font-bold">
                      <input
                        className="mr-2 leading-tight"
                        type="checkbox"
                        checked={false}
                        onChange={() =>
                          push({ type: "", name: "", amount: "" })
                        }
                      />
                      <span className="text-sm mx-2">Custom</span>
                    </label>
                  </>
                )}
              </FieldArray>

              {isUpate || (
                <FileUpload
                  name="files"
                  fileRef={files}
                  handleFileChange={handleFileChange}
                />
              )}
            </div>

            <button
              className="btn bg-gradient-dark mx-auto d-block btn-lg mb-1"
              type="submit"
            >
              Update
            </button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default Payment;
