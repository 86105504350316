import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";

import { AccountingSchema } from "../../Helpers/Constants/Schemas";
import { AccountingFileds } from "../../Helpers/Constants/InitialValues";

import { EndPoints } from "../../Helpers/Constants/EndPoints";
import { ACCOUNTING, BANK } from "../../Helpers/Constants/Dropdowns";

import API from "../../Helpers/Constants/EndPoints/Api";
import Axios from "../../Helpers/Universal/Axios/Axios";
import {
  FilesFormData,
  FilesUpload,
  UpdateParam,
} from "../../Helpers/Universal/Function/common";
import { useRef } from "react";
import FileUpload from "../Common/FileUpload";
import { NA, UPLOAD } from "../../Helpers/Constants/Default";
import Loader from "../Common/Loader";

const AddAccounting = () => {
  const [visible, setVisible] = useState({ loader: false });
  const navigate = useNavigate();
  const { state } = useLocation();

  const files = useRef(null);

  const [isUpate] = useState(state ? true : false);
  const [initialValues] = useState(
    isUpate
      ? {
          ...state.val,
          date: new Date(state?.val?.date).toISOString().substr(0, 10),
          subParticulars: state?.val?.subParticular?._id,
          particular: state?.val?.particular?._id,
          company: state?.val?.company?._id,
          paymentMode: state?.val?.paymentMode,
          bank: state?.val?.bank?._id,
        }
      : AccountingFileds.required
  );

  const [dropdown, setDropdown] = useState({
    companies: [],
    paticulars: [],
    subParticulars: [],
    banks: [],
  });
  const [isTypeReceipts, setIsTypeReceipts] = useState(false);
  const [MOP, setMOP] = useState(state?.val?.paymentMode || 0);

  const getDropdowns = async () => {
    try {
      const [com, par, bank] = await Promise.all([
        Axios.get(API.DROPDOWN.COMPANY),
        Axios.get(API.DROPDOWN.PARTICULAR),
        Axios.get(API.DROPDOWN.BANK, { type: BANK.TYPE.ACCOUNTING }),
      ]);

      if (com.status) setDropdown((prev) => ({ ...prev, companies: com.data }));
      if (par.status)
        setDropdown((prev) => ({ ...prev, paticulars: par.data }));
      if (bank.status) setDropdown((prev) => ({ ...prev, banks: bank.data }));
    } catch (err) {
      console.log("ERROR==>", err);
    }
  };

  const handleSubmit = async (data) => {
    try {
      if (files.current?.formData)
        data.files = await FilesUpload(files.current?.formData);

      if (data?.bank && data?.paymentMode == 0) delete data.bank;

      const keys = Object.keys(data);
      const params = {};

      keys?.map((key) => (data[key] ? (params[key] = data[key]) : null));

      const { status } = await Axios.post(API.ACCOUNTING.CREATE, params);
      if (status) navigate(EndPoints.ACCOUNTING);
    } catch (err) {
      console.log("ERROR==>", err);
    } finally {
      setVisible({ loader: false });
    }
  };

  const handleUpdateDocument = async (data) => {
    try {
      const params = await UpdateParam(AccountingFileds, data);

      const { status } = await Axios.patch(API.ACCOUNTING.UPDATE, {
        accounting_id: initialValues._id,
        ...params,
      });
      if (status) navigate(EndPoints.ACCOUNTING);
    } catch (err) {
      console.log("ERROR==>", err);
    } finally {
      setVisible({ loader: false });
    }
  };

  const getSubParticulars = async (e) => {
    try {
      const particular_id = e && e.target.value;

      if (particular_id) {
        const { status, data } = await Axios.get(API.DROPDOWN.SUB_PARTICULAR, {
          particular_id,
        });
        if (status) setDropdown((prev) => ({ ...prev, subParticulars: data }));
      }
    } catch (err) {
      console.log("ERROR==>", err);
    }
  };

  const handleFileChange = (event) => {
    files.current.formData = FilesFormData(event, UPLOAD.Accounting);
  };

  useEffect(() => {
    getDropdowns();
  }, []);

  return (
    <>
      {visible.loader && <Loader />}
      <Formik
        initialValues={initialValues}
        validationSchema={AccountingSchema}
        onSubmit={(values, { resetForm }) => {
          setVisible({ loader: true });
          isUpate ? handleUpdateDocument(values) : handleSubmit(values);
          resetForm();
        }}
      >
        {({ errors, touched, setValues }) => (
          <Form className="border rounded shadow mx-auto w-50 mt-7 m-4 p-4">
            <div className="row">
              <div className="col-md-6 mb-3">
                <label>Date</label>
              </div>
              <div className="col-md-6 mb-3">
                <Field className="form-control" name="date" type="date" />
                {errors.date && touched.date ? (
                  <div className="error">{errors.date}</div>
                ) : null}
              </div>

              <div className="col-md-6 mb-3">
                <label>Company</label>
              </div>
              <div className="col-md-6 mb-3">
                <Field className="form-control" name="company" as="select">
                  <option value="">Click to Select</option>
                  {dropdown.companies?.map((val) => (
                    <option key={val._id} value={val._id}>
                      {val.name}
                    </option>
                  ))}
                </Field>
                {errors.company && touched.company ? (
                  <div className="error">{errors.company}</div>
                ) : null}
              </div>

              <div className="col-md-6 mb-3">
                <label>Type</label>
              </div>
              <div className="col-md-6 mb-3">
                <Field
                  className="form-control"
                  name="type"
                  as="select"
                  onClick={(e) => {
                    const isReceipts = e.target.value == 1;
                    setIsTypeReceipts(isReceipts);
                    isReceipts &&
                      setValues((prev) => ({
                        ...prev,
                        particular: "",
                        subParticular: "",
                        approvedBy: "",
                        voucherNumber: "",
                      }));
                  }}
                >
                  <option value="">Click to Select</option>
                  {Object.keys(ACCOUNTING.TYPE)?.map((val, i) => (
                    <option key={i} value={ACCOUNTING.TYPE[val]}>
                      {val}
                    </option>
                  ))}
                </Field>
                {errors.type && touched.type ? (
                  <div className="error">{errors.type}</div>
                ) : null}
              </div>

              <div className="col-md-6 mb-3">
                <label>Mode of Payment</label>
              </div>
              <div className="col-md-6 mb-3">
                <Field
                  className="form-control"
                  name="paymentMode"
                  as="select"
                  onClick={(e) => {
                    setMOP(e.target.value);
                  }}
                >
                  <option value="">Click to Select</option>
                  {Object.keys(ACCOUNTING.MODE_OF_PAYMENT)?.map((val, i) => (
                    <option key={i} value={ACCOUNTING.MODE_OF_PAYMENT[val]}>
                      {val}
                    </option>
                  ))}
                </Field>
                {errors.paymentMode && touched.paymentMode ? (
                  <div className="error">{errors.paymentMode}</div>
                ) : null}
              </div>

              {MOP == 1 && (
                <>
                  <div className="col-md-6 mb-3"></div>
                  <div className="col-md-6 mb-3">
                    <Field className="form-control" name="bank" as="select">
                      <option value="">Click to Select</option>
                      {dropdown.banks?.map((val) => (
                        <option key={val._id} value={val._id}>
                          {`${val.firmName || NA} - ${val.bankName || NA}`}
                        </option>
                      ))}
                    </Field>
                    {errors.bank && touched.bank ? (
                      <div className="error">{errors.bank}</div>
                    ) : null}
                  </div>
                </>
              )}

              <div className="col-md-6 mb-3">
                <label>Amount</label>
              </div>
              <div className="col-md-6 mb-3">
                <Field className="form-control" name="amount" type="number" />
                {errors.amount && touched.amount ? (
                  <div className="error">{errors.amount}</div>
                ) : null}
              </div>

              {!isTypeReceipts && (
                <>
                  <div className="col-md-6 mb-3">
                    <label>Particulars</label>
                  </div>
                  <div className="col-md-6 mb-3">
                    <Field
                      className="form-control"
                      name="particular"
                      as="select"
                      onClick={getSubParticulars}
                    >
                      <option value="">Click to Select</option>
                      {dropdown.paticulars?.map((val) => (
                        <option key={val._id} value={val._id}>
                          {val.name}
                        </option>
                      ))}
                    </Field>
                    {errors.particular && touched.particular ? (
                      <div className="error">{errors.particular}</div>
                    ) : null}
                  </div>

                  <div className="col-md-6 mb-3">
                    <label>Sub Particular</label>
                  </div>
                  <div className="col-md-6 mb-3">
                    <Field
                      className="form-control"
                      name="subParticular"
                      as="select"
                    >
                      <option value="">Click to Select</option>
                      {dropdown.subParticulars?.map((val) => (
                        <option key={val._id} value={val._id}>
                          {val.name}
                        </option>
                      ))}
                    </Field>
                    {errors.subParticular && touched.subParticular ? (
                      <div className="error">{errors.subParticular}</div>
                    ) : null}
                  </div>
                </>
              )}

              <div className="col-md-6 mb-3">
                <label>Narration</label>
              </div>
              <div className="col-md-6 mb-3">
                <Field className="form-control" type="text" name="narration" />
                {errors.narration && touched.narration ? (
                  <div className="error">{errors.narration}</div>
                ) : null}
              </div>

              {!isTypeReceipts && (
                <>
                  <div className="col-md-6 mb-3">
                    <label>Approved By</label>
                  </div>
                  <div className="col-md-6 mb-3">
                    <Field
                      className="form-control"
                      type="text"
                      name="approvedBy"
                    />
                    {errors.approvedBy && touched.approvedBy ? (
                      <div className="error">{errors.approvedBy}</div>
                    ) : null}
                  </div>

                  <div className="col-md-6 mb-3">
                    <label>Voucher Number</label>
                  </div>
                  <div className="col-md-6 mb-3">
                    <Field
                      className="form-control"
                      name="voucherNumber"
                      type="text"
                    />
                    {errors.voucherNumber && touched.voucherNumber ? (
                      <div className="error">{errors.voucherNumber}</div>
                    ) : null}
                  </div>
                </>
              )}

              {/* <div className="col-md-6 mb-3">
                <label>Closing Balance</label>
              </div>
              <div className="col-md-6 mb-3">
                <Field
                  className="form-control"
                  type="number"
                  name="closingBalance"
                />
                {errors.closingBalance && touched.closingBalance ? (
                  <div className="error">{errors.closingBalance}</div>
                ) : null}
              </div> */}
              {isUpate || (
                <FileUpload
                  name="files"
                  fileRef={files}
                  handleFileChange={handleFileChange}
                />
              )}
            </div>

            <button
              className="btn bg-gradient-dark mx-auto d-block btn-lg mb-1"
              type="submit"
            >
              {isUpate ? "Update" : "Add"}
            </button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AddAccounting;
