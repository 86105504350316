import { EndPoints } from "../../Helpers/Constants/EndPoints";

const {
  DASHBOARD,
  NONE,
  ADD_BANK,
  BANK,
  ADD_PAYMENT,
  ADD_INTERNAL_TRANSFER,
  INTERNAL_TRANSFER,
  ADD_PAYMENT_GATEWAY,
  PAYMENT_GATEWAY,
  ADD_P2P_ACCOUNT,
  P2P_ACCOUNT,
  ADD_PLATFORM,
  PLATFORM,
  ADD_EXPENSE_TYPE,
  EXPENSE_TYPE,
  ADD_CUSTOM_EXPENSE,
  CUSTOM_EXPENSE,
  ADD_ACCOUNTING,
  ACCOUNTING,
  ADD_SUB_PARTICULAR,
  SUB_PARTICULAR,
  TRANSACTION,
  ADD_CASH_BALANCE,
  CASH_BALANCE,
  BANK_CURRENT_BALNACE,
  PG_CURRENT_BALNACE,
  ROLE,
  ADD_ROLE,
  USER,
  ADD_USER,
  VIEW_TRANSACTION,
  ADD_TRANSFER,
  TRANSFER,
  COMPANY,
  VIEW_COMPANY,
  ADD_COMPANY,
  PARTICULAR,
  ADD_PARTICULAR,
} = EndPoints;

export const HeaderContent = {
  [NONE]: "Dashboard",
  [DASHBOARD]: "Dashboard",
  [ADD_BANK]: "Add Bank",
  [BANK]: "Bank",
  [ADD_PAYMENT]: "Add New Payment",
  [ADD_INTERNAL_TRANSFER]: "Add Internal Transfer",
  [INTERNAL_TRANSFER]: "Internal Transfer",
  [ADD_PAYMENT_GATEWAY]: "Add Payment Gateway",
  [PAYMENT_GATEWAY]: "Payment Gateway",
  [ADD_P2P_ACCOUNT]: "Add P2P Account",
  [P2P_ACCOUNT]: "P2P Account",
  [ADD_PLATFORM]: "Add Platform",
  [PLATFORM]: "Platform",
  [ADD_EXPENSE_TYPE]: "Add Expense Type",
  [EXPENSE_TYPE]: "Expense Type",
  [ADD_CUSTOM_EXPENSE]: "Add Custom Expense",
  [CUSTOM_EXPENSE]: "Custom Expense",
  [ADD_ACCOUNTING]: "Add Accounting",
  [ACCOUNTING]: "Accounting",
  [ADD_SUB_PARTICULAR]: "Add Sub Particular",
  [SUB_PARTICULAR]: "Sub Particular",
  [TRANSACTION]: "Transaction",
  [ADD_CASH_BALANCE]: "Add Cash Balance",
  [CASH_BALANCE]: "Cash Balance",
  [BANK_CURRENT_BALNACE]: "Bank Current Balance",
  [PG_CURRENT_BALNACE]: "PG Current Balance",
  [ROLE]: "Role",
  [ADD_ROLE]: "Add Role",
  [USER]: "User",
  [ADD_USER]: "Add User",
  [VIEW_TRANSACTION]: "Transaction Data",
  [ADD_TRANSFER]: "Add Transfer",
  [TRANSFER]: "Transfer",
  [COMPANY]: "Company",
  [VIEW_COMPANY]: "Company Entries",
  [ADD_COMPANY]: "Add Company",
  [ADD_PARTICULAR]: "Add Particular",
  [PARTICULAR]: "Particular",
};
