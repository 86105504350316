const {
  REACT_APP_host_url,
  REACT_APP_api_key,
  REACT_APP_auth_domain,
  REACT_APP_project_id,
  REACT_APP_storage_bucket,
  REACT_APP_messaging_sender_id,
  REACT_APP_app_id,
  REACT_APP_measurement_id,
  REACT_APP_api_url,
} = process.env;

module.exports = {
  url: REACT_APP_host_url,
  API_URL: REACT_APP_api_url,
  firebaseConfig: {
    apiKey: REACT_APP_api_key,
    authDomain: REACT_APP_auth_domain,
    projectId: REACT_APP_project_id,
    storageBucket: REACT_APP_storage_bucket,
    messagingSenderId: REACT_APP_messaging_sender_id,
    appId: REACT_APP_app_id,
    measurementId: REACT_APP_measurement_id,
  },
};
