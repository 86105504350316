import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { useSelector } from "react-redux";

import { BankSchema } from "../../Helpers/Constants/Schemas";
import { BankFields } from "../../Helpers/Constants/InitialValues";
import { EndPoints } from "../../Helpers/Constants/EndPoints";
import CustomSelect from "../Common/CustomSelect";

import API from "../../Helpers/Constants/EndPoints/Api";
import Axios from "../../Helpers/Universal/Axios/Axios";
import { UpdateParam } from "../../Helpers/Universal/Function/common";
import { BANK } from "../../Helpers/Constants/Dropdowns";
import { USER_TYPE } from "../../Helpers/Constants/Default";

const AddBank = () => {
  const { profile } = useSelector((val) => val);
  const navigate = useNavigate();
  const { state } = useLocation();

  const [isAdmin] = useState(profile?.role === USER_TYPE[0] ? true : false);
  const [isUpate] = useState(state ? true : false);
  const [dropdown, setDropdown] = useState({
    platforms: [],
    types: Object.keys(BANK.TYPE),
  });
  const [initialValues] = useState(
    isUpate
      ? {
          ...state?.val,
          platform: state?.val?.platform?._id,
          secondaryPlatforms: state?.val?.secondaryPlatforms?.map(
            (val) => val?._id
          ),
        }
      : BankFields.required
  );

  const handleSubmit = async (data) => {
    try {
      // if (!isAdmin) data.type = BANK.TYPE.ACCOUNTING;
      const { status } = await Axios.post(API.BANK.CREATE, data);
      if (status) navigate(EndPoints.BANK);
    } catch (err) {
      console.log("ERROR==>", err);
    }
  };

  const handleUpdateDocument = async (data) => {
    try {
      const params = await UpdateParam(BankFields, data);

      const { status } = await Axios.patch(API.BANK.UPDATE, {
        bank_id: initialValues._id,
        ...params,
      });
      if (status) navigate(EndPoints.BANK);
    } catch (err) {
      console.log("ERROR==>", err);
    }
  };

  const getPlatforms = async () => {
    try {
      const { status, data } = await Axios.get(API.DROPDOWN.PLATFORM);
      if (status) setDropdown((prev) => ({ ...prev, platforms: data }));
    } catch (err) {
      console.log("ERROR==>", err);
    }
  };

  useEffect(() => {
    getPlatforms();
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        ...BankSchema.fields,
        ...(isAdmin
          ? {
              type: Yup.number()
                .oneOf(Object.values(BANK.TYPE))
                .required("Required"),
            }
          : {}),
      })}
      onSubmit={(values, { resetForm }) => {
        isUpate ? handleUpdateDocument(values) : handleSubmit(values);
        // resetForm();
      }}
    >
      {({ errors, touched }) => (
        <Form className="border rounded shadow mx-auto w-80 mt-7 m-4 p-4">
          <div className="row">
            <div className="col-md-6 mb-3">
              <label>Firm Name</label>
              <Field className="form-control" name="firmName" type="text" />
              {errors.firmName && touched.firmName ? (
                <div className="error">{errors.firmName}</div>
              ) : null}
            </div>

            <div className="col-md-6 mb-3">
              <label>Bank Name</label>
              <Field className="form-control" name="bankName" type="text" />
              {errors.bankName && touched.bankName ? (
                <div className="error">{errors.bankName}</div>
              ) : null}
            </div>

            <div className="col-md-6 mb-3">
              <label>Platform</label>
              <Field className="form-control" name="platform" as="select">
                <option value="">Choose a Platform</option>
                {dropdown.platforms?.map((val) => (
                  <option key={val._id} value={val._id}>
                    {val.name}
                  </option>
                ))}
              </Field>
              {errors.platform && touched.platform ? (
                <div className="error">{errors.platform}</div>
              ) : null}
            </div>

            <div className="col-md-6 mb-3">
              <label>Secondary Platforms</label>
              <Field
                name="secondaryPlatforms"
                options={dropdown.platforms.map((val) => ({
                  label: val.name,
                  value: val._id,
                }))}
                component={CustomSelect}
                placeholder="Choose Platforms"
                isMulti={true}
              />
              {errors.secondaryPlatforms && touched.secondaryPlatforms ? (
                <div className="error">{errors.secondaryPlatforms}</div>
              ) : null}
            </div>

            <div className="col-md-6 mb-3">
              <label>Balance Amount</label>
              <Field
                className="form-control"
                name="balanceAmount"
                type="text"
              />
              {errors.balanceAmount && touched.balanceAmount ? (
                <div className="error">{errors.balanceAmount}</div>
              ) : null}
            </div>

            <div className="col-md-6 mb-3">
              <label>Proprietor Name</label>
              <Field
                className="form-control"
                name="proprietorName"
                type="text"
              />
              {errors.proprietorName && touched.proprietorName ? (
                <div className="error">{errors.proprietorName}</div>
              ) : null}
            </div>

            <div className="col-md-6 mb-3">
              <label>Account Number</label>
              <Field
                className="form-control"
                name="accountNumber"
                type="text"
              />
              {errors.accountNumber && touched.accountNumber ? (
                <div className="error">{errors.accountNumber}</div>
              ) : null}
            </div>

            <div className="col-md-6 mb-3">
              <label>IFSC Code</label>
              <Field className="form-control" name="ifscCode" type="text" />
              {errors.ifscCode && touched.ifscCode ? (
                <div className="error">{errors.ifscCode}</div>
              ) : null}
            </div>

            <div className="col-md-6 mb-3">
              <label>Account Type</label>
              <Field className="form-control" name="accountType" type="text" />
              {errors.accountType && touched.accountType ? (
                <div className="error">{errors.accountType}</div>
              ) : null}
            </div>

            <div className="col-md-6 mb-3">
              <label>Branch Name</label>
              <Field className="form-control" name="branchName" type="text" />
              {errors.branchName && touched.branchName ? (
                <div className="error">{errors.branchName}</div>
              ) : null}
            </div>

            <div className="col-md-6 mb-3">
              <label>Mobile Number</label>
              <Field className="form-control" name="mobileNumber" type="text" />
              {errors.mobileNumber && touched.mobileNumber ? (
                <div className="error">{errors.mobileNumber}</div>
              ) : null}
            </div>

            <div className="col-md-6 mb-3">
              <label>Email ID</label>
              <Field className="form-control" name="emailId" type="email" />
              {errors.emailId && touched.emailId ? (
                <div className="error">{errors.emailId}</div>
              ) : null}
            </div>

            <div className="col-md-6 mb-3">
              <label>Password</label>
              <Field className="form-control" name="password" type="password" />
              {errors.password && touched.password ? (
                <div className="error">{errors.password}</div>
              ) : null}
            </div>

            <div className="col-md-6 mb-3">
              <label>Customer ID</label>
              <Field className="form-control" name="customerId" type="text" />
              {errors.customerId && touched.customerId ? (
                <div className="error">{errors.customerId}</div>
              ) : null}
            </div>
            <div className="col-md-6 mb-3">
              <label>Internet Banking User</label>
              <Field
                className="form-control"
                name="internetBankingUser"
                type="text"
              />
              {errors.internetBankingUser && touched.internetBankingUser ? (
                <div className="error">{errors.internetBankingUser}</div>
              ) : null}
            </div>

            <div className="col-md-6 mb-3">
              <label>Internet Banking PIN</label>
              <Field
                className="form-control"
                name="internetBankingPin"
                type="text"
              />
              {errors.internetBankingPin && touched.internetBankingPin ? (
                <div className="error">{errors.internetBankingPin}</div>
              ) : null}
            </div>

            <div className="col-md-6 mb-3">
              <label>Aadhar Card Number</label>
              <Field
                className="form-control"
                name="aadharCardNumber"
                type="text"
              />
              {errors.aadharCardNumber && touched.aadharCardNumber ? (
                <div className="error">{errors.aadharCardNumber}</div>
              ) : null}
            </div>

            <div className="col-md-6 mb-3">
              <label>Date of Birth</label>
              <Field className="form-control" name="DOB" type="date" />
              {errors.DOB && touched.DOB ? (
                <div className="error">{errors.DOB}</div>
              ) : null}
            </div>

            <div className="col-md-6 mb-3">
              <label>ATM Pin</label>
              <Field className="form-control" name="atmPin" type="text" />
              {errors.atmPin && touched.atmPin ? (
                <div className="error">{errors.atmPin}</div>
              ) : null}
            </div>

            <div className="col-md-6 mb-3">
              <label>Debit Card Number</label>
              <Field
                className="form-control"
                name="debitCardNumber"
                type="text"
              />
              {errors.debitCardNumber && touched.debitCardNumber ? (
                <div className="error">{errors.debitCardNumber}</div>
              ) : null}
            </div>

            <div className="col-md-6 mb-3">
              <label>Debit Card Expiry</label>
              <Field
                className="form-control"
                name="debitCardExpiry"
                type="month"
              />
              {errors.debitCardExpiry && touched.debitCardExpiry ? (
                <div className="error">{errors.debitCardExpiry}</div>
              ) : null}
            </div>

            <div className="col-md-6 mb-3">
              <label>Debit Card CVV</label>
              <Field className="form-control" name="debitCardCvv" type="text" />
              {errors.debitCardCvv && touched.debitCardCvv ? (
                <div className="error">{errors.debitCardCvv}</div>
              ) : null}
            </div>

            <div className="col-md-6 mb-3">
              <label>Reference Name</label>
              <Field
                className="form-control"
                name="referenceName"
                type="text"
              />
              {errors.referenceName && touched.referenceName ? (
                <div className="error">{errors.referenceName}</div>
              ) : null}
            </div>

            <div className="col-md-6 mb-3">
              <label>Date of Issue</label>
              <Field className="form-control" name="dateOfIssue" type="date" />
              {errors.dateOfIssue && touched.dateOfIssue ? (
                <div className="error">{errors.dateOfIssue}</div>
              ) : null}
            </div>

            <div className="col-md-6 mb-3">
              <label>Mobile Phone</label>
              <Field className="form-control" name="mobilePhone" type="text" />
              {errors.mobilePhone && touched.mobilePhone ? (
                <div className="error">{errors.mobilePhone}</div>
              ) : null}
            </div>

            {/* {isAdmin && ( */}
            <div className="col-md-6 mb-3">
              <label>Bank Type</label>
              <Field className="form-control" name="type" as="select">
                <option value="">Choose a Type</option>
                {dropdown.types?.map((val) => (
                  <option key={val} value={BANK.TYPE[val]}>
                    {val}
                  </option>
                ))}
              </Field>
              {errors.type && touched.type ? (
                <div className="error">{errors.type}</div>
              ) : null}
            </div>
            {/* )} */}
          </div>

          <button
            className="btn bg-gradient-dark mx-auto d-block btn-lg mb-1"
            type="submit"
          >
            {isUpate ? "Update" : "Add"}
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default AddBank;
