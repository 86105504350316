import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { EndPoints } from "../../Helpers/Constants/EndPoints";
import { CreatedAt, ReverseObj } from "../../Helpers/Universal/Function/common";
import { TRANSACTION } from "../../Helpers/Constants/Dropdowns";
import { NA, NO_FILES } from "../../Helpers/Constants/Default";
import ViewFile from "../Common/ViewFile";

const ViewTransaction = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const [data] = useState(state?.val ? state.val : false);

  useEffect(() => {
    data || navigate(EndPoints.TRANSACTION);
  }, [data]);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="border rounded p-2 shadow-sm m-3">
          <div className="d-flex justify-content-around p-2 border-bottom pb-2 rounded mb-2">
            <div className="col-md-5">
              <h6 className="p-2 mb-0 text-uppercase">Platform</h6>
            </div>
            <div className="col-md-7">
              <div className="d-flex">
                <p className="p-2 mb-0 w-50 text-dark">
                  {data?.platform?.name || NA}
                </p>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-around p-2 border-bottom pb-2 rounded mb-2">
            <div className="col-md-5">
              <h6 className="p-2 mb-0 text-uppercase">Automatic Deposit</h6>
            </div>
            <div className="col-md-7">
              {data?.automaticDeposit?.length ? (
                data?.automaticDeposit?.map((val) => (
                  <div key={val._id} className="d-flex">
                    <p className="p-2 mb-0 w-50 text-dark">
                      {val?.docId?.name || NA}
                    </p>
                    <p className="p-2 mb-0 w-50 text-dark">
                      {val?.docId_2?.name || NA}
                    </p>
                    <p className="p-2 mb-0 w-50 text-dark">
                      {val?.amount || NA}
                    </p>
                  </div>
                ))
              ) : (
                <p className="p-2 mb-0 w-50 text-dark">{NA}</p>
              )}
            </div>
          </div>

          <div className="d-flex justify-content-around p-2 border-bottom pb-2 rounded mb-2">
            <div className="col-md-5">
              <h6 className="p-2 mb-0 text-uppercase">Manual Deposit</h6>
            </div>
            <div className="col-md-7">
              {data?.manualDeposit?.length ? (
                data?.manualDeposit?.map((val) => (
                  <div key={val._id} className="d-flex">
                    <p className="p-2 mb-0 w-50 text-dark">
                      {ReverseObj(TRANSACTION.TYPE)[val?.type] || NA}
                    </p>
                    <p className="p-2 mb-0 w-50 text-dark">
                      {val?.amount || NA}
                    </p>
                  </div>
                ))
              ) : (
                <p className="p-2 mb-0 w-50 text-dark">{NA}</p>
              )}
            </div>
          </div>

          <div className="d-flex justify-content-around p-2 border-bottom pb-2 rounded mb-2">
            <div className="col-md-5">
              <h6 className="p-2 mb-0 text-uppercase">Deposit Failed</h6>
            </div>
            <div className="col-md-7">
              {data?.depositFailed?.length ? (
                data?.depositFailed?.map((val) => (
                  <div key={val._id} className="d-flex">
                    <p className="p-2 mb-0 w-50 text-dark">
                      {val?.docId?.name || NA}
                    </p>
                    <p className="p-2 mb-0 w-50 text-dark">
                      {val?.amount || NA}
                    </p>
                  </div>
                ))
              ) : (
                <p className="p-2 mb-0 w-50 text-dark">{NA}</p>
              )}
            </div>
          </div>

          <div className="d-flex justify-content-around p-2 border-bottom pb-2 rounded mb-2">
            <div className="col-md-5">
              <h6 className="p-2 mb-0 text-uppercase">Auto Withdrawal</h6>
            </div>
            <div className="col-md-7">
              {data?.autoWithdrawal?.length ? (
                data?.autoWithdrawal?.map((val) => (
                  <div key={val._id} className="d-flex">
                    <p className="p-2 mb-0 w-50 text-dark">
                      {val?.docId?.name || NA}
                    </p>
                    <p className="p-2 mb-0 w-50 text-dark">
                      {val?.amount || NA}
                    </p>
                  </div>
                ))
              ) : (
                <p className="p-2 mb-0 w-50 text-dark">{NA}</p>
              )}
            </div>
          </div>

          <div className="d-flex justify-content-around p-2 border-bottom pb-2 rounded mb-2">
            <div className="col-md-5">
              <h6 className="p-2 mb-0 text-uppercase">Manual Withdrawal</h6>
            </div>
            <div className="col-md-7">
              {data?.manualWithdrawal?.length ? (
                data?.manualWithdrawal?.map((val) => (
                  <div key={val._id} className="d-flex">
                    <p className="p-2 mb-0 w-50 text-dark">
                      {val?.docId?.name ||
                        `${val?.docId?.firmName} - ${val?.docId?.bankName}` ||
                        NA}
                    </p>
                    <p className="p-2 mb-0 w-50 text-dark">{val?.amount}</p>
                  </div>
                ))
              ) : (
                <p className="p-2 mb-0 w-50 text-dark">{NA}</p>
              )}
            </div>
          </div>

          <div className="d-flex justify-content-around p-2 border-bottom pb-2 rounded mb-2">
            <div className="col-md-5">
              <h6 className="p-2 mb-0 text-uppercase">Withdrawal Processing</h6>
            </div>
            <div className="col-md-7">
              {data?.withdrawalProcessing?.length ? (
                data?.withdrawalProcessing?.map((val) => (
                  <div key={val._id} className="d-flex">
                    <p className="p-2 mb-0 w-50 text-dark">
                      {val?.docId?.name || NA}
                    </p>
                    <p className="p-2 mb-0 w-50 text-dark">
                      {val?.amount || NA}
                    </p>
                  </div>
                ))
              ) : (
                <p className="p-2 mb-0 w-50 text-dark">{NA}</p>
              )}
            </div>
          </div>

          <div className="d-flex justify-content-around p-2 border-bottom pb-2 rounded mb-2">
            <div className="col-md-5">
              <h6 className="p-2 mb-0 text-uppercase">Withdrawal Failed</h6>
            </div>
            <div className="col-md-7">
              {data?.withdrawalFailed?.length ? (
                data?.withdrawalFailed?.map((val) => (
                  <div key={val._id} className="d-flex">
                    <p className="p-2 mb-0 w-50 text-dark">
                      {val?.docId?.name || NA}
                    </p>
                    <p className="p-2 mb-0 w-50 text-dark">
                      {val?.amount || NA}
                    </p>
                  </div>
                ))
              ) : (
                <p className="p-2 mb-0 w-50 text-dark">{NA}</p>
              )}
            </div>
          </div>

          <div className="d-flex justify-content-around p-2 border-bottom pb-2 rounded mb-2">
            <div className="col-md-5">
              <h6 className="p-2 mb-0 text-uppercase">Wrong Deposit</h6>
            </div>
            <div className="col-md-7">
              <div className="d-flex">
                <p className="p-2 mb-0 w-50 text-dark">
                  {data?.wrongDeposit || NA}
                </p>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-around p-2 border-bottom pb-2 rounded mb-2">
            <div className="col-md-5">
              <h6 className="p-2 mb-0 text-uppercase">Wrong Withdrawal</h6>
            </div>
            <div className="col-md-7">
              <div className="d-flex">
                <p className="p-2 mb-0 w-50 text-dark">
                  {data?.wrongWithdrawal || NA}
                </p>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-around p-2 border-bottom pb-2 rounded mb-2">
            <div className="col-md-5">
              <h6 className="p-2 mb-0 text-uppercase">Incorrect Settlement</h6>
            </div>
            <div className="col-md-7">
              <div className="d-flex">
                <p className="p-2 mb-0 w-50 text-dark">
                  {data?.incorrectSettlement || NA}
                </p>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-around p-2 border-bottom pb-2 rounded mb-2">
            <div className="col-md-5">
              <h6 className="p-2 mb-0 text-uppercase">Payout Received</h6>
            </div>
            <div className="col-md-7">
              <div className="d-flex">
                <p className="p-2 mb-0 w-50 text-dark">
                  {data?.payoutReceived?.amount ||
                  data?.payoutReceived?.text ? (
                    <>
                      {data?.payoutReceived?.amount || NA} <br></br>
                      {data?.payoutReceived?.text || NA}
                    </>
                  ) : (
                    NA
                  )}
                </p>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-around p-2 border-bottom pb-2 rounded mb-2">
            <div className="col-md-5">
              <h6 className="p-2 mb-0 text-uppercase">Bank</h6>
            </div>
            <div className="col-md-7">
              {data?.bank?.length ? (
                data?.bank?.map((val) => (
                  <div key={val._id} className="d-flex">
                    <p className="p-2 mb-0 w-50 text-dark">
                      {`${val?.docId?.firmName} - ${val?.docId?.bankName}` ||
                        NA}
                    </p>
                    <p className="p-2 mb-0 w-50 text-dark">
                      {val?.amount || NA}
                    </p>
                  </div>
                ))
              ) : (
                <p className="p-2 mb-0 w-50 text-dark">{NA}</p>
              )}
            </div>
          </div>

          <div className="d-flex justify-content-around p-2 border-bottom pb-2 rounded mb-2">
            <div className="col-md-5">
              <h6 className="p-2 mb-0 text-uppercase">GST</h6>
            </div>
            <div className="col-md-7">
              <div className="d-flex">
                <p className="p-2 mb-0 w-50 text-dark">{data?.GST || NA}</p>
              </div>
            </div>
          </div>

          <div className="w-100 mt-3 border-bottom pb-2">
            <div className="col-md-6">
              <h6 className="p-2 text-uppercase">Custom</h6>
            </div>
            {data?.custom?.length ? (
              data?.custom?.map((val) => (
                <div
                  key={val._id}
                  className="d-flex justify-content-around p-2 rounded mb-2"
                >
                  <div className="col-md-3"></div>
                  <div className="col-md-2">
                    <p className="p-2 mb-0">
                      {ReverseObj(TRANSACTION.CUSTOM_TYPE)[val?.type] || NA}
                    </p>
                  </div>
                  <div className="col-md-4">
                    <p className="p-2 mb-0">{val?.name || NA}</p>
                  </div>
                  <div className="col-md-3">
                    <p className="p-2 mb-0">{val?.amount || NA}</p>
                  </div>
                </div>
              ))
            ) : (
              <div className="d-flex">
                <div className="col-md-5"></div>
                <p className="p-2 mb-0 w-50 text-dark">{NA}</p>
              </div>
            )}
          </div>

          <div className="d-flex justify-content-around p-2 border-bottom pb-2 rounded mb-2">
            <div className="col-md-5">
              <h6 className="p-2 mb-0 text-uppercase">Remarks</h6>
            </div>
            <div className="col-md-7">
              <div className="d-flex">
                <p className="p-2 mb-0 w-50 text-dark">{data?.remarks || NA}</p>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-around p-2 border-bottom pb-2 rounded mb-2">
            <div className="col-md-5">
              <h6 className="p-2 mb-0 text-uppercase">Files</h6>
            </div>
            <div className="col-md-7">
              <div className="d-flex">
                <p className="p-2 mb-0 w-50 text-dark">
                  {data.files?.length === 0 ? (
                    NO_FILES
                  ) : (
                    <ViewFile files={data.files} />
                  )}
                </p>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-around p-2 border-bottom pb-2 rounded mb-2">
            <div className="col-md-5">
              <h6 className="p-2 mb-0 text-uppercase">Added By</h6>
            </div>
            <div className="col-md-7">
              <div className="d-flex">
                <p className="p-2 mb-0 w-50 text-dark">
                  {data?.createdBy?.name || NA}
                </p>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-around p-2 rounded mb-2">
            <div className="col-md-5">
              <h6 className="p-2 mb-0 text-uppercase">Created-At</h6>
            </div>
            <div className="col-md-7">
              <div className="d-flex">
                <p className="p-2 mb-0 w-50 text-dark">
                  {CreatedAt(data?.createdAt) || NA}
                </p>
              </div>
            </div>
          </div>
          <button
            className="btn bg-gradient-dark mx-auto d-block btn-lg mb-1 mt-3"
            onClick={() => navigate(EndPoints.TRANSACTION)}
          >
            Done
          </button>
        </div>
      </div>
    </div>
  );
};

export default ViewTransaction;
