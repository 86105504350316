import API from "../../Constants/EndPoints/Api";
import Axios from "./Axios";

export const getProfile = async () => {
  try {
    const { status, data } = await Axios.get(API.PROFILE);
    if (status) return data;
  } catch (err) {
    console.log("ERROR==>", err);
  }
};
