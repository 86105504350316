import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Success from "../Common/Success";
import FireStore from "../../Helpers/Firebase/service";
import { DB } from "../../Helpers/Firebase/db";
import DatePicker from "../Common/DatePicker";
import { EndPoints } from "../../Helpers/Constants/EndPoints";
import { CreatedAt } from "../../Helpers/Universal/Function/common";

const CashBalance = ({ permissions: { all, add, edit, delete: del } }) => {
  const [data, setData] = useState([]);
  const [success, setSuccess] = useState(false);
  const [filter, setFilter] = useState({ dates: false });

  const getData = async (dates) => {
    try {
      const { status, data } = await FireStore.get(
        DB.CASH_BALANCE,
        ["timeStamp", "desc"],
        false,
        dates
      );
      if (status) setData(data);
    } catch (err) {
      console.log("ERROR==>", err);
    }
  };

  const delData = async (documentId) => {
    try {
      setSuccess(true);
      await FireStore.delete(DB.CASH_BALANCE, documentId);
      setData((prev) => prev.filter((val) => val.id !== documentId));
    } catch (err) {
      console.log("ERROR==>", err);
    } finally {
      setSuccess(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getData(filter.dates ? [filter.dates[0], filter.dates[1]] : false);
  }, [filter]);

  return (
    <>
      {success && <Success heading="Deleted" />}
      <div className="container-fluid py-3">
        <div className="dateselect_navigation d-flex justify-content-between align-items-center">
          <DatePicker filter={filter} setFilter={setFilter} />
          {(all || add) && (
            <Link to={EndPoints.ADD_CASH_BALANCE}>
              <button className="btn bg-gradient-dark float-end d-flex mt-3 mb-3 btn-md mb-1">
                <i className="fas fa-plus me-2 mt-1" aria-hidden="true"></i>
                Add
              </button>
            </Link>
          )}
        </div>

        <div className="overflow-x-scroll w-100">
          <table className="table-striped table align-middle mt-3 gate_way">
            <thead className="bg-light text-dark">
              <tr>
                <th scope="col" className="text-uppercase">
                  #
                </th>
                <th scope="col" className="text-uppercase">
                  Date
                </th>
                <th scope="col" className="text-uppercase">
                  From Date
                </th>
                <th scope="col" className="text-uppercase">
                  To Date
                </th>
                <th scope="col" className="text-uppercase">
                  Company
                </th>
                <th scope="col" className="text-uppercase">
                  Mode of Payment
                </th>
                {(all || edit || del) && (
                  <th scope="col" className="text-uppercase">
                    Action
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {data?.map((val, i) => (
                <tr key={val.id}>
                  <td>{++i + filter.offset}</td>
                  <td>{CreatedAt(val.createdAt)}</td>
                  <td>{val.fromDate}</td>
                  <td>{val.toDate}</td>
                  <td>{val.company}</td>
                  <td>{val.paymentMode}</td>
                  {(all || edit || del) && (
                    <td>
                      {(all || edit) && (
                        <Link to={EndPoints.ADD_CASH_BALANCE} state={{ val }}>
                          <i className="fas fa-edit me-2 pe-auto"></i>
                        </Link>
                      )}
                      {(all || del) && (
                        <i
                          className="material-icons text-danger pe-auto"
                          onClick={() => delData(val.id)}
                        >
                          delete
                        </i>
                      )}
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default CashBalance;
