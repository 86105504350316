export const CUSTOM_EXPENSE = {
  APPROVED_BY: { Admin: 1, Agent: 2 },
};

export const STATUS = { Active: 1, Inactive: 0 };

export const ACCOUNTING = {
  TYPE: {
    Payments: 2,
    Receipts: 1,
  },
  MODE_OF_PAYMENT: {
    Cash: 0,
    Bank: 1,
  },
  APPROVED_BY: {
    Anil: 3,
    Pruhtvi: 2,
    Suraj: 1,
  },
};

export const TRANSACTION = {
  TYPE: {
    Approved: 1,
    "Deposited by me": 2,
  },
  CUSTOM_TYPE: {
    Debit: 1,
    Credit: 2,
  },
};

export const TRANSFER = {
  MODE_OF_PAYMENT: {
    Cash: 1,
  },
};

export const ENTRY = {
  TYPE: {
    COMPANY: 1,
  },
  MODEL: ["Company"],
};

export const BANK = {
  TYPE: {
    ADMIN: 1,
    ACCOUNTING: 2,
  },
};

export const INTERNAL_TRANSFER = {
  REF_TYPE: ["Bank", "PaymentGateway"],
};
