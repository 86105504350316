export const NA = "NA";

export const NO_FILES = "no files";

export const LIMIT = 10;

export const OFFSET = 0;

export const USER_TYPE = ["Admin", "User"];

export const LIMITATION = [10, 20, 30, 40, 50];

export const NOT_FOUND = "404 Not Found";

export const UPLOAD = {
  Transaction: "transaction",
  P2pAccount: "p2pAccount",
  Accounting: "accounting",
  CustomExpense: "customExpense",
};
