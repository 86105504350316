import React from "react";
import successImg from "../../assests/succss.webp";

const Success = ({ heading }) => {
  return (
    <div className="bg_viewpopup" style={{ zIndex: "999999" }}>
      <div className="successfull bg-white p-4 h-75 w-50 top-50 start-50 end-0 border border-secondary rounded text-center position-absolute">
        <span>
          <img className="w-15" src={successImg} alt="file"></img>
          <h2 className="text-center text-dark mt-5">Successfully {heading}</h2>
        </span>
      </div>
    </div>
  );
};

export default Success;
