export const EndPoints = {
  LOGIN: "/login",
  DASHBOARD: "/dashboard",
  NONE: "/",
  ADD_BANK: "/add-bank",
  BANK: "/bank",
  ADD_PAYMENT: "/add-transaction",
  ADD_INTERNAL_TRANSFER: "/add-internal-transfer",
  INTERNAL_TRANSFER: "/internal-transfer",
  ADD_PAYMENT_GATEWAY: "/add-payment-gateway",
  PAYMENT_GATEWAY: "/payment-gateway",
  ADD_P2P_ACCOUNT: "/add-p2p-account",
  P2P_ACCOUNT: "/p2p-account",
  ADD_PLATFORM: "/add-platform",
  PLATFORM: "/platform",
  ADD_EXPENSE_TYPE: "/add-expense-type",
  EXPENSE_TYPE: "/expense-type",
  ADD_CUSTOM_EXPENSE: "/add-custom-expense",
  CUSTOM_EXPENSE: "/custom-expense",
  ADD_ACCOUNTING: "/add-accounting",
  ACCOUNTING: "/accounting",
  ADD_SUB_PARTICULAR: "/add-sub-particular",
  SUB_PARTICULAR: "/sub-particular",
  TRANSACTION: "/transaction",
  ADD_CASH_BALANCE: "/add-cash-balance",
  CASH_BALANCE: "/cash-balance",
  BANK_CURRENT_BALNACE: "/bank-current-balance",
  PG_CURRENT_BALNACE: "/pg-current-balance",
  ROLE: "/role",
  ADD_ROLE: "/add-role",
  USER: "/user",
  ADD_USER: "/add-user",
  UNKNOWN: "*",
  VIEW_TRANSACTION: "/view-transaction",
  ADD_TRANSFER: "/add-transfer",
  TRANSFER: "/transfer",
  COMPANY: "/company",
  VIEW_COMPANY: "/view-company",
  ADD_COMPANY: "/add-company",
  ADD_PARTICULAR: "/add-particular",
  PARTICULAR: "/particular",
};
