import { EndPoints } from "../../Helpers/Constants/EndPoints";
import Error from "../../Components/Auth/Error";
import Login from "../../Components/Auth/Login";
import Dashboard from "../../Components/Dashboard/Dashboard";
import AddBank from "../../Components/Forms/Bank";
import AddCustomExpense from "../../Components/Forms/CustomExpense";
import AddExpenseType from "../../Components/Forms/ExpenseType";
import AddInternalTransfer from "../../Components/Forms/InternalTransfer";
import AddP2p from "../../Components/Forms/P2p";
import Payment from "../../Components/Forms/Payment";
import AddPaymentGateway from "../../Components/Forms/PaymentGateway";
import AddPlatform from "../../Components/Forms/Platform";
import Bank from "../../Components/Listing/Bank";
import CustomExpense from "../../Components/Listing/CustomExpense";
import ExpenseType from "../../Components/Listing/ExpenseType";
import InternalTransfer from "../../Components/Listing/InternalTransfer";
import P2p from "../../Components/Listing/P2p";
import PaymentGateway from "../../Components/Listing/PaymentGateway";
import Platform from "../../Components/Listing/Platform";
import AddAccounting from "../../Components/Forms/Accounting";
import Accounting from "../../Components/Listing/Accounting";
import AddSubParticular from "../../Components/Forms/SubParticular";
import SubParticular from "../../Components/Listing/SubParticular";
import Transaction from "../../Components/Listing/Transaction";
import AddCashBalance from "../../Components/Forms/CashBalance";
import CashBalance from "../../Components/Listing/CashBalance";
import BankCurrentBalance from "../../Components/Listing/BankCurrentBalance";
import PGCurrentBalance from "../../Components/Listing/PGCurrentBalance";
import User from "../../Components/Listing/User";
import AddUser from "../../Components/Forms/User";
import Role from "../../Components/Listing/Role";
import AddRole from "../../Components/Forms/Role";
import ViewTransaction from "../../Components/view/Transaction";
import AddTransfer from "../../Components/Forms/Transfer";
import Transfer from "../../Components/Listing/Transfer";
import Company from "../../Components/Listing/Company";
import ViewCompany from "../../Components/view/Company";
import AddCompany from "../../Components/Forms/Company";
import AddParticular from "../../Components/Forms/Particular";
import Particular from "../../Components/Listing/Particular";

const {
  LOGIN,
  DASHBOARD,
  NONE,
  ADD_BANK,
  BANK,
  ADD_PAYMENT,
  ADD_INTERNAL_TRANSFER,
  INTERNAL_TRANSFER,
  ADD_PAYMENT_GATEWAY,
  PAYMENT_GATEWAY,
  ADD_P2P_ACCOUNT,
  P2P_ACCOUNT,
  ADD_PLATFORM,
  PLATFORM,
  ADD_EXPENSE_TYPE,
  EXPENSE_TYPE,
  ADD_CUSTOM_EXPENSE,
  CUSTOM_EXPENSE,
  ADD_ACCOUNTING,
  ACCOUNTING,
  ADD_SUB_PARTICULAR,
  SUB_PARTICULAR,
  TRANSACTION,
  ADD_CASH_BALANCE,
  CASH_BALANCE,
  BANK_CURRENT_BALNACE,
  PG_CURRENT_BALNACE,
  ROLE,
  ADD_ROLE,
  USER,
  ADD_USER,
  VIEW_TRANSACTION,
  ADD_TRANSFER,
  TRANSFER,
  COMPANY,
  VIEW_COMPANY,
  ADD_COMPANY,
  ADD_PARTICULAR,
  PARTICULAR,
  UNKNOWN,
} = EndPoints;

export const routes = () => [
  { path: LOGIN, element: Login },
  {
    path: DASHBOARD,
    element: Dashboard,
    pvtRoute: true,
  },
  {
    path: NONE,
    element: Dashboard,
    pvtRoute: true,
  },
  {
    path: ADD_BANK,
    element: AddBank,
    pvtRoute: true,
  },
  {
    path: BANK,
    element: Bank,
    pvtRoute: true,
  },
  {
    path: ADD_PAYMENT,
    element: Payment,
    pvtRoute: true,
  },
  {
    path: ADD_INTERNAL_TRANSFER,
    element: AddInternalTransfer,
    pvtRoute: true,
  },
  {
    path: INTERNAL_TRANSFER,
    element: InternalTransfer,
    pvtRoute: true,
  },
  {
    path: ADD_PAYMENT_GATEWAY,
    element: AddPaymentGateway,
    pvtRoute: true,
  },
  {
    path: PAYMENT_GATEWAY,
    element: PaymentGateway,
    pvtRoute: true,
  },
  {
    path: ADD_P2P_ACCOUNT,
    element: AddP2p,
    pvtRoute: true,
  },
  {
    path: P2P_ACCOUNT,
    element: P2p,
    pvtRoute: true,
  },
  {
    path: ADD_PLATFORM,
    element: AddPlatform,
    pvtRoute: true,
  },
  {
    path: PLATFORM,
    element: Platform,
    pvtRoute: true,
  },
  {
    path: ADD_EXPENSE_TYPE,
    element: AddExpenseType,
    pvtRoute: true,
  },
  {
    path: EXPENSE_TYPE,
    element: ExpenseType,
    pvtRoute: true,
  },
  {
    path: ADD_CUSTOM_EXPENSE,
    element: AddCustomExpense,
    pvtRoute: true,
  },
  {
    path: CUSTOM_EXPENSE,
    element: CustomExpense,
    pvtRoute: true,
  },
  {
    path: ADD_ACCOUNTING,
    element: AddAccounting,
    pvtRoute: true,
  },
  {
    path: ACCOUNTING,
    element: Accounting,
    pvtRoute: true,
  },
  {
    path: ADD_SUB_PARTICULAR,
    element: AddSubParticular,
    pvtRoute: true,
  },
  {
    path: SUB_PARTICULAR,
    element: SubParticular,
    pvtRoute: true,
  },
  {
    path: TRANSACTION,
    element: Transaction,
    pvtRoute: true,
  },
  {
    path: ADD_CASH_BALANCE,
    element: AddCashBalance,
    pvtRoute: true,
  },
  {
    path: CASH_BALANCE,
    element: CashBalance,
    pvtRoute: true,
  },
  {
    path: BANK_CURRENT_BALNACE,
    element: BankCurrentBalance,
    pvtRoute: true,
  },
  {
    path: PG_CURRENT_BALNACE,
    element: PGCurrentBalance,
    pvtRoute: true,
  },
  {
    path: ROLE,
    element: Role,
    pvtRoute: true,
  },
  {
    path: ADD_ROLE,
    element: AddRole,
    pvtRoute: true,
  },
  {
    path: USER,
    element: User,
    pvtRoute: true,
  },
  {
    path: ADD_USER,
    element: AddUser,
    pvtRoute: true,
  },
  {
    path: VIEW_TRANSACTION,
    element: ViewTransaction,
    pvtRoute: true,
  },
  {
    path: ADD_TRANSFER,
    element: AddTransfer,
    pvtRoute: true,
  },
  {
    path: TRANSFER,
    element: Transfer,
    pvtRoute: true,
  },
  {
    path: COMPANY,
    element: Company,
    pvtRoute: true,
  },
  {
    path: VIEW_COMPANY,
    element: ViewCompany,
    pvtRoute: true,
  },
  {
    path: ADD_COMPANY,
    element: AddCompany,
    pvtRoute: true,
  },
  {
    path: ADD_PARTICULAR,
    element: AddParticular,
    pvtRoute: true,
  },
  {
    path: PARTICULAR,
    element: Particular,
    pvtRoute: true,
  },
  {
    path: UNKNOWN,
    element: Error,
    pvtRoute: true,
  },
];
