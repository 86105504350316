import React, { useState } from "react";

const ViewFile = ({ files }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <div
        style={{ cursor: "pointer", color: "blue" }}
        onClick={() => setOpen((prev) => !prev)}
      >
        {files?.length === 1 ? "View" : `View(${files?.length} attachments)`}
      </div>
      {open && (
        <div className="bg_viewpopup">
          <div
            className="viewFiles bg-white p-4 h-75 w-50 top-50 start-50 end-0 border border-secondary rounded text-center position-absolute"
            onClick={() => setOpen((prev) => !prev)}
          >
            <span className="cross_icon bg-secondary end-1 position-absolute rounded-circle text-white">
              <label>&times;</label>
            </span>

            <div className="files">
              {files?.map((val, i) => (
                <div key={i}>
                  <img
                    className="w-100 mb-2"
                    src={val}
                    alt={`file-${++i}`}
                  ></img>
                  <br></br>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ViewFile;
