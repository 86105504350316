import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import { useLocation, useNavigate } from "react-router-dom";

import { CashBalanceSchema } from "../../Helpers/Constants/Schemas";
import { CashBalanceFields } from "../../Helpers/Constants/InitialValues";
import Success from "../Common/Success";
import FireStore from "../../Helpers/Firebase/service";
import { DB } from "../../Helpers/Firebase/db";
import { EndPoints } from "../../Helpers/Constants/EndPoints";
// import { Company, PaymentType } from "../../Helpers/Constants/Dropdowns";

const AddCashBalance = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [isUpate] = useState(state ? true : false);
  const [initialValues] = useState(isUpate ? state.val : CashBalanceFields);

  const [success, setSuccess] = useState(false);

  const handleSubmit = async (data) => {
    try {
      const { status } = await FireStore.post(DB.CASH_BALANCE, data);
      if (status) navigate(EndPoints.CASH_BALANCE);
    } catch (err) {
      console.log("ERROR==>", err);
    } finally {
      setSuccess(false);
    }
  };

  const handleUpdateDocument = async (data) => {
    try {
      const { status } = await FireStore.patch(
        DB.CASH_BALANCE,
        initialValues.id,
        data
      );
      if (status) navigate(EndPoints.CASH_BALANCE);
    } catch (err) {
      console.log("ERROR==>", err);
    } finally {
      setSuccess(false);
    }
  };

  return (
    <>
      {success && <Success heading={isUpate ? "Updated" : "Added"} />}
      <Formik
        initialValues={initialValues}
        validationSchema={CashBalanceSchema}
        onSubmit={(values, { resetForm }) => {
          setSuccess(true);
          isUpate ? handleUpdateDocument(values) : handleSubmit(values);
          resetForm();
        }}
      >
        {({ errors, touched }) => (
          <Form className="border rounded shadow mx-auto w-50 mt-7 m-4 p-4">
            <div className="row">
              <div className="col-md-6 mb-3">
                <label>From</label>
              </div>
              <div className="col-md-6 mb-3">
                <Field className="form-control" name="fromDate" type="date" />
                {errors.fromDate && touched.fromDate ? (
                  <div className="error">{errors.fromDate}</div>
                ) : null}
              </div>

              <div className="col-md-6 mb-3">
                <label>To</label>
              </div>
              <div className="col-md-6 mb-3">
                <Field className="form-control" name="toDate" type="date" />
                {errors.toDate && touched.toDate ? (
                  <div className="error">{errors.toDate}</div>
                ) : null}
              </div>

              <div className="col-md-6 mb-3">
                <label>Company</label>
              </div>
              <div className="col-md-6 mb-3">
                <Field className="form-control mb-2" as="select" name="company">
                  <option value="">
                    {isUpate ? initialValues.company : "Click to Select"}
                  </option>
                  {/* {Company?.map((val, i) => (
                    <option key={i} value={val}>
                      {val}
                    </option>
                  ))} */}
                </Field>
                {errors.company && touched.company ? (
                  <div className="error">{errors.company}</div>
                ) : null}
              </div>

              <div className="col-md-6 mb-3">
                <label>Mode of Payment</label>
              </div>
              <div className="col-md-6 mb-3">
                <Field className="form-control" as="select" name="paymentMode">
                  <option value="">
                    {isUpate ? initialValues.paymentType : "Click to Select"}
                  </option>
                  {/* {PaymentType?.map((val, i) => (
                    <option key={i} value={val}>
                      {val}
                    </option>
                  ))} */}
                </Field>
                {errors.paymentMode && touched.paymentMode ? (
                  <div className="error">{errors.paymentMode}</div>
                ) : null}
              </div>
            </div>

            <button
              className="btn bg-gradient-dark mx-auto d-block btn-lg mb-1"
              type="submit"
            >
              {isUpate ? "Update" : "Submit"}
            </button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AddCashBalance;
