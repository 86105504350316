import React, { useRef, useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import { useLocation, useNavigate } from "react-router-dom";

import { P2pSchema } from "../../Helpers/Constants/Schemas";
import { P2pFields } from "../../Helpers/Constants/InitialValues";
import FileUpload from "../Common/FileUpload";

import { EndPoints } from "../../Helpers/Constants/EndPoints";

import API from "../../Helpers/Constants/EndPoints/Api";
import Axios from "../../Helpers/Universal/Axios/Axios";
import {
  FilesFormData,
  FilesUpload,
  UpdateParam,
} from "../../Helpers/Universal/Function/common";
import { UPLOAD } from "../../Helpers/Constants/Default";
import Loader from "../Common/Loader";

const AddP2p = () => {
  const [visible, setVisible] = useState({ loader: false });

  const navigate = useNavigate();
  const { state } = useLocation();

  const bankFiles = useRef(null);
  const dashboardFiles = useRef(null);

  const [isUpate] = useState(state ? true : false);
  const [initialValues] = useState(
    isUpate
      ? { ...state?.val, bank: state?.val?.bank?._id }
      : P2pFields.required
  );

  const [dropdown, setDropdown] = useState({ banks: [] });

  const getDropdowns = async () => {
    try {
      const { status, data } = await Axios.get(API.DROPDOWN.BANK);
      if (status) setDropdown({ banks: data });
    } catch (err) {
      console.log("ERROR==>", err);
    }
  };

  const handleSubmit = async (data) => {
    try {
      if (bankFiles.current?.formData)
        data.bankFiles = await FilesUpload(bankFiles.current?.formData);

      if (dashboardFiles.current?.formData)
        data.dashboardFiles = await FilesUpload(
          dashboardFiles.current?.formData
        );

      const { status } = await Axios.post(API.P2P.CREATE, data);
      if (status) navigate(EndPoints.P2P_ACCOUNT);
    } catch (err) {
      console.log("ERROR==>", err);
    } finally {
      setVisible({ loader: false });
    }
  };

  const handleUpdateDocument = async (data) => {
    try {
      const params = await UpdateParam(P2pFields, data);
      const { status } = await Axios.patch(API.P2P.UPDATE, {
        p2pAccount_id: initialValues._id,
        ...params,
      });
      if (status) navigate(EndPoints.P2P_ACCOUNT);
    } catch (err) {
      console.log("ERROR==>", err);
    } finally {
      setVisible({ loader: false });
    }
  };

  const handleFileChange = (event, ref) => {
    ref.current.formData = FilesFormData(event, UPLOAD.P2pAccount);
  };

  useEffect(() => {
    getDropdowns();
  }, []);

  return (
    <>
      {visible.loader && <Loader />}
      <Formik
        initialValues={initialValues}
        validationSchema={P2pSchema}
        onSubmit={(values, { resetForm }) => {
          setVisible({ loader: true });
          isUpate ? handleUpdateDocument(values) : handleSubmit(values);
          resetForm();
        }}
      >
        {({ errors, touched }) => (
          <Form className="border rounded shadow mx-auto w-50 mt-7 m-4 p-4">
            <div className="row">
              <div className="col-md-6 mb-3">
                <label>Bank</label>
              </div>
              <div className="col-md-6 mb-3">
                <Field className="form-control" name="bank" as="select">
                  <option value="">Select Bank</option>
                  {dropdown.banks?.map((val) => (
                    <option key={val._id} value={val._id}>
                      {val.firmName} - {val.bankName}
                    </option>
                  ))}
                </Field>
                {errors.bank && touched.bank ? (
                  <div className="error">{errors.bank}</div>
                ) : null}
              </div>

              <div className="col-md-6 mb-3">
                <label>Amount Received on dashboard</label>
              </div>
              <div className="col-md-6 mb-3">
                <Field
                  className="form-control mb-2"
                  type="number"
                  name="bankAmount"
                />
                {errors.bankAmount && touched.bankAmount ? (
                  <div className="error">{errors.bankAmount}</div>
                ) : null}
                {isUpate || (
                  <FileUpload
                    name="dashboardFiles"
                    fileRef={dashboardFiles}
                    handleFileChange={(e) =>
                      handleFileChange(e, dashboardFiles)
                    }
                  />
                )}
              </div>

              <div className="col-md-6 mb-3">
                <label>Amount Received on Bank</label>
              </div>
              <div className="col-md-6 mb-3">
                <Field
                  className="form-control"
                  type="number"
                  name="dashboardAmount"
                />
                {errors.dashboardAmount && touched.dashboardAmount ? (
                  <div className="error">{errors.dashboardAmount}</div>
                ) : null}
                {isUpate || (
                  <FileUpload
                    name="bankFiles"
                    fileRef={bankFiles}
                    handleFileChange={(e) => handleFileChange(e, bankFiles)}
                  />
                )}
              </div>
            </div>

            <button
              className="btn bg-gradient-dark mx-auto d-block btn-lg mb-1"
              type="submit"
            >
              {isUpate ? "Update" : "Add"}
            </button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AddP2p;
