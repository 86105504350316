import React, { useEffect, useState } from "react";
import { Formik, Form, Field, FieldArray } from "formik";

import API from "../../Helpers/Constants/EndPoints/Api";
import Axios from "../../Helpers/Universal/Axios/Axios";
import { UpdateParam } from "../../Helpers/Universal/Function/common";
import { RoleFields } from "../../Helpers/Constants/InitialValues";

const Permission = ({ val, setData }) => {
  const [visible, setVisible] = useState(false);
  const [initialValues, setInitialValues] = useState(val);

  useEffect(() => {
    setInitialValues(val);
  }, [val]);

  const handleUpdateDocument = async (data) => {
    try {
      const params = await UpdateParam(RoleFields, data);

      const dashboard = params.permissions?.find(
        (val) => val.field === "dashboard"
      );

      if (dashboard.all || dashboard.add)
        params.permissions = params.permissions?.map((val) =>
          val.field === "transaction" ? { ...val, add: true } : val
        );

      const { status } = await Axios.patch(API.ROLE.UPDATE, {
        role_id: initialValues._id,
        ...params,
      });
      if (status) {
        setData((prev) => ({
          ...prev,
          data: prev?.data?.map((val) =>
            val._id === initialValues._id ? data : val
          ),
        }));
      }
    } catch (err) {
      console.log("ERROR==>", err);
    } finally {
      setVisible(false);
    }
  };

  return (
    <>
      {visible ? (
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => {
            handleUpdateDocument(values);
          }}
        >
          {({ values }) => (
            <Form className="bg_viewpopup">
              <div className="successfull bg-white p-4 w-50 top-50 start-50 end-0 border border-secondary rounded text-center position-absolute">
                <FieldArray name="permissions">
                  {() => (
                    <table className="table table-bordered text-center">
                      <thead className="bg-light text-dark">
                        <tr>
                          <th scope="col">Menu Name</th>
                          <th scope="col">All</th>
                          <th scope="col">View</th>
                          <th scope="col">Add</th>
                          <th scope="col">Edit</th>
                          <th scope="col">Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {values?.permissions?.map((val, index) => (
                          <tr key={index}>
                            <td>{val.name}</td>
                            <td>
                              <Field
                                className="form-check-input"
                                type="checkbox"
                                name={`permissions[${index}].all`}
                                checked={val.all}
                              />
                            </td>
                            <td>
                              <Field
                                className="form-check-input"
                                type="checkbox"
                                name={`permissions[${index}].view`}
                                checked={val.all ? val.all : val.view}
                              />
                            </td>
                            <td>
                              <Field
                                className="form-check-input"
                                type="checkbox"
                                name={`permissions[${index}].add`}
                                checked={val.all ? val.all : val.add}
                              />
                            </td>
                            <td>
                              <Field
                                className="form-check-input"
                                type="checkbox"
                                name={`permissions[${index}].edit`}
                                checked={val.all ? val.all : val.edit}
                              />
                            </td>
                            <td>
                              <Field
                                className="form-check-input"
                                type="checkbox"
                                name={`permissions[${index}].delete`}
                                checked={val.all ? val.all : val.delete}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </FieldArray>
                <div className="d-flex justify-content-end mt-5">
                  <button type="submit" className="btn btn-success btn-sm me-2">
                    Save
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger btn-sm"
                    onClick={() => setVisible(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      ) : (
        <i
          className="fa fa-life-ring fs-6 text-success pe-auto"
          aria-hidden="true"
          onClick={() => setVisible((prev) => !prev)}
        ></i>
      )}
    </>
  );
};

export default Permission;
