import {
  addDoc,
  collection,
  getDocs,
  doc,
  updateDoc,
  deleteDoc,
  query,
  where,
  orderBy,
  serverTimestamp,
  getDoc,
} from "@firebase/firestore";
import { getStorage, ref, getDownloadURL, uploadBytes } from "firebase/storage";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import moment from "moment";
import Fuse from "fuse.js";

import { firestore } from "../../Helpers/Firebase/firebase";

const FireStore = {
  SUCCESS: async (res) => {
    if (res.status) return { status: true, data: res.data };
    else return { status: false, err: "Something Went Wrong" };
  },
  FAILURE: async (err) => {
    return {
      status: false,
      err: "Something Went Wrong",
      error: err.message,
      firebaseError: err,
    };
  },
  get: async (db, order, condition, dates) => {
    try {
      let QUR_FUN;
      const SORT =
          order && order?.length > 0
            ? orderBy(...order)
            : orderBy("timeStamp", "desc"),
        TIME_STAMP = {
          START_DATE:
            dates && dates.length > 0
              ? ["timeStamp", ">=", new Date(`${dates[0]}T00:00:00.000Z`)]
              : false,
          END_DATE:
            dates && dates.length > 0
              ? ["timeStamp", "<=", new Date(`${dates[1]}T23:59:59.999Z`)]
              : false,
        };

      if (condition && condition.length > 0 && !dates)
        QUR_FUN = query(
          collection(firestore, db),
          ...condition?.map((val) => where(...val)),
          SORT
        );
      else if (dates && dates.length > 0 && !condition)
        QUR_FUN = query(
          collection(firestore, db),
          where(...TIME_STAMP.START_DATE),
          where(...TIME_STAMP.END_DATE),
          SORT
        );
      else if (dates && dates.length > 0 && condition && condition.length > 0)
        QUR_FUN = query(
          collection(firestore, db),
          ...condition?.map((val) => where(...val)),
          where(...TIME_STAMP.START_DATE),
          where(...TIME_STAMP.END_DATE),
          SORT
        );
      else if (order && order.length > 0 && !condition && !dates)
        QUR_FUN = query(collection(firestore, db), SORT);
      else QUR_FUN = collection(firestore, db);

      const { docs } = await getDocs(QUR_FUN);
      const data = docs.map((val) => ({ ...val.data(), id: val.id }));

      return FireStore.SUCCESS({ status: true, data });
    } catch (error) {
      return FireStore.FAILURE(error);
    }
  },
  getWithPopulate: async (data, fields) => {
    try {
      const populatedData = [];

      for await (let val of data) {
        for await (let key of fields) {
          if (key.multiple && val[key.field]) {
            const multiPopulated = [];

            for await (let id of val[key.field]) {
              const ID = key.subField ? id[key.subField] : id;
              if (ID) {
                const { status, data } = await FireStore.findDocumentById(
                  key.db[0],
                  ID
                );

                if (status && Object.keys(data).length > 2) {
                  multiPopulated.push(
                    key.subField ? { ...id, [key.subField]: data } : data
                  );
                } else if (key.db[1]) {
                  const findData = await FireStore.findDocumentById(
                    key.db[1],
                    ID
                  );

                  if (findData?.status) {
                    multiPopulated.push(
                      key.subField
                        ? { ...id, [key.subField]: findData?.data }
                        : findData?.data
                    );
                  }
                }
              }
            }

            val[key.field] = multiPopulated;
          } else if (!key.multiple && val[key.field]) {
            const { status, data } = await FireStore.findDocumentById(
              key.db[0],
              val[key.field]
            );

            if (status) val[key.field] = data;
          }
        }
        populatedData.push(val);
      }

      return FireStore.SUCCESS({ status: true, data: populatedData });
    } catch (error) {
      return FireStore.FAILURE(error);
    }
  },
  patch: async (db, id, data) => {
    try {
      const sanitizedData = {};

      data.timeStamp = serverTimestamp();
      data.updatedAt = moment().format("DD/MM/YYYY");
      data.timeStampU = data.timeStamp;

      const ref = doc(firestore, db, id);
      const docSnapshot = await getDoc(ref);

      if (docSnapshot.exists) data.timeStamp = docSnapshot.data().timeStamp;

      Object.keys(data).forEach((key) => {
        if (data[key] !== undefined) sanitizedData[key] = data[key];
      });

      await updateDoc(ref, sanitizedData);

      return FireStore.SUCCESS({ status: true });
    } catch (error) {
      return FireStore.FAILURE(error);
    }
  },
  findDocumentById: async (db, documentId) => {
    try {
      let data = {};
      const docRef = doc(firestore, db, documentId);
      const docSnapshot = await getDoc(docRef);
      if (docSnapshot.exists)
        data = { ...docSnapshot.data(), id: docSnapshot.id };
      return FireStore.SUCCESS({ status: true, data });
    } catch (error) {
      return FireStore.FAILURE(error);
    }
  },
  findDocumentBySearch: async ({
    db,
    order,
    condition,
    dates,
    fields,
    search,
  }) => {
    try {
      let { status, data } = await FireStore.get(
        db,
        order,
        condition,
        dates,
        fields,
        search
      );

      if (status && search) {
        const options = { keys: fields };
        const fuse = new Fuse(data, options);

        const results = fuse.search(search);
        data = results.map((result) => result.item);
      }

      return FireStore.SUCCESS({ status: true, data });
    } catch (error) {
      FireStore.FAILURE(error);
    }
  },
  post: async (db, data) => {
    try {
      const ref = collection(firestore, db);
      await addDoc(ref, {
        ...data,
        createdAt: moment().format("DD/MM/YYYY"),
        updatedAt: moment().format("DD/MM/YYYY"),
        timeStamp: serverTimestamp(),
        timeStampU: serverTimestamp(),
      });
      return FireStore.SUCCESS({ status: true });
    } catch (error) {
      return FireStore.FAILURE(error);
    }
  },
  delete: async (db, id) => {
    try {
      const data = doc(firestore, db, id);
      await deleteDoc(data);
      return FireStore.SUCCESS({ status: true });
    } catch (error) {
      return FireStore.FAILURE(error);
    }
  },
  upload: async (data) => {
    try {
      const storageRef = ref(getStorage(), data.name);
      await uploadBytes(storageRef, data);
      return await getDownloadURL(storageRef);
    } catch (error) {
      return FireStore.FAILURE(error);
    }
  },
  signUp: async (email, password) => {
    try {
      const auth = getAuth();
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      return FireStore.SUCCESS({ status: true, data: user });
    } catch (error) {
      return FireStore.FAILURE(error);
    }
  },
  signIn: async (email, password) => {
    try {
      const auth = getAuth();
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      return FireStore.SUCCESS({ status: true, data: user });
    } catch (error) {
      return FireStore.FAILURE(error);
    }
  },
};

export default FireStore;
