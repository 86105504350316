import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";

import { TransferSchema } from "../../Helpers/Constants/Schemas";
import { TransferFileds } from "../../Helpers/Constants/InitialValues";

import { EndPoints } from "../../Helpers/Constants/EndPoints";
import { TRANSFER } from "../../Helpers/Constants/Dropdowns";

import API from "../../Helpers/Constants/EndPoints/Api";
import Axios from "../../Helpers/Universal/Axios/Axios";
import { UpdateParam } from "../../Helpers/Universal/Function/common";

import Loader from "../Common/Loader";

const AddTransfer = () => {
  const [visible, setVisible] = useState({ loader: false });
  const navigate = useNavigate();
  const { state } = useLocation();

  const [isUpate] = useState(state ? true : false);
  const [initialValues] = useState(
    isUpate
      ? {
          ...state.val,
          fromCompany: state?.val?.fromCompany?._id,
          toCompany: state?.val?.toCompany?._id,
        }
      : TransferFileds.required
  );

  const [dropdown, setDropdown] = useState({ companies: [] });

  const getDropdowns = async () => {
    try {
      const { data, status } = await Axios.get(API.DROPDOWN.COMPANY);

      if (status) setDropdown({ companies: data });
    } catch (err) {
      console.log("ERROR==>", err);
    }
  };

  const handleSubmit = async (data) => {
    try {
      const { status } = await Axios.post(API.TRANSFER.CREATE, data);
      if (status) navigate(EndPoints.TRANSFER);
    } catch (err) {
      console.log("ERROR==>", err);
    } finally {
      setVisible({ loader: false });
    }
  };

  const handleUpdateDocument = async (data) => {
    try {
      const params = await UpdateParam(TransferFileds, data);

      const { status } = await Axios.patch(API.TRANSFER.UPDATE, {
        transfer_id: initialValues._id,
        ...params,
      });
      if (status) navigate(EndPoints.TRANSFER);
    } catch (err) {
      console.log("ERROR==>", err);
    } finally {
      setVisible({ loader: false });
    }
  };

  useEffect(() => {
    getDropdowns();
  }, []);

  return (
    <>
      {visible.loader && <Loader />}
      <Formik
        initialValues={initialValues}
        validationSchema={TransferSchema}
        onSubmit={(values, { resetForm }) => {
          setVisible({ loader: true });
          isUpate ? handleUpdateDocument(values) : handleSubmit(values);
          resetForm();
        }}
      >
        {({ errors, touched }) => (
          <Form className="border rounded shadow mx-auto w-50 mt-7 m-4 p-4">
            <div className="row">
              <div className="col-md-6 mb-3">
                <label>From Company</label>
              </div>
              <div className="col-md-6 mb-3">
                <Field className="form-control" name="fromCompany" as="select">
                  <option value="">Click to Select</option>
                  {dropdown.companies?.map((val) => (
                    <option key={val._id} value={val._id}>
                      {val.name}
                    </option>
                  ))}
                </Field>
                {errors.fromCompany && touched.fromCompany ? (
                  <div className="error">{errors.fromCompany}</div>
                ) : null}
              </div>

              <div className="col-md-6 mb-3">
                <label>Mode of Payment</label>
              </div>
              <div className="col-md-6 mb-3">
                <Field className="form-control" name="paymentMode" as="select">
                  <option value="">Click to Select</option>
                  {Object.keys(TRANSFER.MODE_OF_PAYMENT)?.map((val, i) => (
                    <option key={i} value={TRANSFER.MODE_OF_PAYMENT[val]}>
                      {val}
                    </option>
                  ))}
                </Field>
                {errors.paymentMode && touched.paymentMode ? (
                  <div className="error">{errors.paymentMode}</div>
                ) : null}
              </div>

              <div className="col-md-6 mb-3">
                <label>Amount</label>
              </div>
              <div className="col-md-6 mb-3">
                <Field className="form-control" name="amount" type="number" />
                {errors.amount && touched.amount ? (
                  <div className="error">{errors.amount}</div>
                ) : null}
              </div>

              <div className="col-md-6 mb-3">
                <label>To Company</label>
              </div>
              <div className="col-md-6 mb-3">
                <Field className="form-control" name="toCompany" as="select">
                  <option value="">Click to Select</option>
                  {dropdown.companies?.map((val) => (
                    <option key={val._id} value={val._id}>
                      {val.name}
                    </option>
                  ))}
                </Field>
                {errors.toCompany && touched.toCompany ? (
                  <div className="error">{errors.toCompany}</div>
                ) : null}
              </div>
            </div>

            <button
              className="btn bg-gradient-dark mx-auto d-block btn-lg mb-1"
              type="submit"
            >
              {isUpate ? "Update" : "Add"}
            </button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AddTransfer;
