import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import Switch from "react-switch";

import { RoleSchema } from "../../Helpers/Constants/Schemas";
import { RoleFields } from "../../Helpers/Constants/InitialValues";
import { EndPoints } from "../../Helpers/Constants/EndPoints";
import API from "../../Helpers/Constants/EndPoints/Api";
import Axios from "../../Helpers/Universal/Axios/Axios";
import { UpdateParam } from "../../Helpers/Universal/Function/common";

const AddRole = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [isUpate] = useState(state ? true : false);
  const [initialValues] = useState(isUpate ? state.val : RoleFields.required);

  const [toggle, setToggle] = useState(state?.val?.status || 0);

  const handleSubmit = async (data) => {
    try {
      const { status } = await Axios.post(API.ROLE.CREATE, data);
      if (status) navigate(EndPoints.ROLE);
    } catch (err) {
      console.log("ERROR==>", err);
    }
  };

  const handleUpdateDocument = async (data) => {
    try {
      const params = await UpdateParam(RoleFields, data);
      const { status } = await Axios.patch(API.ROLE.UPDATE, {
        role_id: initialValues._id,
        ...params,
      });
      if (status) navigate(EndPoints.ROLE);
    } catch (err) {
      console.log("ERROR==>", err);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={RoleSchema}
      onSubmit={(values, { resetForm }) => {
        const data = { ...values, status: toggle };
        isUpate ? handleUpdateDocument(data) : handleSubmit(data);
        // resetForm();
      }}
    >
      {({ errors, touched }) => (
        <Form className="border rounded shadow mx-auto w-50 mt-7 m-4 p-4">
          <div className="row">
            <div className="col-md-6 mb-3">
              <label>Role</label>
            </div>
            <div className="col-md-6 mb-3">
              <Field className="form-control" type="text" name="name" />
              {errors.name && touched.name ? (
                <div className="error">{errors.name}</div>
              ) : null}
            </div>

            <div className="col-md-6 mb-3">
              <label>Status</label>
            </div>
            <div className="col-md-6 mb-3">
              <Switch
                checked={toggle ? true : false}
                onChange={() => setToggle((prev) => (prev ? 0 : 1))}
              />
            </div>
          </div>

          <button
            className="btn bg-gradient-dark mx-auto d-block btn-lg mb-1"
            type="submit"
          >
            {isUpate ? "Update" : "Add"}
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default AddRole;
