import { useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import "./App.css";
import "./Components/Custom/custom.css";
import Sidebar from "./Components/Sidebar/Sidebar";
import Header from "./Components/Header/Header";

import { routes } from "./Helpers/Constants/Routes";
import PvtRoute from "./Components/Auth/PvtRoute";
import { EndPoints } from "./Helpers/Constants/EndPoints";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setProfile } from "./slices/profileSlice";
import { getProfile } from "./Helpers/Universal/Axios/Operation";

function App() {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const [collapse, setCollapse] = useState(false);
  const excludeSidebar = pathname === EndPoints.LOGIN;

  const { profile } = useSelector((val) => val);
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (token && !profile.role)
      (async () => {
        dispatch(setProfile(await getProfile()));
      })();
  });

  return (
    <>
      {!excludeSidebar && (
        <Sidebar setCollapse={setCollapse} collapse={collapse} />
      )}
      <main className="main-content position-relative border-radius-lg">
        {!excludeSidebar && <Header collapse={collapse} />}
        <Routes>
          {routes()?.map((val, i) => (
            <Route
              key={i}
              exact
              path={val.path}
              element={
                val.pvtRoute ? (
                  <PvtRoute
                    children={val.element}
                    path={val.path?.split("/")[1]}
                  />
                ) : (
                  <val.element />
                )
              }
            />
          ))}
        </Routes>
      </main>
      {/* <Error /> */}
    </>
  );
}

export default App;
