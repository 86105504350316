import { EndPoints } from "../../Helpers/Constants/EndPoints";
const {
  LOGIN,
  DASHBOARD,
  ADD_BANK,
  BANK,
  ADD_INTERNAL_TRANSFER,
  INTERNAL_TRANSFER,
  ADD_PAYMENT_GATEWAY,
  PAYMENT_GATEWAY,
  ADD_P2P_ACCOUNT,
  P2P_ACCOUNT,
  ADD_PLATFORM,
  PLATFORM,
  ADD_EXPENSE_TYPE,
  EXPENSE_TYPE,
  ADD_CUSTOM_EXPENSE,
  CUSTOM_EXPENSE,
  ADD_ACCOUNTING,
  ACCOUNTING,
  ADD_SUB_PARTICULAR,
  SUB_PARTICULAR,
  TRANSACTION,
  BANK_CURRENT_BALNACE,
  PG_CURRENT_BALNACE,
  ROLE,
  ADD_ROLE,
  USER,
  ADD_USER,
  VIEW_TRANSACTION,
  ADD_TRANSFER,
  TRANSFER,
  COMPANY,
  VIEW_COMPANY,
  ADD_COMPANY,
  ADD_PARTICULAR,
  PARTICULAR,
} = EndPoints;

export const sidebar = [
  {
    name: "Dashboard",
    link: [DASHBOARD],
    icon: "dashboard",
  },
  {
    name: "Transactions",
    link: [TRANSACTION, VIEW_TRANSACTION],
    icon: "payment",
  },
  {
    name: "Accounting",
    link: [ACCOUNTING, ADD_ACCOUNTING],
    icon: "person",
  },
  {
    name: "Transfer",
    link: [TRANSFER, ADD_TRANSFER],
    icon: "swap_horiz",
  },
  {
    name: "Internal Transfer",
    link: [INTERNAL_TRANSFER, ADD_INTERNAL_TRANSFER],
    icon: "view_in_ar",
  },
  {
    name: "P2P Account",
    link: [P2P_ACCOUNT, ADD_P2P_ACCOUNT],
    icon: "person",
  },
  {
    name: "Custom Expenses",
    link: [CUSTOM_EXPENSE, ADD_CUSTOM_EXPENSE],
    icon: "money",
  },
  {
    name: "Bank Current Balance",
    link: [BANK_CURRENT_BALNACE],
    icon: "wallet",
  },
  {
    name: "PG Current Balance",
    link: [PG_CURRENT_BALNACE],
    icon: "wallet",
  },
  // {
  //   name: "Cash Balance",
  //   link: [CASH_BALANCE, ADD_CASH_BALANCE],
  //   icon: "wallet",
  // },
  { break: true },
  {
    name: "Settings",
    icon: "settings",
    link: [false],
    childrens: [
      { name: "Role", link: [ROLE, ADD_ROLE], icon: "person" },
      { name: "User", link: [USER, ADD_USER], icon: "person" },
      { name: "Bank", link: [BANK, ADD_BANK], icon: "table_view" },
      {
        name: "Company",
        link: [COMPANY, VIEW_COMPANY, ADD_COMPANY],
        icon: "table_view",
      },
      {
        name: "Payment Gateway",
        link: [PAYMENT_GATEWAY, ADD_PAYMENT_GATEWAY],
        icon: "payments",
      },
      { name: "Platform", link: [PLATFORM, ADD_PLATFORM], icon: "wallet" },
      {
        name: "Particular",
        link: [PARTICULAR, ADD_PARTICULAR],
        icon: "wallet",
      },
      {
        name: "Sub Particular",
        link: [SUB_PARTICULAR, ADD_SUB_PARTICULAR],
        icon: "circle",
      },
      {
        name: "Type Of Expenses",
        link: [EXPENSE_TYPE, ADD_EXPENSE_TYPE],
        icon: "money",
      },
    ],
  },
  {
    name: "Logout",
    link: [LOGIN],
    icon: "logout",
  },
];
