import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { sidebar } from "../../Helpers/Constants/Sidebar";
import { EndPoints } from "../../Helpers/Constants/EndPoints";
import { useSelector } from "react-redux";
import { USER_TYPE } from "../../Helpers/Constants/Default";

const Sidebar = ({ setCollapse, collapse }) => {
  const [dropdown, setDropdown] = useState(false);

  const { pathname } = useLocation();
  const { profile } = useSelector((val) => val);

  const checkAvalability = (path) => {
    if (profile?.role === USER_TYPE[0]) return { view: true };

    if (!path) return { view: true };

    return profile
      ? profile?.permissions?.find((ele) => `/${ele.field}` === path)
      : false;
  };

  return (
    <aside
      className={`sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3 bg-gradient-dark ps bg-white 
      ${collapse ? "sidebarcollapse" : "null"}`}
    >
      <div className="sidenav-header">
        <i
          className="fas fa-times p-3 cursor-pointer text-white opacity-5 position-absolute end-0 top-0 d-none d-xl-none"
          aria-hidden="true"
        ></i>
        <Link to={EndPoints.DASHBOARD}>
          <li className="navbar-brand m-0" target="_blank">
            <span className="font-weight-bold text-white d-flex justify-content-center">
              A C C O U N T I N G
            </span>
          </li>
        </Link>
      </div>

      <hr className="horizontal light mt-0 mb-3"></hr>

      <div className="collapse navbar-collapse w-auto">
        <ul className="navbar-nav">
          {sidebar?.map((val, i, data) => {
            const avaliable = checkAvalability(val.link?.[0]);

            if (avaliable && (avaliable?.view || avaliable?.all))
              return val.break ? (
                <li key={i} className="nav-item mt-3 ">
                  <h6 className="ps-4 ms-2 text-uppercase text-xs text-white font-weight-bolder opacity-8">
                    Options
                  </h6>
                </li>
              ) : (
                <div key={i}>
                  <Link
                    to={val.link?.[0]}
                    className={
                      val.link?.includes(pathname) ? "active d-flex" : "null"
                    }
                  >
                    <li
                      className="nav-item nav-link text-white"
                      onClick={() =>
                        val.name === "Settings" ? setDropdown(!dropdown) : null
                      }
                    >
                      <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                        <i className="material-icons opacity-10">{val.icon}</i>
                      </div>
                      <span className="nav-link-text ms-1">{val.name}</span>
                    </li>
                  </Link>
                  {val?.childrens && (
                    <ul
                      key={i + data.length}
                      className={!dropdown ? "dropdown-menu" : "null"}
                    >
                      {val.childrens?.map((val, j) => {
                        const avaliable = checkAvalability(val.link?.[0]);

                        if (avaliable && (avaliable?.view || avaliable?.all))
                          return (
                            <Link
                              to={val.link?.[0]}
                              className={
                                val.link?.includes(pathname)
                                  ? "active d-flex"
                                  : "null"
                              }
                              key={j}
                            >
                              <li className="nav-item nav-link text-white">
                                <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                  <i className="material-icons opacity-10">
                                    {val.icon}
                                  </i>
                                </div>
                                <span className="nav-link-text ms-1">
                                  {val.name}
                                </span>
                              </li>
                            </Link>
                          );
                      })}
                    </ul>
                  )}
                </div>
              );
          })}
        </ul>
      </div>

      <div className="sidenav-footer  w-100 bottom-0 ">
        <button
          className="sidebarback"
          onClick={() => setCollapse((prev) => !prev)}
        >
          <i className={`fa fa-angle-${collapse ? "right" : "left"}`}></i>
        </button>
      </div>
    </aside>
  );
};

export default Sidebar;
