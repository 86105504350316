import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import Switch from "react-switch";

import { UserSchema } from "../../Helpers/Constants/Schemas";
import { UserFields } from "../../Helpers/Constants/InitialValues";

import { EndPoints } from "../../Helpers/Constants/EndPoints";
import { useEffect } from "react";

import API from "../../Helpers/Constants/EndPoints/Api";
import Axios from "../../Helpers/Universal/Axios/Axios";
import { UpdateParam } from "../../Helpers/Universal/Function/common";

const AddUser = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [isUpate] = useState(state ? true : false);
  const [initialValues] = useState(
    isUpate
      ? {
          ...state?.val,
          password: state?.val?.dcryptedPass,
          role: state?.val?.role?._id,
        }
      : UserFields.required
  );

  const [role, setRole] = useState([]);
  const [toggle, setToggle] = useState(state?.val?.status ? 1 : 0);

  const handleSubmit = async (data) => {
    try {
      const { status } = await Axios.post(API.USER.CREATE, data);
      if (status) navigate(EndPoints.USER);
    } catch (err) {
      console.log("ERROR==>", err);
    }
  };

  const getRoles = async () => {
    try {
      const { status, data } = await Axios.get(API.DROPDOWN.ROLE);
      if (status) setRole(data);
    } catch (err) {
      console.log("ERROR==>", err);
    }
  };

  const handleUpdateDocument = async (data) => {
    try {
      const params = await UpdateParam(UserFields, data);
      const { status } = await Axios.patch(API.USER.UPDATE, {
        user_id: initialValues._id,
        ...params,
      });

      if (status) navigate(EndPoints.USER);
    } catch (err) {
      console.log("ERROR==>", err);
    }
  };

  useEffect(() => {
    getRoles();
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={UserSchema}
      onSubmit={(values, { resetForm }) => {
        values.status = toggle;
        isUpate ? handleUpdateDocument(values) : handleSubmit(values);
        resetForm();
      }}
    >
      {({ errors, touched }) => (
        <Form className="border rounded shadow mx-auto w-50 mt-7 m-4 p-4">
          <div className="row">
            <div className="col-md-6 mb-3">
              <label>Name</label>
            </div>
            <div className="col-md-6 mb-3">
              <Field className="form-control" type="text" name="name" />
              {errors.name && touched.name ? (
                <div className="error">{errors.name}</div>
              ) : null}
            </div>

            <div className="col-md-6 mb-3">
              <label>Email</label>
            </div>
            <div className="col-md-6 mb-3">
              <Field className="form-control" type="email" name="email" />
              {errors.email && touched.email ? (
                <div className="error">{errors.email}</div>
              ) : null}
            </div>

            <div className="col-md-6 mb-3">
              <label>Password</label>
            </div>
            <div className="col-md-6 mb-3">
              <Field className="form-control" type="text" name="password" />
              {errors.password && touched.password ? (
                <div className="error">{errors.password}</div>
              ) : null}
            </div>

            <div className="col-md-6 mb-3">
              <label>Role</label>
            </div>
            <div className="col-md-6 mb-3">
              <Field className="form-control" as="select" name="role">
                <option value="">Select a Role</option>
                {role?.map((val) => (
                  <option key={val._id} value={val._id}>
                    {val.name}
                  </option>
                ))}
              </Field>
              {errors.role && touched.role ? (
                <div className="error">{errors.role}</div>
              ) : null}
            </div>

            <div className="col-md-6 mb-3">
              <label>Status</label>
            </div>
            <div className="col-md-6 mb-3">
              <Switch
                checked={toggle ? true : false}
                onChange={() => setToggle((prev) => (prev ? 0 : 1))}
              />
            </div>
          </div>

          <button
            className="btn bg-gradient-dark mx-auto d-block btn-lg mb-1"
            type="submit"
          >
            {isUpate ? "Update" : "Add"}
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default AddUser;
