import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";

import { LoginFileds } from "../../Helpers/Constants/InitialValues";
import { LoginSchema } from "../../Helpers/Constants/Schemas";

import Loader from "../Common/Loader";

import API from "../../Helpers/Constants/EndPoints/Api";

import Axios from "../../Helpers/Universal/Axios/Axios";
import { EndPoints } from "../../Helpers/Constants/EndPoints";
import { getProfile } from "../../Helpers/Universal/Axios/Operation";
import { useDispatch } from "react-redux";
import { setProfile } from "../../slices/profileSlice";

const Login = () => {
  const [visible, setVisible] = useState(false);
  const [initialValues] = useState(LoginFileds);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (params) => {
    try {
      setVisible(true);

      const { status, data } = await Axios.post(API.LOGIN.ADMIN, params);

      if (status) {
        localStorage.setItem("token", data.accessToken);
        dispatch(setProfile(await getProfile()));
        navigate(EndPoints.DASHBOARD);
      }
    } catch (err) {
      console.log("ERROR==>", err);
    } finally {
      setVisible(false);
    }
  };

  return (
    <>
      {visible && <Loader />}
      <Formik
        initialValues={initialValues}
        validationSchema={LoginSchema}
        onSubmit={(values, { resetForm }) => {
          handleSubmit(values);
          // resetForm();
        }}
      >
        {({ errors, touched }) => (
          <main className="main-content mt-0">
            <div className="page-header align-items-start min-vh-100">
              <span className="mask opacity-6"></span>
              <div className="container my-auto">
                <div className="row">
                  <div className="col-lg-6 col-md-8 col-12 mx-auto">
                    <div className="card z-index-0 fadeIn3 fadeInBottom">
                      <div className="card-header p-0 position-relative mt-n4 mx-3 z-index-2 d-flex justify-content-center">
                        <div className="bg-gradient-dark border-radius-lg w-45">
                          <h5 className="text-white font-weight-bolder text-center shadow-lg p-2 pl-3 pr-3 mb-0">
                            Accounting
                          </h5>
                        </div>
                      </div>
                      <div className="card-body">
                        <Form role="form" className="text-start">
                          <div className="input-group input-group-outline my-3 mb-5">
                            <label className="form-label w-0 mt-n5">
                              Email
                            </label>
                            <Field
                              className="form-control w-100"
                              type="text"
                              name="email"
                            />
                            {errors.email && touched.email ? (
                              <div className="error">{errors.email}</div>
                            ) : null}
                          </div>
                          <div className="input-group input-group-outline mb-3">
                            <label className="form-label w-0 mt-n5">
                              Password
                            </label>
                            <Field
                              className="form-control w-100"
                              type="password"
                              name="password"
                            />
                            {errors.password && touched.password ? (
                              <div className="error">{errors.password}</div>
                            ) : null}
                          </div>
                          <div className="text-center">
                            <button
                              type="submit"
                              className="btn bg-gradient-dark w-100 my-4 mb-2"
                            >
                              Sign in
                            </button>
                          </div>
                          {/* <p className="mt-4 text-sm text-center">
                           Reset Password
                           <a className="mx-1 bg-gradient-dark text-gradient font-weight-bold">
                             click here
                           </a>
                         </p> */}
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        )}
      </Formik>
    </>
  );
};

export default Login;
