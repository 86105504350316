import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { EndPoints } from "../../Helpers/Constants/EndPoints";
import Loader from "../Common/Loader";

import API from "../../Helpers/Constants/EndPoints/Api";
import Axios from "../../Helpers/Universal/Axios/Axios";
import { CreatedAt } from "../../Helpers/Universal/Function/common";
import Paginate from "../Common/Paginate";
import { LIMIT, NA, OFFSET } from "../../Helpers/Constants/Default";
import DeleteConfirmation from "../Common/DeleteConfirmation ";

const PaymentGateway = ({ permissions: { all, add, edit, delete: del } }) => {
  const [data, setData] = useState([]);
  const [visible, setVisible] = useState({
    loader: false,
    deleteConformation: { data: false, visible: false },
  });
  const [filter, setFilter] = useState({ limit: LIMIT, offset: OFFSET });
  const [dropdown, setDropdown] = useState({
    paymentGateways: [],
    platforms: [],
  });

  const getData = async () => {
    try {
      setVisible((prev) => ({ ...prev, loader: true }));
      const { status, data } = await Axios.get(
        API.PAYMENT_GATEWAY.LISTING,
        filter
      );
      if (status) setData(data);
    } catch (err) {
      console.log("ERROR==>", err);
    } finally {
      setVisible((prev) => ({ ...prev, loader: false }));
    }
  };

  const getDropdowns = async () => {
    try {
      const [platforms, paymentGateways] = await Promise.all([
        Axios.get(API.DROPDOWN.PLATFORM),
        Axios.get(API.DROPDOWN.PAYMENT_GATEWAY),
      ]);

      if (platforms.status)
        setDropdown((prev) => ({ ...prev, platforms: platforms.data }));

      if (paymentGateways.status)
        setDropdown((prev) => ({
          ...prev,
          paymentGateways: paymentGateways.data,
        }));
    } catch (err) {
      console.log("ERROR==>", err);
    }
  };

  const setFilters = (event, key) => {
    const value = event.target.value;

    value
      ? setFilter((prev) => ({ ...prev, [key]: value }))
      : setFilter((prev) => {
          delete prev[key];
          return { ...prev };
        });
  };

  useEffect(() => {
    getData();
  }, [filter]);

  useEffect(() => {
    getDropdowns();
  }, []);

  return (
    <>
      {visible.loader && <Loader />}
      {visible.deleteConformation.visible && (
        <DeleteConfirmation
          visible={visible}
          setVisible={setVisible}
          setData={setData}
          API={API.PAYMENT_GATEWAY.DELETE}
        />
      )}
      <div className="container-fluid py-3">
        <select
          className=" form-select text-start btn bg-gradient-dark btn-lg mb-1 w-10"
          onChange={(e) => setFilters(e, "platform_id")}
        >
          <option value="">Platform</option>
          {dropdown?.platforms?.map((val) => (
            <option key={val._id} value={val._id}>
              {val.name}
            </option>
          ))}
        </select>
        <select
          className="form-select text-start btn bg-gradient-dark btn-lg mb-1 w-15 mx-2"
          onChange={(e) => setFilters(e, "paymentGateway_id")}
        >
          <option value="">Payment Gateway</option>
          {dropdown?.paymentGateways?.map((val) => (
            <option key={val._id} value={val._id}>
              {val.name}
            </option>
          ))}
        </select>
        {(all || add) && (
          <Link to={EndPoints.ADD_PAYMENT_GATEWAY}>
            <button className="btn bg-gradient-dark float-end d-flex btn-md mb-1">
              <i className="fas fa-plus me-2 mt-1" aria-hidden="true"></i>
              Add
            </button>
          </Link>
        )}

        <div className="overflow-x-scroll w-100">
          <table className="table-striped table align-middle mt-3 gate_way">
            <thead className="bg-light text-dark">
              <tr>
                <th scope="col" className="text-uppercase">
                  #
                </th>
                <th scope="col" className="text-uppercase">
                  Date
                </th>
                <th scope="col" className="text-uppercase">
                  Payment Gateway
                </th>
                <th scope="col" className="text-uppercase">
                  Platforms
                </th>
                <th scope="col" className="text-uppercase">
                  Pay In
                </th>
                <th scope="col" className="text-uppercase">
                  Pay Out
                </th>
                <th scope="col" className="text-uppercase">
                  Opening Balance
                </th>
                <th scope="col" className="text-uppercase custom-width-36">
                  Remarks
                </th>
                <th scope="col" className="text-uppercase">
                  Added By
                </th>
                {(all || edit || del) && (
                  <th scope="col" className="text-uppercase">
                    Action
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {data?.data?.map((val, i) => (
                <tr key={val._id}>
                  <td>{++i + filter.offset}</td>
                  <td>{CreatedAt(val.createdAt)} </td>
                  <td>{val.name} </td>
                  <td>
                    {val.platforms?.map((val) => (
                      <div key={val._id}>
                        {val.name}
                        <br></br>
                      </div>
                    ))}
                  </td>
                  <td>{val.payIn ? "Yes" : "No"}</td>
                  <td>{val.payOut ? "Yes" : "No"}</td>
                  <td>{val.openingBalance}</td>
                  <td className="custom-width-36">{val.remarks}</td>
                  <td>{val.createdBy?.name || NA}</td>
                  {(all || edit || del) && (
                    <td>
                      {(all || edit) && (
                        <Link
                          to={EndPoints.ADD_PAYMENT_GATEWAY}
                          state={{ val }}
                        >
                          <i className="fas fa-edit me-2 pe-auto"></i>
                        </Link>
                      )}
                      {(all || del) && (
                        <i
                          className="material-icons text-danger pe-auto"
                          onClick={() =>
                            setVisible((prev) => ({
                              ...prev,
                              deleteConformation: {
                                data: { _id: val._id, name: val.name },
                                visible: true,
                              },
                            }))
                          }
                        >
                          delete
                        </i>
                      )}
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Paginate count={data?.count} filter={filter} setFilter={setFilter} />
      </div>
    </>
  );
};

export default PaymentGateway;
