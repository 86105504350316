import React from "react";
import ReactPaginate from "react-paginate";

import { LIMIT, LIMITATION } from "../../Helpers/Constants/Default";

const Paginate = ({ count, filter, setFilter }) => {
  const handlePageClick = (e) => {
    setFilter((prev) => ({ ...prev, offset: e.selected * prev.limit }));
  };

  return count && count > LIMIT ? (
    <div className="pagination-btn d-flex">
      <ReactPaginate
        previousLabel={<i className={`fa fa-angle-left`}></i>}
        nextLabel={<i className={`fa fa-angle-right`}></i>}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={Math.ceil(count / filter.limit)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"}
      />
      <select
        onChange={(e) =>
          setFilter((prev) => ({ ...prev, limit: e.target.value }))
        }
      >
        {LIMITATION.map((val, i) => (
          <option key={i} value={val}>
            {val}
          </option>
        ))}
      </select>
    </div>
  ) : null;
};

export default Paginate;
