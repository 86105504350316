import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";

import DatePicker from "../Common/DatePicker";
import { EndPoints } from "../../Helpers/Constants/EndPoints";
import Loader from "../Common/Loader";

import API from "../../Helpers/Constants/EndPoints/Api";
import Axios from "../../Helpers/Universal/Axios/Axios";
import { CreatedAt, ReverseObj } from "../../Helpers/Universal/Function/common";
import { ACCOUNTING, BANK } from "../../Helpers/Constants/Dropdowns";
import { LIMIT, NA, NO_FILES, OFFSET } from "../../Helpers/Constants/Default";
import Paginate from "../Common/Paginate";
import ViewFile from "../Common/ViewFile";
import DeleteConfirmation from "../Common/DeleteConfirmation ";

const Accounting = ({ permissions: { all, add, edit, delete: del } }) => {
  const [data, setData] = useState([]);
  const [visible, setVisible] = useState({
    loader: false,
    deleteConformation: { data: false, visible: false },
  });
  const [dropdown, setDropdown] = useState({ paymentModes: [], companies: [] });
  const [filter, setFilter] = useState({
    limit: LIMIT,
    offset: OFFSET,
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });

  const getData = async () => {
    try {
      setVisible((prev) => ({ ...prev, loader: true }));
      const { status, data } = await Axios.get(API.ACCOUNTING.LISTING, filter);

      if (status) setData(data);
    } catch (err) {
      console.log("ERROR==>", err);
    } finally {
      setVisible((prev) => ({ ...prev, loader: false }));
    }
  };

  const getDropdowns = async () => {
    try {
      const [com, bank] = await Promise.all([
        Axios.get(API.DROPDOWN.COMPANY),
        Axios.get(API.DROPDOWN.BANK, { type: BANK.TYPE.ACCOUNTING }),
      ]);

      if (com.status) setDropdown((prev) => ({ ...prev, companies: com.data }));
      if (bank.status)
        setDropdown((prev) => ({ ...prev, paymentModes: bank.data }));
    } catch (err) {
      console.log("ERROR==>", err);
    }
  };

  const setFilters = (event, key) => {
    const value = event?.target?.value;

    value
      ? setFilter((prev) => ({ ...prev, [key]: value }))
      : setFilter((prev) => {
          delete prev[key];
          return { ...prev };
        });
  };

  useEffect(() => {
    getData();
  }, [filter]);

  useEffect(() => {
    getDropdowns();
  }, []);

  return (
    <>
      {visible.loader && <Loader />}
      {visible.deleteConformation.visible && (
        <DeleteConfirmation
          visible={visible}
          setVisible={setVisible}
          setData={setData}
          API={API.ACCOUNTING.DELETE}
        />
      )}
      <div className="container-fluid py-3">
        <div className="dateselect_navigation d-flex justify-content-between align-items-center">
          <div className="d-flex">
            <select
              className="form-select text-start btn bg-gradient-dark btn-lg mb-1 mx-2"
              onChange={(e) => setFilters(e, "paymentMode_id")}
            >
              <option value="">Accounting Banks</option>
              {dropdown.paymentModes?.map((val) => (
                <option key={val._id} value={val._id}>
                  {`${val.firmName || NA} - ${val.bankName || NA}`}
                </option>
              ))}
            </select>
            <select
              className="form-select text-start btn bg-gradient-dark btn-lg mb-1 mx-2 flex-container"
              onChange={(e) => setFilters(e, "company_id")}
            >
              <option value="">Company</option>
              {dropdown.companies?.map((val) => (
                <option key={val._id} value={val._id}>
                  {val.name}
                </option>
              ))}
            </select>
            <DatePicker filter={filter} setFilter={setFilter} />
          </div>
          {(all || add) && (
            <Link to={EndPoints.ADD_ACCOUNTING}>
              <button className="btn bg-gradient-dark float-end d-flex mt-3 mb-3 btn-md mb-1">
                <i className="fas fa-plus me-2 mt-1" aria-hidden="true"></i>
                Add
              </button>
            </Link>
          )}
        </div>

        <div className="overflow-x-scroll w-100">
          <table className="table-striped table align-middle mt-3 gate_way">
            <thead className="bg-light text-dark">
              <tr>
                <th scope="col" className="text-uppercase">
                  #
                </th>
                <th scope="col" className="text-uppercase">
                  Date
                </th>
                <th scope="col" className="text-uppercase">
                  Selected Date
                </th>
                <th scope="col" className="text-uppercase">
                  Company
                </th>
                <th scope="col" className="text-uppercase">
                  Type
                </th>
                <th scope="col" className="text-uppercase">
                  Mode of Payment
                </th>
                <th scope="col" className="text-uppercase">
                  Bank
                </th>
                <th scope="col" className="text-uppercase">
                  Amount
                </th>
                <th scope="col" className="text-uppercase">
                  Particulars
                </th>
                <th scope="col" className="text-uppercase">
                  Sub Particulars
                </th>
                <th scope="col" className="text-uppercase">
                  Narration
                </th>
                <th scope="col" className="text-uppercase">
                  Approved By
                </th>
                <th scope="col" className="text-uppercase">
                  Closing Balance
                </th>
                <th scope="col" className="text-uppercase">
                  Added By
                </th>
                <th scope="col" className="text-uppercase">
                  Files
                </th>
                {(all || edit || del) && (
                  <th scope="col" className="text-uppercase">
                    Action
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {data?.data?.map((val, i) => (
                <tr key={val._id}>
                  <td>{++i + filter.offset}</td>
                  <td>{CreatedAt(val.createdAt)}</td>
                  <td>{CreatedAt(val.date)}</td>
                  <td>{val.company?.name || NA}</td>
                  <td>{ReverseObj(ACCOUNTING.TYPE)[val.type] || NA}</td>
                  <td>
                    {ReverseObj(ACCOUNTING.MODE_OF_PAYMENT)[val.paymentMode] ||
                      NA}
                  </td>
                  <td>
                    {val.bank?.firmName || val.bank?.bankName
                      ? `${val.bank?.firmName || NA} - ${
                          val.bank?.bankName || NA
                        }`
                      : NA}
                  </td>
                  <td>{val.amount}</td>
                  <td>{val.particular?.name || NA}</td>
                  <td>{val.subParticular?.name || NA}</td>
                  <td>{val.narration}</td>
                  <td>{val.approvedBy}</td>
                  <td>{val.closingBalance}</td>
                  <td>{val.createdBy?.name || NA}</td>
                  <td>
                    {val.files?.length > 0 ? (
                      <ViewFile files={val.files} />
                    ) : (
                      NO_FILES
                    )}
                  </td>
                  {(all || edit || del) && (
                    <td>
                      {(all || edit) && (
                        <Link to={EndPoints.ADD_ACCOUNTING} state={{ val }}>
                          <i className="fas fa-edit me-2 pe-auto"></i>
                        </Link>
                      )}
                      {(all || del) && (
                        <i
                          className="material-icons text-danger pe-auto"
                          onClick={() =>
                            setVisible((prev) => ({
                              ...prev,
                              deleteConformation: {
                                data: { _id: val._id, name: "acconting entry" },
                                visible: true,
                              },
                            }))
                          }
                        >
                          delete
                        </i>
                      )}
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Paginate count={data?.count} filter={filter} setFilter={setFilter} />
      </div>
    </>
  );
};

export default Accounting;
