import React, { useRef, useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import { useLocation, useNavigate } from "react-router-dom";

import { CustomExpenseSchema } from "../../Helpers/Constants/Schemas";
import { CustomExpenseFields } from "../../Helpers/Constants/InitialValues";
import FileUpload from "../Common/FileUpload";

import { CUSTOM_EXPENSE } from "../../Helpers/Constants/Dropdowns";

import { EndPoints } from "../../Helpers/Constants/EndPoints";

import API from "../../Helpers/Constants/EndPoints/Api";
import Axios from "../../Helpers/Universal/Axios/Axios";
import {
  FilesFormData,
  FilesUpload,
  UpdateParam,
} from "../../Helpers/Universal/Function/common";
import { UPLOAD } from "../../Helpers/Constants/Default";
import Loader from "../Common/Loader";

const AddCustomExpense = () => {
  const [visible, setVisible] = useState({ loader: false });

  const navigate = useNavigate();
  const { state } = useLocation();

  const files = useRef(null);

  const [isUpate] = useState(state ? true : false);
  const [initialValues] = useState(
    isUpate
      ? {
          ...state?.val,
          platform: state?.val?.platform?._id,
          bank: state?.val?.bank?._id,
          expenseType: state?.val?.expenseType?._id,
        }
      : CustomExpenseFields.required
  );

  const [dropdown, setDropdown] = useState({
    expTypes: [],
    banks: [],
    platforms: [],
  });

  const handleSubmit = async (data) => {
    try {
      if (files.current?.formData)
        data.files = await FilesUpload(files.current?.formData);

      const { status } = await Axios.post(API.CUSTOM_EXPENSE.CREATE, data);
      if (status) navigate(EndPoints.CUSTOM_EXPENSE);
    } catch (err) {
      console.log("ERROR==>", err);
    } finally {
      setVisible({ loader: false });
    }
  };

  const handleUpdateDocument = async (data) => {
    try {
      const params = await UpdateParam(CustomExpenseFields, data);
      const { status } = await Axios.patch(API.CUSTOM_EXPENSE.UPDATE, {
        customExpense_id: initialValues._id,
        ...params,
      });
      if (status) navigate(EndPoints.CUSTOM_EXPENSE);
    } catch (err) {
      console.log("ERROR==>", err);
    } finally {
      setVisible({ loader: false });
    }
  };

  const getDropdowns = async () => {
    try {
      const [expTypes, banks, platforms] = await Promise.all([
        Axios.get(API.DROPDOWN.EXPENSE_TYPE),
        Axios.get(API.DROPDOWN.BANK),
        Axios.get(API.DROPDOWN.PLATFORM),
      ]);

      if (expTypes.status) setDropdown({ expTypes: expTypes.data });
      if (banks.status) setDropdown((prev) => ({ ...prev, banks: banks.data }));
      if (platforms.status)
        setDropdown((prev) => ({ ...prev, platforms: platforms.data }));
    } catch (err) {
      console.log("ERROR==>", err);
    }
  };

  const handleFileChange = (event) => {
    files.current.formData = FilesFormData(event, UPLOAD.CustomExpense);
  };

  useEffect(() => {
    getDropdowns();
  }, []);

  return (
    <>
      {visible.loader && <Loader />}
      <Formik
        initialValues={initialValues}
        validationSchema={CustomExpenseSchema}
        onSubmit={(values, { resetForm }) => {
          setVisible({ loader: true });
          isUpate ? handleUpdateDocument(values) : handleSubmit(values);
          resetForm();
        }}
      >
        {({ errors, touched }) => (
          <Form className="border rounded shadow mx-auto w-50 mt-7 m-4 p-4">
            <div className="row">
              <div className="col-md-6 mb-3">
                <label>Platform</label>
              </div>
              <div className="col-md-6 mb-3">
                <Field className="form-control" name="platform" as="select">
                  <option value="">Select a Platform</option>
                  {dropdown.platforms?.map((val) => (
                    <option key={val._id} value={val._id}>
                      {val.name}
                    </option>
                  ))}
                </Field>
                {errors.platform && touched.platform ? (
                  <div className="error">{errors.platform}</div>
                ) : null}
              </div>

              <div className="col-md-6 mb-3">
                <label>Bank</label>
              </div>
              <div className="col-md-6 mb-3">
                <Field className="form-control" name="bank" as="select">
                  <option value="">Select a Bank</option>
                  {dropdown.banks?.map((val) => (
                    <option key={val._id} value={val._id}>
                      {val.firmName} - {val.bankName}
                    </option>
                  ))}
                </Field>
                {errors.bank && touched.bank ? (
                  <div className="error">{errors.bank}</div>
                ) : null}
              </div>

              <div className="col-md-6 mb-3">
                <label>Type of Expense</label>
              </div>
              <div className="col-md-6 mb-3">
                <Field className="form-control" name="expenseType" as="select">
                  <option value="">Select Type</option>
                  {dropdown.expTypes?.map((val) => (
                    <option key={val._id} value={val._id}>
                      {val.name}
                    </option>
                  ))}
                </Field>
                {errors.expenseType && touched.expenseType ? (
                  <div className="error">{errors.expenseType}</div>
                ) : null}
              </div>

              <div className="col-md-6 mb-3">
                <label>Amount</label>
              </div>
              <div className="col-md-6 mb-3">
                <Field
                  className="form-control mb-2"
                  type="number"
                  name="amount"
                />
                {errors.amount && touched.amount ? (
                  <div className="error">{errors.amount}</div>
                ) : null}
                {isUpate || (
                  <FileUpload
                    name="files"
                    fileRef={files}
                    handleFileChange={handleFileChange}
                  />
                )}
              </div>

              <div className="col-md-6 mb-3">
                <label>Remarks</label>
              </div>
              <div className="col-md-6 mb-3">
                <Field className="form-control" type="text" name="remarks" />
                {errors.remarks && touched.remarks ? (
                  <div className="error">{errors.remarks}</div>
                ) : null}
              </div>

              <div className="col-md-6 mb-3">
                <label>Approved By</label>
              </div>
              <div className="col-md-6 mb-3">
                <Field className="form-control" as="select" name="approvedBy">
                  <option value="">Click to Select</option>
                  {Object.keys(CUSTOM_EXPENSE.APPROVED_BY)?.map((val, i) => (
                    <option key={i} value={CUSTOM_EXPENSE.APPROVED_BY[val]}>
                      {val}
                    </option>
                  ))}
                </Field>
                {errors.approvedBy && touched.approvedBy ? (
                  <div className="error">{errors.approvedBy}</div>
                ) : null}
              </div>
            </div>

            <button
              className="btn bg-gradient-dark mx-auto d-block btn-lg mb-1"
              type="submit"
            >
              {isUpate ? "Update" : "Add"}
            </button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AddCustomExpense;
