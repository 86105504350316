import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import { useLocation, useNavigate } from "react-router-dom";

import { SubParticularSchema } from "../../Helpers/Constants/Schemas";
import { SubParticularFields } from "../../Helpers/Constants/InitialValues";
import { EndPoints } from "../../Helpers/Constants/EndPoints";

import API from "../../Helpers/Constants/EndPoints/Api";
import Axios from "../../Helpers/Universal/Axios/Axios";
import { UpdateParam } from "../../Helpers/Universal/Function/common";

const AddSubParticular = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [particulars, setParticulars] = useState([]);
  const [isUpate] = useState(state ? true : false);
  const [initialValues] = useState(
    isUpate
      ? { ...state.val, particular: state.val?.particular?._id }
      : SubParticularFields.required
  );

  const handleSubmit = async (data) => {
    try {
      const { status } = await Axios.post(API.SUB_PARTICULAR.CREATE, data);
      if (status) navigate(EndPoints.SUB_PARTICULAR);
    } catch (err) {
      console.log("ERROR==>", err);
    }
  };

  const handleUpdateDocument = async (data) => {
    try {
      const params = await UpdateParam(SubParticularFields, data);

      const { status } = await Axios.patch(API.SUB_PARTICULAR.UPDATE, {
        subParticular_id: initialValues._id,
        ...params,
      });
      if (status) navigate(EndPoints.SUB_PARTICULAR);
    } catch (err) {
      console.log("ERROR==>", err);
    }
  };

  const getParticulars = async () => {
    try {
      const { status, data } = await Axios.get(API.DROPDOWN.PARTICULAR);
      if (status) setParticulars(data);
    } catch (err) {
      console.log("ERROR==>", err);
    }
  };

  useEffect(() => {
    getParticulars();
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={SubParticularSchema}
      onSubmit={(values, { resetForm }) => {
        isUpate ? handleUpdateDocument(values) : handleSubmit(values);
        resetForm();
      }}
    >
      {({ errors, touched }) => (
        <Form className="border rounded shadow mx-auto w-50 mt-7 m-4 p-4">
          <div className="row">
            <div className="col-md-6 mb-3">
              <label>Sub Particular</label>
            </div>
            <div className="col-md-6 mb-3">
              <Field className="form-control" type="text" name="name" />
              {errors.name && touched.name ? (
                <div className="error">{errors.name}</div>
              ) : null}
            </div>

            <div className="col-md-6 mb-3">
              <label>Particular</label>
            </div>
            <div className="col-md-6 mb-3">
              <Field className="form-control" as="select" name="particular">
                <option value="">Select a Particular</option>
                {particulars?.map((val) => (
                  <option key={val._id} value={val._id}>
                    {val.name}
                  </option>
                ))}
              </Field>
              {errors.particular && touched.particular ? (
                <div className="error">{errors.particular}</div>
              ) : null}
            </div>
          </div>

          <button
            className="btn bg-gradient-dark mx-auto d-block btn-lg mb-1"
            type="submit"
          >
            {isUpate ? "Update" : "Add"}
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default AddSubParticular;
