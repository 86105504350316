import * as Yup from "yup";

import { ACCOUNTING, STATUS, TRANSFER, INTERNAL_TRANSFER } from "./Dropdowns";

export const PaymentSchema = Yup.object({
  platform: Yup.string().required("Required"),
  automaticDeposit: Yup.array().optional(),
  manualDeposit: Yup.array().optional(),
  depositFailed: Yup.array().optional(),
  autoWithdrawal: Yup.array().optional(),
  manualWithdrawal: Yup.array().optional(),
  withdrawalProcessing: Yup.array().optional(),
  withdrawalFailed: Yup.array().optional(),
  wrongDeposit: Yup.number().moreThan(-1).optional(),
  wrongWithdrawal: Yup.number().moreThan(-1).optional(),
  incorrectSettlement: Yup.number().moreThan(-1).optional(),
  payoutReceived: Yup.object()
    .shape({
      text: Yup.string().optional(),
      amount: Yup.number().moreThan(-1).optional(),
    })
    .optional(),
  bank: Yup.array().optional(),
  GST: Yup.number().moreThan(-1).optional(),
  remarks: Yup.string().optional(),
  custom: Yup.array().optional(),
});

export const BankSchema = Yup.object({
  firmName: Yup.string().required("Required"),
  bankName: Yup.string().required("Required"),
  platform: Yup.string().optional(),
  secondaryPlatforms: Yup.array().optional(),
  balanceAmount: Yup.number().moreThan(-1).optional(),
  proprietorName: Yup.string().optional(),
  accountNumber: Yup.string().optional(),
  ifscCode: Yup.string().optional(),
  accountType: Yup.string().optional(),
  branchName: Yup.string().optional(),
  mobileNumber: Yup.string().optional(),
  emailId: Yup.string().optional().email(),
  password: Yup.string().optional(),
  customerId: Yup.string().optional(),
  internetBankingUser: Yup.string().optional(),
  internetBankingPin: Yup.string().optional(),
  aadharCardNumber: Yup.string().optional(),
  DOB: Yup.date().optional(),
  atmPin: Yup.string().optional(),
  debitCardNumber: Yup.string().optional(),
  debitCardExpiry: Yup.date().optional(),
  debitCardCvv: Yup.string().optional(),
  referenceName: Yup.string().optional(),
  dateOfIssue: Yup.date().optional(),
  mobilePhone: Yup.string().optional(),
});

export const InternalTransferSchema = Yup.object({
  platform: Yup.string().required("Required"),
  from: Yup.string().required("Required"),
  fromType: Yup.string().oneOf(INTERNAL_TRANSFER.REF_TYPE).required("Required"),
  amount: Yup.number().moreThan(0).required("Required"),
  to: Yup.string().required("Required"),
  toType: Yup.string().oneOf(INTERNAL_TRANSFER.REF_TYPE).required("Required"),
  openingBalance: Yup.number().moreThan(-1).optional(),
  remarks: Yup.string().optional(),
});

export const InternalTransferSchemaa = Yup.object({
  platform: Yup.string().required("Required"),
  fromBank: Yup.string().required("Required"),
  amount: Yup.number().moreThan(0).required("Required"),
  toBank: Yup.string().required("Required"),
  remarks: Yup.string().optional(),
});

export const PaymentGatewaySchema = Yup.object({
  name: Yup.string().required("Required"),
  platforms: Yup.array().optional(),
  payIn: Yup.boolean().optional(),
  payOut: Yup.boolean().optional(),
  openingBalance: Yup.number().moreThan(-1).optional(),
  remarks: Yup.string().optional(),
});

export const P2pSchema = Yup.object({
  bank: Yup.string().required("Required"),
  bankAmount: Yup.number().moreThan(0).required("Required"),
  dashboardAmount: Yup.number().moreThan(0).required("Required"),
  // dashboardFiles: Yup.object().optional(),
  // bankFiles: Yup.object().optional(),
});

export const PlatformSchema = Yup.object({
  name: Yup.string().required("Required"),
});

export const ParticularSchema = Yup.object({
  name: Yup.string().required("Required"),
});

export const CompanySchema = Yup.object({
  name: Yup.string().required("Required"),
});

export const ExpenseTypeSchema = Yup.object({
  name: Yup.string().required("Required"),
});

export const CustomExpenseSchema = Yup.object({
  platform: Yup.string().required("Required"),
  bank: Yup.string().required("Required"),
  expenseType: Yup.string().required("Required"),
  amount: Yup.number().required("Required"),
  remarks: Yup.string().optional(),
  approvedBy: Yup.number().oneOf([1, 2]).required("Required"),
  // files: Yup.array().optional(),
});

export const LoginSchema = Yup.object({
  email: Yup.string().email().required("Required"),
  password: Yup.string().required("Required"),
});

export const AccountingSchema = Yup.object({
  date: Yup.date().optional(),
  company: Yup.string().required("Required"),
  type: Yup.number().oneOf(Object.values(ACCOUNTING.TYPE)).required("Required"),
  paymentMode: Yup.number()
    .oneOf(Object.values(ACCOUNTING.MODE_OF_PAYMENT))
    .required("Required"),
  bank: Yup.string().optional(),
  amount: Yup.number().moreThan(0).required("Required"),
  particular: Yup.string().optional(),
  subParticular: Yup.string().optional(),
  narration: Yup.string().required("Required"),
  approvedBy: Yup.string().optional(),
  voucherNumber: Yup.string().optional(),
});

export const SubParticularSchema = Yup.object({
  particular: Yup.string().required("Required"),
  name: Yup.string().required("Required"),
});

export const CashBalanceSchema = Yup.object({
  fromDate: Yup.date().required("Required"),
  toDate: Yup.date().required("Required"),
  company: Yup.string().required("Required"),
  paymentMode: Yup.string().required("Required"),
});

export const RoleSchema = Yup.object({
  name: Yup.string().required("Required"),
  status: Yup.number().oneOf(Object.values(STATUS)).required("Required"),
});

export const UserSchema = Yup.object({
  name: Yup.string().required("Required"),
  email: Yup.string().email().required("Required"),
  password: Yup.string().required("Required"),
  role: Yup.string().required("Required"),
  status: Yup.number().oneOf(Object.values(STATUS)).optional(),
});

export const TransferSchema = Yup.object({
  fromCompany: Yup.string().required("Required"),
  paymentMode: Yup.number()
    .oneOf(Object.values(TRANSFER.MODE_OF_PAYMENT))
    .required("Required"),
  amount: Yup.number().moreThan(0).required("Required"),
  toCompany: Yup.string().required("Required"),
});

export const EntrySchema = Yup.object({
  amount: Yup.number().moreThan(0).required("Required"),
});
