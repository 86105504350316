import React from "react";
import ReactLoading from "react-loading";

const Loader = () => {
  return (
    <div className="bg_viewpopup" style={{ zIndex: "999999" }}>
      <div className="successfull p-4 w-50 top-50 start-50 end-0 position-absolute">
        <ReactLoading
          className="mx-auto"
          type="bars"
          color="linear-gradient(195deg, #42424a 0%, #191919 100%)"
          height="0.1%"
          width="11%"
        />
      </div>
    </div>
  );
};

export default Loader;
