import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";

import ViewFile from "../Common/ViewFile";
import DatePicker from "../Common/DatePicker";
import { EndPoints } from "../../Helpers/Constants/EndPoints";
import Loader from "../Common/Loader";

import API from "../../Helpers/Constants/EndPoints/Api";
import Axios from "../../Helpers/Universal/Axios/Axios";
import { CreatedAt, ReverseObj } from "../../Helpers/Universal/Function/common";
import { CUSTOM_EXPENSE } from "../../Helpers/Constants/Dropdowns";
import { LIMIT, NA, NO_FILES, OFFSET } from "../../Helpers/Constants/Default";
import Paginate from "../Common/Paginate";
import DeleteConfirmation from "../Common/DeleteConfirmation ";

const CustomExpense = ({ permissions: { all, add, edit, delete: del } }) => {
  const [data, setData] = useState([]);
  const [visible, setVisible] = useState({
    loader: false,
    deleteConformation: { data: false, visible: false },
  });
  const [filter, setFilter] = useState({
    limit: LIMIT,
    offset: OFFSET,
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });

  const getData = async () => {
    try {
      setVisible((prev) => ({ ...prev, loader: true }));
      const { status, data } = await Axios.get(
        API.CUSTOM_EXPENSE.LISTING,
        filter
      );

      if (status) setData(data);
    } catch (err) {
      console.log("ERROR==>", err);
    } finally {
      setVisible((prev) => ({ ...prev, loader: false }));
    }
  };

  useEffect(() => {
    getData();
  }, [filter]);

  return (
    <>
      {visible.loader && <Loader />}
      {visible.deleteConformation.visible && (
        <DeleteConfirmation
          visible={visible}
          setVisible={setVisible}
          setData={setData}
          API={API.CUSTOM_EXPENSE.DELETE}
        />
      )}

      <div className="container-fluid py-3">
        <div className="dateselect_navigation d-flex justify-content-between align-items-center">
          <DatePicker filter={filter} setFilter={setFilter} />
          {(all || add) && (
            <Link to={EndPoints.ADD_CUSTOM_EXPENSE}>
              <button className="btn bg-gradient-dark float-end d-flex btn-md mb-1">
                <i className="fas fa-plus me-2 mt-1" aria-hidden="true"></i>
                Add
              </button>
            </Link>
          )}
        </div>

        <div className="overflow-x-scroll w-100">
          <table className="table-striped table align-middle mt-3 gate_way">
            <thead className="bg-light text-dark">
              <tr>
                <th scope="col" className="text-uppercase">
                  #
                </th>
                <th scope="col" className="text-uppercase">
                  Date
                </th>
                <th scope="col" className="text-uppercase">
                  Platform
                </th>
                <th scope="col" className="text-uppercase">
                  Bank
                </th>
                <th scope="col" className="text-uppercase">
                  Type of Expense
                </th>
                <th scope="col" className="text-uppercase">
                  Amount
                </th>
                <th scope="col" className="text-uppercase">
                  Remarks
                </th>
                <th scope="col" className="text-uppercase">
                  Approved By
                </th>
                <th scope="col" className="text-uppercase">
                  Added By
                </th>
                <th scope="col" className="text-uppercase">
                  Files
                </th>
                {(all || edit || del) && (
                  <th scope="col" className="text-uppercase">
                    Action
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {data?.data?.map((val, i) => (
                <tr key={val._id}>
                  <td>{++i + filter.offset}</td>
                  <td>{CreatedAt(val.createdAt)}</td>
                  <td>{val.platform?.name || NA}</td>
                  <td>
                    {`${val.bank?.firmName || NA} - ${
                      val.bank?.bankName || NA
                    }`}
                  </td>
                  <td>{val.expenseType?.name || NA}</td>
                  <td>{val.amount}</td>
                  <td>{val.remarks}</td>
                  <td>
                    {ReverseObj(CUSTOM_EXPENSE.APPROVED_BY)[val.approvedBy] ||
                      NA}
                  </td>
                  <td>{val.createdBy?.name || NA}</td>
                  <td>
                    {val.files?.length > 0 ? (
                      <ViewFile files={val.files} />
                    ) : (
                      NO_FILES
                    )}
                  </td>
                  {(all || edit || del) && (
                    <td>
                      {(all || edit) && (
                        <Link to={EndPoints.ADD_CUSTOM_EXPENSE} state={{ val }}>
                          <i className="fas fa-edit me-2 pe-auto"></i>
                        </Link>
                      )}
                      {(all || del) && (
                        <i
                          className="material-icons text-danger pe-auto"
                          onClick={() =>
                            setVisible((prev) => ({
                              ...prev,
                              deleteConformation: {
                                data: {
                                  _id: val._id,
                                  name: "custom expense entry",
                                },
                                visible: true,
                              },
                            }))
                          }
                        >
                          delete
                        </i>
                      )}
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Paginate count={data?.count} filter={filter} setFilter={setFilter} />
      </div>
    </>
  );
};

export default CustomExpense;
